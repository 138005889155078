import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "layouts/protected";
import Loader from "./layouts/preloader";
import { useThemeParams } from "app/telegram";

function App() {
  let [ ColorScheme ] = useThemeParams();

  return (
    <div
      data-testid="app"
      className={`h-screen ${ColorScheme === "dark" ? "dark" : "light"}`}
      style={{ backgroundImage: "background.svg" }}
    >
      <Routes>
        <Route path="/loading" element={<Loader />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
