import React, { useEffect, useState } from "react";
import { BottomSheet, useBottomSheet } from "context/bottomsheet.context";
import ZodiacList from "features/zodiac/zodiacList";
import { getZodiac, getZodiacPeriod, Zodiac } from "app/types/zodiac";
import CompatibilityZodiacCard from "./compatibilityZodiacCard";
import { CompatibilityZodiac } from "app/types/compatibility";
import { CompatibilityZodiacCreateRequest, useCompatibilityZodiacCreateMutation } from "app/services/compatibility";
import { handleError } from "utils";
import { useProfileListQuery } from "app/services/profile";

const CompatibilityZodiacPage = () => {

  const { openBottomSheet, closeBottomSheet } = useBottomSheet();

  const [ selectFn, setSelectFn ] = useState<(zodiac: Zodiac) => void>();
  const [ selectedFirstZodiac, setSelectedFirstZodiac ] = useState<Zodiac | null>(null);
  const [ selectedSecondZodiac, setSelectedSecondZodiac ] = useState<Zodiac | null>(null);
  const [ compatibilityData, setCompatibilityData ] = useState<CompatibilityZodiac | null>(null);

  const [ compatibilityZodiac ] = useCompatibilityZodiacCreateMutation();
  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();

  useEffect(() => {
    if (!!profiles?.length && !selectedFirstZodiac) {
      setSelectedFirstZodiac(profiles.find(p => p.is_default)?.zodiac || null)
    }
  }, [ profiles ]);

  if (isProfilesLoading) return <div/>;

  const handleSelect = (section: "first" | "second") => {
    const newSelectFn = (zodiac: Zodiac) => {
      if (section === "first") {
        setSelectedFirstZodiac(zodiac);
      } else {
        setSelectedSecondZodiac(zodiac);
      }
      setCompatibilityData(null)
      closeBottomSheet();
    };

    setSelectFn(() => newSelectFn);
    openBottomSheet();
  };

  const submit = async () => {
    if (!selectedFirstZodiac || !selectedSecondZodiac) return

    const compatibility: Partial<CompatibilityZodiacCreateRequest> = {
      zodiac: selectedFirstZodiac!,
      partner_zodiac: selectedSecondZodiac!,
    };

    try {
      const result = await compatibilityZodiac(compatibility as CompatibilityZodiacCreateRequest).unwrap()
      if (!result) {
        throw new Error("Failed to create zodiac compatibility")
      } else {
        setCompatibilityData(result)
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message)
      } else {
        handleError(err, "An unexpected error occurred")
      }
    }
  }

  const renderZodiac = (section: "first" | "second", zodiac: Zodiac | null) => {
    if (!zodiac) {
      return (
        <div
          className="flex flex-col items-center justify-center border-2 border-dashed rounded-xl w-[13rem] h-[13rem]">
          <button className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
            onClick={() => handleSelect(section)}>
            Выбрать знак
          </button>
        </div>
      )
    }

    const zodiacName = zodiac
      ? zodiac.charAt(0).toUpperCase() + zodiac.slice(1).toLowerCase()
      : Zodiac.ARIES.charAt(0).toUpperCase() + Zodiac.ARIES.slice(1).toLowerCase();
    const period = getZodiacPeriod(zodiac)

    return (
      <>
        <div className="flex flex-col items-center justify-center mb-5">
          <img className="w-32 h-32 mx-auto" src={`/img/constellation/${getZodiac(zodiac)}.svg`} alt=""/>
          <h1 className="text-base py-2 text-center text-tg-theme-subtitle">{zodiacName}</h1>
          <p className="text-xs text-tg-theme-subtitle uppercase">
            {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")}
            - 
            ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
          </p>
        </div>
        <div className="w-full flex justify-center">
          <button
            className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
            onClick={() => handleSelect(section)}
          >
            Изменить
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="w-full mb-10 constellation">
        <div className="mb-10">
          <div className="flex flex-col items-center justify-center mt-8">
            <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
          </div>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">Matching</h1>
          <div className="text-center">
            <p className="text-sm text-tg-theme-subtitle">Check compatibility between zodiac signs and
              astro-profiles
            </p>
          </div>
        </div>

        <div className="px-5 my-10">
          <div className="flex flex-col items-center justify-center mt-8">
            {renderZodiac("first", selectedFirstZodiac)}
          </div>
        </div>

        <div className="w-full flex justify-center py-5">
          <hr className="w-1/3"/>
        </div>

        <div className="px-5 my-10">
          <div className="flex flex-col items-center justify-center mt-8">
            {renderZodiac("second", selectedSecondZodiac)}
          </div>
        </div>

        {!compatibilityData && (
          <div className="px-5 my-10 flex justify-center items-center">
            <div
              className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer w-4/5"
              onClick={submit}
            >
              <div className="mb-1 flex justify-center items-center">
                <img src="/ico/arrow-right.svg" alt=""/>
              </div>
              <div className="px-5 font-bold text-sm text-gold-gradient flex items-center justify-center">
                Показать совместимость
              </div>
              <div className="mb-1 flex justify-center items-center">
                <img src="/ico/arrow-left.svg" alt=""/>
              </div>
            </div>
          </div>
        )}

        {!!compatibilityData && (
          <div className="px-10">
            <CompatibilityZodiacCard data={compatibilityData}/>
          </div>
        )}

      </div>

      <BottomSheet>
        <div className="px-5">
          <ZodiacList onSelect={(z: Zodiac) => selectFn ? selectFn(z) : null}/>
        </div>
      </BottomSheet>
    </div>
  );
};

export default CompatibilityZodiacPage;