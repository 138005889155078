import React, { FC } from "react";

export interface CompatibilityIndicatorProps {
  mood?: number;
  money?: number;
  love?: number;
  isPercentage?: boolean;
}

const CompatibilityIndicator: FC<CompatibilityIndicatorProps> = ({
  mood = 3,
  money = 3,
  love = 3,
  isPercentage = false,
}) => {

  const convertToCirclesValue = (value: number): number => {
    if (isPercentage) {
      if (value <= 25) return 1;
      if (value <= 75) return 2;
      return 3;
    }
    return value;
  };

  const renderCircles = (v: number) => {
    const circlesValue = convertToCirclesValue(v);
    const src =
      circlesValue === 3
        ? "/ico/circle-green.svg"
        : circlesValue < 3
          ? "/ico/circle-yellow.svg"
          : "/ico/circle-red.svg";
    return Array(circlesValue).fill(null).map((_, i) => (
      <img key={i} src={src} alt="" className="w-3 h-3"/>
    ));
  };

  return (
    <div className="w-full flex justify-center">
      <div className="grid grid-cols-3 gap-10">
        <div className="flex flex-col items-center text-center">
          <div className="text-[#AFAFDE] text-sm font-bold pb-1">Mood</div>
          <div className="flex justify-center gap-1">
            {renderCircles(mood)}
          </div>
        </div>

        <div className="flex flex-col items-center text-center">
          <div className="text-[#AFAFDE] text-sm font-bold pb-1">Money</div>
          <div className="flex justify-center gap-1">
            {renderCircles(money)}
          </div>
        </div>

        <div className="flex flex-col items-center text-center">
          <div className="text-[#AFAFDE] text-sm font-bold pb-1">Love</div>
          <div className="flex justify-center gap-1">
            {renderCircles(love)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompatibilityIndicator;