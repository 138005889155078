import React from "react";
import NatalChart from "./natalChart";
import { NatalChartPlanet, NatalChartHouse, NatalChartAspect } from "../../../app/types/natal_chart";

interface DualNatalChartProps {
  ascendant: number;
  houses: NatalChartHouse[];
  planets: NatalChartPlanet[];
  aspects: NatalChartAspect[];
  secondPlanets: NatalChartPlanet[];
  secondAspects: NatalChartAspect[];
  showHouseNumbers?: boolean;
  showDegrees?: boolean;
}

export const DualNatalChart: React.FC<DualNatalChartProps> = ({
  ascendant,
  houses,
  planets,
  aspects,
  secondPlanets,
  secondAspects,
  showHouseNumbers,
  showDegrees,
}) => {
  const combinedPlanets = [
    ...planets.map(p => ({ ...p, color: "#4A42C5" })),
    ...secondPlanets.map(p => ({ ...p, color: "#388E09" }))
  ];

  const combinedAspects = [
    ...aspects.map(a => ({ ...a, color: "#4A42C5" })),
    ...secondAspects.map(a => ({ ...a, color: "#388E09" }))
  ];

  return (
    <NatalChart
      ascendant={ascendant}
      houses={houses}
      planets={combinedPlanets}
      aspects={combinedAspects}
      showHouseNumbers={showHouseNumbers}
      showDegrees={showDegrees}
    />
  );
}; 