import { FC, useEffect, useRef, useState } from "react";
import { Element } from "app/types/zodiac";

interface ZodiacElementsProps {
  element: Element | undefined
  secondaryElement?: Element;
}

const ZodiacElements: FC<ZodiacElementsProps> = ({ element, secondaryElement }) => {
  const [ squareSize, setSquareSize ] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const calculateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const squareSize = containerWidth / 2;
        setSquareSize(squareSize);
      }
    };

    calculateDimensions();
    window.addEventListener("resize", calculateDimensions);
    return () => window.removeEventListener("resize", calculateDimensions);
  }, []);

  const getElementColor = (elementType: string) => {
    if (elementType === element || elementType === secondaryElement) {
      switch (elementType) {
        case "air":
          return "bg-gradient-to-r from-[#131C3A] to-[#061321]";
        case "water":
          return "bg-gradient-to-r from-[#131C3A] to-[#061321]";
        case "fire":
          return "bg-gradient-to-r from-[#131C3A] to-[#061321]";
        case "earth":
          return "bg-gradient-to-l from-[#131C3A] to-[#061321]";
      }
    }
    return "bg-[#061321]";
  };

  const getBorderColor = (elementType: string) => {
    if (elementType === element || elementType === secondaryElement) {
      switch (elementType) {
        case "air":
          return "border-l-2";
        case "water":
          return "border-b-2";
        case "fire":
          return "border-t-2";
        case "earth":
          return "border-r-2";
        default:
          return "";
      }
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="relative w-52 h-52" ref={containerRef}>

        {/* Central Square */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            className={`bg-[#061321] border-[#FCF0A1] 
              ${getBorderColor("air")} 
              ${getBorderColor("water")} 
              ${getBorderColor("fire")} 
              ${getBorderColor("earth")}
            `}
            style={{
              width: `${squareSize}px`,
              height: `${squareSize}px`
            }}
          />
        </div>

        {/* Four Triangles and Central Square */}
        <div className="absolute inset-0">
          {/* Central Square */}
          <div className="absolute top-1/4 left-1/4 w-1/2 h-1/2"/>

          {/* Top Triangle (Fire) */}
          <div className="absolute top-0 left-0 right-0 h-1/4 overflow-hidden">
            <div className={`w-full h-full ${getElementColor("fire")}`}
              style={{ clipPath: "polygon(25% 100%, 75% 100%, 50% 0%)" }}/>
          </div>

          {/* Right Triangle (Earth) */}
          <div className="absolute top-1/4 right-0 bottom-1/4 w-1/4 overflow-hidden">
            <div className={`w-full h-full ${getElementColor("earth")}`}
              style={{ clipPath: "polygon(0% 0%, 0% 100%, 100% 50%)" }}/>
          </div>

          {/* Bottom Triangle (Water) */}
          <div className="absolute bottom-0 left-0 right-0 h-1/4 overflow-hidden">
            <div className={`w-full h-full ${getElementColor("water")}`}
              style={{ clipPath: "polygon(25% 0%, 75% 0%, 50% 100%)" }}/>
          </div>

          {/* Left Triangle (Air) */}
          <div className="absolute top-1/4 left-0 bottom-1/4 w-1/4 overflow-hidden">
            <div className={`w-full h-full ${getElementColor("air")}`}
              style={{ clipPath: "polygon(0% 50%, 100% 0%, 100% 100%)" }}/>
          </div>
        </div>

        {/* Element Names */}
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full text-center mt-[-20px]">
          <h3 className={`
            text-md font-semibold 
            ${element === "fire" || secondaryElement === "fire"
            ? "text-tg-theme-section-header"
            : "text-white"}
          `}>
            Огонь
          </h3>
        </div>
        <div className="absolute right-0 top-1/2 transform translate-x-full -translate-y-1/2 text-center mr-[-20px]">
          <h3 className={`
            text-md font-semibold 
            ${element === "earth" || secondaryElement === "earth"
            ? "text-tg-theme-section-header"
            : "text-white"}
          `}>
            Земля
          </h3>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full text-center mb-[-20px]">
          <h3 className={`
            text-md font-semibold 
            ${element === "water" || secondaryElement === "water"
            ? "text-tg-theme-section-header"
            : "text-white"}
            `}>
            Вода
          </h3>
        </div>
        <div className="absolute left-0 top-1/2 transform -translate-x-full -translate-y-1/2 text-center ml-[-20px]">
          <h3 className={`
            text-md font-semibold 
            ${element === "air" || secondaryElement === "air"
            ? "text-tg-theme-section-header"
            : "text-white"}
          `}>
            Воздух
          </h3>
        </div>

        {/* Sensation Descriptions */}
        <div
          className="absolute top-1/4 right-0 transform translate-x-full -translate-y-1/2 text-center mr-[40px] -mt-[16px]">
          <p className={`
            text-xs font-medium 
            ${element === "fire" || element === "earth" || secondaryElement === "fire" || secondaryElement === "earth"
            ? "text-tg-theme-section-header"
            : "text-white"}
          `}>
            Сухо
          </p>
        </div>
        <div
          className="absolute top-1/4 left-0 transform -translate-x-full -translate-y-1/2 text-center ml-[40px] -mt-[16px]">
          <p
            className={`
              text-xs font-medium 
              ${element === "fire" || element === "air" || secondaryElement === "fire" || secondaryElement === "air"
              ? "text-tg-theme-section-header"
              : "text-white"}
          `}>
            Горячо
          </p>
        </div>
        <div
          className="absolute bottom-1/4 right-0 transform translate-x-full translate-y-1/2 text-center mr-[40px] -mb-[16px]">
          <p
            className={`
              text-xs font-medium 
              ${element === "water" || element === "earth" || secondaryElement === "water" || secondaryElement === "earth"
              ? "text-tg-theme-section-header"
              : "text-white"}
          `}>
            Холод
          </p>
        </div>
        <div
          className="absolute bottom-1/4 left-0 transform -translate-x-full translate-y-1/2 text-center ml-[40px] -mb-[16px]">
          <p
            className={`
              text-xs font-medium 
              ${element === "air" || element === "water" || secondaryElement === "air" || secondaryElement === "water"
              ? "text-tg-theme-section-header"
              : "text-white"}
          `}>
            Влажно
          </p>
        </div>

        {!!secondaryElement ? (
          <>
            <div className="absolute -top-12 -left-14">
              <img src={`/ico/elements/${element}.svg`} alt="" className="w-10 h-10"/>
            </div>
            <div className="absolute -top-12 -right-14">
              <img src={`/ico/elements/${secondaryElement}.svg`} alt="" className="w-10 h-10"/>
            </div>
          </>
        ) : (
          <div className="absolute -top-12 -right-14">
            <img src={`/ico/elements/${element}.svg`} alt="" className="w-10 h-10"/>
          </div>
        )}

      </div>
    </div>
  );
};

export default ZodiacElements;
