import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useTelegram } from "context/telegram.provider";
import { useForm } from "context/form.context";
import { List, ListItem } from "widgets/components/list/list";
import { ProfileCreateRequest, useProfileCreateMutation } from "app/services/profile";
import { AccountSettingsUpdateRequest, useAccountSettingsUpdateMutation } from "app/services/account";
import moment from "moment";
import { handleError } from "utils";
import { getZodiac, Zodiac } from "app/types/zodiac";

export const ScreenDonePath = "done";
const ScreenDone = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formData } = useForm();
  const { setMainButtonVisible, setMainButtonProps } = useTelegram()

  const [ updateAccount ] = useAccountSettingsUpdateMutation();
  const [ createProfile ] = useProfileCreateMutation();

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenDone.mainButton"), submit);
  }, [ setMainButtonProps, formData ]);

  const submit = async () => {
    const profile: Partial<ProfileCreateRequest> = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      birthday: formData.birthday / 1000,
    };

    try {
      const accountUpdateData = {
        report_time: formData.report_time,
        zodiacs: formData.zodiacs,
      } as AccountSettingsUpdateRequest

      const accountResult = await updateAccount(accountUpdateData).unwrap()

      if (!accountResult) {
        throw new Error("Failed to update account")
      }

      const profileResult = await createProfile(profile as ProfileCreateRequest).unwrap()

      if (!profileResult) {
        throw new Error("Failed to create profile")
      }

      navigate("/");

    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message)
      } else {
        handleError(err, "An unexpected error occurred")
      }
    }
  }

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">

      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.screens.screenDone.title")}
      </h1>

      <div className="text-center mb-5">
        {t("features.welcome.screens.screenDone.desc")}
      </div>

      <List>
        <ListItem>
          <div className="flex justify-between">
            <div>Имя</div>
            <div className="text-tg-theme-section-header">{formData.first_name}</div>
          </div>
        </ListItem>
        <ListItem>
          <div className="flex justify-between">
            <div>Фамилия</div>
            <div className="text-tg-theme-section-header">{formData.last_name}</div>
          </div>
        </ListItem>
        <ListItem>
          <div className="flex justify-between">
            <div>Дата рождения</div>
            <div className="text-tg-theme-section-header">
              {moment.unix(formData.birthday / 1000).utc().format("DD.MM.YYYY HH:mm")}
            </div>
          </div>
        </ListItem>
        <ListItem>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div>Время получения гороскопа</div>
              <div className="text-tg-theme-section-header">{formData.daily_time}</div>
            </div>
            <div className="grid grid-cols-12 gap-2 mt-2">
              {formData.zodiacs?.map((zodiac: Zodiac) => (
                <div key={zodiac} className="col-span-1">
                  <img
                    src={`/ico/constellation/Constellation=${zodiac.toLowerCase()}, Color=white.svg`}
                    alt={getZodiac(zodiac)}
                    className="w-full h-auto"
                  />
                </div>
              ))}
            </div>
          </div>
        </ListItem>
      </List>

    </div>
  )
}

export default ScreenDone
