import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Card from "widgets/components/card";
import AstroProfile from "widgets/components/astroprofile";
import { FeatureWelcomePath } from "../welcome/routes";
import { ScreenHelloPath } from "../welcome/screens/screenHello";
import { useProfileListQuery } from "app/services/profile";

export const ProfileListFeature = () => {

  const navigate = useNavigate();

  const {
    data: profiles,
    isLoading,
  } = useProfileListQuery();

  if (isLoading) return <div/>;
  if (!profiles?.length) {
    return <Navigate to={FeatureWelcomePath(ScreenHelloPath)}/>;
  }

  const defaultProfile = profiles?.find(p => p.is_default)

  return (
    <div>

      <div className="w-full text-center mb-5">
        <h2 className="text-sm text-tg-theme-section-header">
          Ваш астро-профиль
        </h2>
      </div>

      {defaultProfile && (
        <Card
          className="bg-card-gradient cursor-pointer"
          onClick={() => navigate(`/profile/${defaultProfile.id}`)}>
          <AstroProfile data={defaultProfile}/>
        </Card>
      )}

      <div className="w-full text-center mt-10 mb-5">
        <h2 className="text-sm text-tg-theme-section-header">
          Отслеживаемные профили
        </h2>
      </div>


      {profiles?.filter(p => !p.is_default).map((profile, index) => {
        return (
          <Card key={index}
            className="bg-card-gradient mb-5 cursor-pointer"
            onClick={() => navigate(`/profile/${profile.id}`)}>
            <AstroProfile data={profile}/>
          </Card>
        )
      })}

      <div className="flex w-full justify-center cursor-pointer" onClick={() => navigate("/profile")}>
        <img src="/ico/btn-plus.svg" alt=""/>
      </div>
    </div>
  )
}
