import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomSheet, useBottomSheet } from "context/bottomsheet.context";
import {
  CompatibilityProfileCreateRequest,
  useCompatibilityProfileCreateMutation,
  useCompatibilityProfileListQuery
} from "app/services/compatibility";
import AstroProfileForm from "widgets/forms/astroprofile";
import { formatDate, handleError } from "utils";
import { useProfileListQuery } from "app/services/profile";
import { AstroProfile } from "widgets/forms/astroprofile/astroprofile";
import { PaymentForm } from "../../widgets/forms/payment/payment.form";

const CompatibilityProfilePage = () => {

  const navigate = useNavigate();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [ selectedProfile, setSelectedProfile ] = useState<AstroProfile>();
  const [ selectFn, setSelectFn ] = useState<(profile: AstroProfile) => void>();
  const [ selectedFirstProfile, setSelectedFirstProfile ] = useState<AstroProfile | null>(null);
  const [ selectedSecondProfile, setSelectedSecondProfile ] = useState<AstroProfile | null>(null);

  const [ compatibilityProfile ] = useCompatibilityProfileCreateMutation();
  const {
    data: compatibilities,
  } = useCompatibilityProfileListQuery();
  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();

  useEffect(() => {
    if (!!profiles?.length && !selectedFirstProfile) {
      const p = profiles.find(p => p.is_default)
      if (!p) return
      const data = {
        id: p.id,
        name: `${p.first_name || ""} ${p.last_name || ""}`.trim(),
        location: `${p.city || ""} ${p.country || ""}`.trim(),
        birthday: p.birthday
      } as AstroProfile
      setSelectedFirstProfile(data)
    }
  }, [ profiles ]);

  if (isProfilesLoading) return <div/>;

  const handleSelect = (section: "first" | "second", profile?: AstroProfile) => {
    setSelectedProfile(profile)
    const newSelectFn = (profile: AstroProfile) => {
      if (section === "first") {
        setSelectedFirstProfile(profile);
      } else {
        setSelectedSecondProfile(profile);
      }
      closeBottomSheet();
    };

    setSelectFn(() => newSelectFn);
    openBottomSheet();
  };

  const handlePaymentSubmit = (type: "stars" | "fens") => {
    console.log("Payment type:", type);
    closeBottomSheet();
  };

  const submit = async () => {
    if (!selectedFirstProfile || !selectedSecondProfile) return

    const compatibility: Partial<CompatibilityProfileCreateRequest> = {
      zodiac: selectedFirstProfile!,
      partner_zodiac: selectedSecondProfile!,
    };

    try {
      const result = await compatibilityProfile(compatibility as CompatibilityProfileCreateRequest).unwrap()
      if (!result) {
        throw new Error("Failed to create profile compatibility")
      } else {
        // TODO
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message)
      } else {
        handleError(err, "An unexpected error occurred")
      }
    }
  }

  const renderProfile = (section: "first" | "second", profile: AstroProfile | null) => {
    if (!profile) {
      return (
        <div className="px-5 my-10">
          <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-xl p-10">
            <div className="text-center text-sm mb-6">
              Выбрать существующий профиль или заполнить данные
            </div>
            <button className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
              onClick={() => handleSelect(section)}>
              Изменить
            </button>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="mb-5 flex flex-col items-center justify-center mt-8 ">
          <img className="w-32 h-32 mx-auto" src={"/img/constellation/gemini.svg"} alt=""/>
          <h1 className="text-base py-2 text-center text-gold-gradient">
            {profile.name}
          </h1>
          <p className="text-xs text-gold-gradient uppercase">
            {formatDate(new Date(profile.birthday))}, {profile.location}
          </p>
        </div>

        <div className="w-full flex justify-center">
          <button
            className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
            onClick={() => handleSelect(section, profile)}
          >
            Изменить
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="w-full mb-10 constellation">

        <div className="mb-10">
          <div className="flex flex-col items-center justify-center mt-8">
            <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
          </div>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
            Matching
          </h1>
          <div className="text-center">
            <p className="text-sm text-tg-theme-subtitle">
              Check compatibility between zodiac signs and astro-profiles
            </p>
          </div>
        </div>

        <div className="px-5 my-10">
          {renderProfile("first", selectedFirstProfile)}
        </div>

        <div className="w-full flex justify-center">
          <hr className="w-1/3"/>
        </div>

        <div className="px-5 my-10">
          {renderProfile("second", selectedSecondProfile)}
        </div>

        <div className="px-5 my-10 flex justify-center items-center">
          <div
            className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer w-4/5"
            onClick={() => openBottomSheet("pay")}
          >
            <div className="mb-1 flex justify-center items-center">
              <img src="/ico/arrow-right.svg" alt=""/>
            </div>
            <div className="px-5 font-bold text-sm text-gold-gradient flex items-center justify-center">
              Показать совместимость
            </div>
            <div className="mb-1 flex justify-center items-center">
              <img src="/ico/arrow-left.svg" alt=""/>
            </div>
          </div>
        </div>

      </div>

      <div className="mb-1 flex justify-center">
        <img src="/ico/double-arrow-down.svg" alt=""/>
      </div>

      <div className="mx-5 my-10">
        <div className="w-full text-center mb-5">
          <h2 className="text-sm text-[#AFAFDE]">История запросов натальной карты</h2>
        </div>

        {!compatibilities?.length && (
          <div className="w-full text-center mb-5">
            <p className="text-sm text-[#AFAFDE]">нет истории</p>
          </div>
        )}

        {compatibilities?.map((compatibility, index) => {
          return (
            <div key={index} className={`
              flex items-center justify-between bg-card-gradient px-5 py-2 rounded-lg cursor-pointer
              ${index < 1 ? "mb-4" : ""}
            `}
            onClick={() => navigate(`/compatibility/${compatibility.id}`)}
            >
              <div className="flex items-center">
                <img src={"/img/constellation/aries.svg"} alt={""} className="w-12 h-12 rounded-full mr-4"/>
                <div className="text-sm text-[#AFAFDE]">
                  <div>{compatibility.profile1.name}</div>
                  <div>{formatDate(new Date(compatibility.profile1.birthday))}</div>
                </div>
              </div>

              <div className="text-[#AFAFDE]">x</div>

              <div className="flex items-center">
                <div className="text-sm text-[#AFAFDE]">
                  <div>{compatibility.profile2.name}</div>
                  <div>{formatDate(new Date(compatibility.profile2.birthday))}</div>
                </div>
                <img src={"/img/constellation/aries.svg"} alt={""} className="w-12 h-12 rounded-full ml-4"/>
              </div>
            </div>
          );
        })}
      </div>

      <BottomSheet name="pay">
        <PaymentForm
          onSubmit={handlePaymentSubmit}
          currencies={[
            { type: "stars", cost: "100", name: "Stars" },
            { type: "fens", cost: "10", name: "Fens" }
          ]}
          buttonText="Заказать совместимость"
          description="Заказижите расширенную проверку совместимости с расшифровкой которая будет всегда вам доступна"
        />
      </BottomSheet>

      <BottomSheet title="Выберите астро-профиль">
        <div className="px-5">
          <AstroProfileForm
            profiles={profiles || []}
            value={selectedProfile}
            onSelect={(p) => selectFn ? selectFn(p) : null}/>
        </div>
      </BottomSheet>
    </div>
  )
}

export default CompatibilityProfilePage