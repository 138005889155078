import { ReactNode } from "react"

interface ListProps {
  title?: string
  subtitle?: string
  className?: string
  onClick?: () => void
  children: ReactNode
}

export const List = ({ children, title, subtitle, className, onClick }: ListProps) => {
  return (
    <>
      {title && <div className="text-xs uppercase ml-3 mb-2">{title}</div>}
      <div className={`rounded-xl bg-card-gradient ${className}`} onClick={onClick}>
        {(subtitle) && (
          <div className="p-4 text-tg-theme-accent-text">
            <h3>{subtitle}</h3>
          </div>
        )}
        <div className="flex flex-col divide-y divide-neutral-900">
          {children}
        </div>
      </div>
    </>
  )
}

interface ListItemProps {
  children: ReactNode
  prefix?: ReactNode
  onClick?: () => void
  disabled?: boolean
}

export const ListItem = ({ children, prefix, onClick, disabled }: ListItemProps) => (
  <div
    className={`flex items-center py-3 px-4 ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    onClick={!disabled ? onClick : undefined}
  >
    {prefix && <div className="w-8">{prefix}</div>}
    <div className="w-full">
      {children}
    </div>
  </div>
)
