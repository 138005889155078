import { api } from "./api"
import {
  NatalChart,
  NatalChartAnalyse,
  NatalChartAnalyseCategory,
  NatalChartAnalyseDetail,
  NatalChartAspect,
  NatalChartHouse,
  NatalChartPlanet
} from "../types/natal_chart";

function getNatalChartFromResponse(src: NatalChartResponse): NatalChart {
  return {
    ...src,
  };
}

function getNatalChartAnalyseFromResponse(src: NatalChartAnalyseResponse): NatalChartAnalyse {
  return {
    ...src,
  };
}

function getNatalChartAnalyseListFromResponse(src: NatalChartAnalyseItemResponse): NatalChartAnalyseCategory {
  return {
    ...src,
  };
}

export type NatalChartResponse = {
  ascendant?: number;
  planets?: NatalChartPlanet[];
  houses?: NatalChartHouse[];
  aspects?: NatalChartAspect[];
}

export type NatalChartAnalyseResponse = {
  type: string,
  status: string,
  name: string,
  details: NatalChartAnalyseDetail[]
}

export type NatalChartAnalyseItemResponse = {
  category: string
}

export type NatalChartAnalyseListResponse = {
  items: NatalChartAnalyseItemResponse[]
}

export const natalChartApi = api.injectEndpoints({
  endpoints: (build) => ({

    natalChartProfileFetch: build.query<NatalChartResponse, string>({
      query: (profile_id) => ({
        url: `v1/profile/${profile_id}/natal_chart`,
        method: "GET",
      }),
      transformResponse: (response: NatalChartResponse): NatalChart => {
        return getNatalChartFromResponse(response);
      },
    }),

    natalChartAnalyse: build.query<NatalChartAnalyseResponse, {profile_id: string, analyze: string}>({
      query: (params) => ({
        url: `v1/profile/${params.profile_id}/natal_chart_analyse/${params.analyze}`,
        method: "GET",
      }),
      transformResponse: (response: NatalChartAnalyseResponse): NatalChartAnalyse => {
        return getNatalChartAnalyseFromResponse(response);
      },
    }),

    natalChartAnalyseList: build.query<NatalChartAnalyseCategory[], void>({
      query: (params) => ({
        url: "v1/natal_chart/analyses",
        method: "GET",
      }),
      transformResponse: (response: NatalChartAnalyseListResponse): NatalChartAnalyseCategory[] => {
        return response.items.map(getNatalChartAnalyseListFromResponse);
      },
    }),

    natalChartFetch: build.query<NatalChartResponse, string>({
      query: (natal_chart_id) => ({
        url: `v1/natal_chart/${natal_chart_id}`,
        method: "GET",
      }),
      transformResponse: (response: NatalChartResponse): NatalChart => {
        return getNatalChartFromResponse(response);
      },
    }),

  }),
})

export const {
  useNatalChartFetchQuery,
  useLazyNatalChartFetchQuery,
  useNatalChartProfileFetchQuery,
  useLazyNatalChartProfileFetchQuery,
  useNatalChartAnalyseQuery,
  useLazyNatalChartAnalyseQuery,
  useNatalChartAnalyseListQuery

} = natalChartApi

export const {
  endpoints: {
    natalChartFetch,
    natalChartAnalyse,
  },
} = natalChartApi

