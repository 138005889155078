import { useEffect, useState } from "react";
import ProfileForm from "./profile.form";
import { useTelegram } from "context/telegram.provider";
import { Profile } from "app/types/profile";
import { ProfileCreateRequest, useProfileCreateMutation, useProfileListQuery } from "app/services/profile";
import { handleError } from "utils";
import { useNavigate } from "react-router-dom";
import { PaymentType } from "app/types/payment";
import { usePaymentCreateMutation } from "app/services/payment";
import { useWebApp } from "app/telegram";

const ProfileCreatePage = () => {

  const navigate = useNavigate();
  const webApp = useWebApp()

  const [ profile, setProfile ] = useState({
    birthday: Date.UTC(new Date().getFullYear() - 20, 0, 1)
  } as Profile);
  const { setBackButtonVisible, setBackButtonOnClick, setMainButtonVisible, setMainButtonProps } = useTelegram()

  const [ createProfile ] = useProfileCreateMutation();
  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();

  const [ createPaymentLink, {
    isError: isCreatePaymentError
  } ] = usePaymentCreateMutation();

  useEffect(() => {
    setBackButtonVisible(true)
    setMainButtonVisible(true)
    setBackButtonOnClick(() => navigate("/"));
    setMainButtonProps("СОЗДАТЬ", submit);
  }, [ profile ]);

  useEffect(() => {
    if (!!profiles?.length && profiles?.length > 1) {
      if (profiles.findIndex(p => p.is_free) !== -1) {
        setMainButtonProps("Оформить подписку", buySubscription);
      }
    }
  }, [ profiles ])

  const handleChange = (data: Profile) => {
    setProfile(data)
  }

  const submit = async () => {
    try {

      const data: Partial<ProfileCreateRequest> = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        birthday: profile.birthday / 1000,
      };

      const profileResult = await createProfile(data as ProfileCreateRequest).unwrap()

      if (!profileResult) {
        throw new Error("Failed to create profile")
      }

      navigate(`/profile/${profileResult.id}`)

    } catch (err) {
      if (err instanceof Error) {
        handleError(err, err.message)
      } else {
        handleError(err, "An unexpected error occurred")
      }
    }
  }

  const buySubscription = async () => {
    try {
      const paymentData = await createPaymentLink({
        type: PaymentType.Subscription,
        subscription_name: "profile_subscription"
      }).unwrap()
      if (!isCreatePaymentError) {
        webApp.openInvoice(paymentData.link, (event: any) => alert(event))
      }
    } catch (err) {
      handleError(err, "Failed to create payment link for profile")
    }
  }

  if (isProfilesLoading) return <div/>

  return (
    <div>
      <div className="flex flex-col min-h-screen px-5 constellation">

        <h1 className="text-3xl font-bold pt-8 mb-3 text-center">
          Новый астро-профиль
        </h1>

        <ProfileForm value={profile} onChange={handleChange}/>
      </div>
    </div>
  )
}

export default ProfileCreatePage
