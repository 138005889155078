import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useScreen } from "context/telegram.provider";
import CompatibilityIndicator from "widgets/components/compatibilityIndicator";
import Card from "widgets/components/card";
import ZodiacElements from "widgets/components/zodiacelements";
import HappyNumber from "widgets/components/happynumber/happynumber";
import { useCompatibilityProfileFetchQuery } from "app/services/compatibility";
import { formatDate, formatPosition } from "utils";
import { getElementString } from "app/types/zodiac";
import { useLazyNatalChartFetchQuery } from "app/services/natalchart";
import { DualNatalChart } from "widgets/components/natalchart/dualNatalChart";

const CompatibilityProfileInfoPage = () => {

  const navigate = useNavigate()
  const { compatibility_id } = useParams();
  const { screen, setBackButtonVisible, setBackButtonOnClick } = useScreen();

  const [ isLoadingBoth, setIsLoadingBoth ] = useState(true);
  const [ activeChart, setActiveChart ] = useState<1 | 2>(1);

  const {
    data: compatibility,
    isLoading,
  } = useCompatibilityProfileFetchQuery(compatibility_id!);

  const [ fetchNatalChart1, {
    data: natalChart1,
    isLoading: isLoadingNatalChart1
  } ] = useLazyNatalChartFetchQuery();

  const [ fetchNatalChart2, {
    data: natalChart2,
    isLoading: isLoadingNatalChart2
  } ] = useLazyNatalChartFetchQuery();

  useEffect(() => {
    if (compatibility) {
      fetchNatalChart1(compatibility.natal_chart_1);
      fetchNatalChart2(compatibility.natal_chart_2);
    }
  }, [ compatibility, fetchNatalChart1, fetchNatalChart2 ]);

  useEffect(() => {
    if (!isLoading && !isLoadingNatalChart1 && !isLoadingNatalChart2) {
      setIsLoadingBoth(false);
    }
  }, [ isLoading, isLoadingNatalChart1, isLoadingNatalChart2 ]);

  useEffect(() => {
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/compatibility/profile"));
  }, [ screen ]);

  if (isLoading) return <div/>;
  if (!compatibility) {
    navigate("/");
    return null
  }

  if (!!compatibility && (isLoadingNatalChart1 || isLoadingNatalChart2)) return <div/>;
  if (isLoadingBoth) return <div/>;

  if (!natalChart1 || !natalChart2) {
    navigate("/");
    return null
  }

  return (
    <div>

      <div className="w-full mb-20 constellation">
        <div className="mb-10">
          <div className="flex flex-col items-center justify-center mt-8">
            <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
          </div>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
            Matching
          </h1>
          <div className="text-center">
            <p className="text-sm text-tg-theme-subtitle">
              Check compatibility astro-profiles
            </p>
          </div>
        </div>

        <div className="px-5 my-10">
          <div className="mb-5 flex flex-col items-center justify-center mt-8 ">
            <img className="w-32 h-32 mx-auto" src={"/img/constellation/gemini.svg"} alt=""/>
            <h1 className="text-base py-2 text-center text-gold-gradient">
              {compatibility.profile1.name}
            </h1>
            <p className="text-xs text-gold-gradient uppercase">
              {formatDate(new Date(compatibility.profile1.birthday))}, {compatibility.profile1.location}
            </p>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <hr className="w-1/3"/>
        </div>

        <div className="px-5 my-10">
          <div className="mb-5 flex flex-col items-center justify-center mt-8 ">
            <img className="w-32 h-32 mx-auto" src={"/img/constellation/gemini.svg"} alt=""/>
            <h1 className="text-base py-2 text-center text-gold-gradient">
              {compatibility.profile2.name}
            </h1>
            <p className="text-xs text-gold-gradient uppercase">
              {formatDate(new Date(compatibility.profile2.birthday))}, , {compatibility.profile2.location}
            </p>
          </div>
        </div>
      </div>

      <div className="py-5"/>

      <div className="mx-5 mt-10">
        <div className="w-full text-center mb-10">
          <h2 className="text-sm text-gold-gradient pb-2">
            ОБЩАЯ ОЦЕНКА
          </h2>

          <div className="relative flex justify-center items-center">
            <div className="text-6xl font-bold text-gold-gradient text-center">
              {compatibility.general.percentage}%
            </div>
            <img src="/ico/triple-arrow-up.svg" alt="" className="absolute w-16 h-16 ml-52"/>
          </div>
        </div>

        <div className="mb-16">
          <CompatibilityIndicator
            mood={compatibility.mood.percentage}
            money={compatibility.money.percentage}
            love={compatibility.love.percentage}
            isPercentage={true}
          />
        </div>
      </div>

      {/* NatalChart Analyse */}
      <div>
        <div className="w-full text-center">
          <h2 className="text-sm text-gold-gradient mb-5">
            АНАЛИЗ НАТАЛЬНЫХ КАРТ
          </h2>
          <div>
            <DualNatalChart
              ascendant={activeChart === 1 ? natalChart1.ascendant! : natalChart2.ascendant!}
              houses={activeChart === 1 ? natalChart1.houses! : natalChart2.houses!}
              planets={activeChart === 1 ? natalChart1.planets! : natalChart2.planets!}
              aspects={activeChart === 1 ? natalChart1.aspects! : natalChart2.aspects!}
              secondPlanets={activeChart === 1 ? natalChart2.planets! : natalChart1.planets!}
              secondAspects={activeChart === 1 ? natalChart2.aspects! : natalChart1.aspects!}
              showHouseNumbers={true}
              showDegrees={true}
            />
          </div>
        </div>

        <div className="mx-5 flex flex-col">
          <div className="max-w-full mx-auto">
            {[ compatibility.profile1, compatibility.profile2 ].map((profile, index) => {
              const circles = [
                <div
                  key="circle1"
                  onClick={() => setActiveChart(1)}
                  className={`w-4 h-4 rounded-full bg-gradient-to-b from-[#4A42C5] to-[#514AAF] border 
                    ${activeChart === 1 ? "border-[#FFFFFF]" : "border-[#AFAFDE]"} 
                    cursor-pointer`}/>,
                <div
                  key="circle2"
                  onClick={() => setActiveChart(2)}
                  className={`w-4 h-4 rounded-full bg-gradient-to-b from-[#388E09] to-[#90DB67] border 
                    ${activeChart === 2 ? "border-[#FFFFFF]" : "border-[#AFAFDE]"} 
                    cursor-pointer`}/>
              ]

              return (
                <div key={index}
                  onClick={() => setActiveChart(index + 1 as 1 | 2)}
                  className={`flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 
                    bg-card-gradient cursor-pointer min-w-[16rem]
                    ${activeChart === index + 1 ? "ring-1 ring-white" : ""}`}
                >
                  <div className="flex justify-end me-4">
                    <div className="flex space-x-4">
                      {circles[index]}
                    </div>
                  </div>
                  <img src={"/img/constellation/aries.svg"} alt={""} className="w-6 h-6 rounded-full me-4"/>
                  <div
                    className={`text-sm w-full ${activeChart === index + 1 ? "text-gold-gradient" : "text-[#AFAFDE]"}`}>
                    {profile.name} {formatDate(new Date(profile.birthday))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center my-10">
        <hr className="w-1/3"/>
      </div>

      {/* Compatibilities */}
      {[ compatibility.general, compatibility.love, compatibility.money ].map((c, index) => {
        const names = [
          "ОБЩАЯ СОВМЕСТИМОСЬ",
          "ОТНОШЕНИЯ",
          "ДЕНЬГИ",
        ]
        return (
          <div key={index} className="mx-10 mb-10">

            <div className="w-full mb-14">
              <h2 className="text-sm text-gold-gradient mb-5 uppercase text-center">
                {names[index]}
              </h2>
              <div className="text-sm text-[#AFAFDE]">
                {c.description}
              </div>
            </div>

            {c.details?.map((detail, detailIndex) => {
              return (
                <div key={detailIndex} className="mb-14">
                  {detail.sections?.map((section, sectionIndex) => {
                    return (
                      <div key={sectionIndex} className="my-14">
                        <div className="flex justify-between">

                          <div className="flex gap-2 mb-8">
                            <div className="mt-[1.2rem]">
                              <img className="w-6"
                                src={`/ico/planets/Rulership=${section.astrology_position_person_1.planet?.toLowerCase()}.svg`}
                                alt=""/>
                            </div>
                            <div>
                              <div className="mb-5">
                                <div className="text-sm text-[#AFAFDE]">
                                  {section.astrology_position_person_1.planet}
                                </div>
                                <div className="text-sm text-[#AFAFDE]">
                                  {section.astrology_position_person_1.zodiac},
                                  {formatPosition(section.astrology_position_person_1.position!)}
                                </div>
                                <div className="text-sm text-[#AFAFDE]">
                                  {section.astrology_position_person_1.house?.replace(/_/g, " ")}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex gap-2">
                            <div className="mt-[1.2rem]">
                              <img className="w-6"
                                src={`/ico/planets/Rulership=${section.astrology_position_person_2.planet?.toLowerCase()}.svg`}
                                alt=""/>
                            </div>
                            <div>
                              <div className="mb-5">
                                <div className="text-sm text-[#AFAFDE]">
                                  {section.astrology_position_person_2.planet}
                                </div>
                                <div className="text-sm text-[#AFAFDE]">
                                  {section.astrology_position_person_1.zodiac},
                                  {formatPosition(section.astrology_position_person_1.position!)}
                                </div>
                                <div className="text-sm text-[#AFAFDE]">
                                  {section.astrology_position_person_1.house?.replace(/_/g, " ")}
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="text-gold-gradient pb-3">
                          {section.name}
                        </div>
                        <div className="text-sm text-[#AFAFDE]">
                          {section.summary}
                        </div>
                      </div>
                    )
                  })}

                  <div className="w-full flex justify-center my-10">
                    <hr className="w-1/3"/>
                  </div>

                  <div className="text-sm text-[#AFAFDE]">
                    {detail.effect}
                  </div>

                </div>
              )
            })}
          </div>
        )
      })}

      {/* Happy numbers */}
      <div className="mx-10">
        <div className="w-full text-center mb-5">
          <h2 className="text-sm font-bold text-gold-gradient uppercase">
            АНАЛИЗ СЧАСТЛИВЫХ ЧИСЕЛ
          </h2>
        </div>
        <div className="text-sm text-[#AFAFDE]">
          {compatibility.numerology_numbers.summary}
        </div>

        <div className="my-5">
          <Card>
            <div>
              <HappyNumber numbers={compatibility.numerology_numbers.numbers_person_1}/>
            </div>
            <div className="w-full text-center text-gold-gradient py-4">x</div>
            <div>
              <HappyNumber numbers={compatibility.numerology_numbers.numbers_person_2}/>
            </div>
          </Card>
        </div>

        <div className="text-sm text-[#AFAFDE]">
          {compatibility.numerology_numbers.effect}
        </div>
      </div>

      {/* Trinity */}
      <div className="mx-10 my-20">
        <div className="w-full text-center mb-20">
          <h2 className="text-sm font-bold text-gold-gradient uppercase">
            Тройственность
          </h2>
        </div>

        <div className="mt-10 mb-28">
          <ZodiacElements
            element={getElementString(compatibility.trinity.element_person_1)}
            secondaryElement={getElementString(compatibility.trinity.element_person_2)}/>
        </div>

        <div className="mt-10 text-sm text-[#AFAFDE]">
          {compatibility.trinity.effect}
        </div>
      </div>

    </div>
  )
}

export default CompatibilityProfileInfoPage
