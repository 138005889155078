import React, { FC, useEffect, useState } from "react";
import { List, ListItem } from "widgets/components/list/list";
import { useTranslation } from "react-i18next";

export interface Props {
  onChange: (name: any, value: any) => void
  data: any
  title?: string
}

const AccountPersonalInfoForm: FC<Props> = ({ title, data, onChange }) => {

  const { t } = useTranslation()
  const [ dataValue, setDataValue ] = useState(data)

  const handleChange = (name: any, data: any) => {
    onChange(name, data)
  }

  useEffect(() => {
    setDataValue(data)
  }, [ data ])

  return (
    <List title={title}>
      <ListItem>
        <input type="text"
          placeholder={t("features.account.forms.accountPersonalInfoForm.inputFirstNamePlaceholder")}
          className="w-full text-tg-theme-text bg-transparent border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
          value={dataValue.first_name}
          onChange={(e) => handleChange("first_name", e.target.value)}
        />
      </ListItem>
      <ListItem>
        <input type="text"
          className="w-full text-tg-theme-text bg-transparent border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
          placeholder={t("features.account.forms.accountPersonalInfoForm.inputLastNamePlaceholder")}
          value={dataValue.last_name}
          onChange={(e) => handleChange("last_name", e.target.value)}
        />
      </ListItem>
    </List>
  )
}
export default AccountPersonalInfoForm;
