import { api } from "./api";
import { Efficiency, EfficiencyGroup, } from "../types/efficiency";

export type EfficiencyResponse = {
  id: string;
  date: number;
  group: EfficiencyGroup;
  percent: number;
  summary: string;
  content: string;
};

export type EfficiencyListResponse = {
  items: EfficiencyResponse[]
}

export type EfficiencyListParams = {
  profile_id?: string
  range_start?: string
  range_end?: string
  group?: string
}

function getEfficiencyFromResponse(src: EfficiencyResponse): Efficiency {
  return {
    ...src,
  };
}

export const efficientApi = api.injectEndpoints({
  endpoints: (build) => ({
    efficientList: build.query<Efficiency[], EfficiencyListParams>({
      query: ({ profile_id, range_start, range_end, group }) => {
        const url = profile_id
          ? `v1/efficiency/profile/${profile_id}`
          : "v1/efficiency";

        return {
          url,
          method: "GET",
          params: { range_start, range_end, group }
        };
      },
      transformResponse: (response: EfficiencyListResponse): Efficiency[] => {
        return response.items.map(getEfficiencyFromResponse);
      },
    }),
  }),
});

export const {
  useEfficientListQuery
} =
  efficientApi;

export const {
  endpoints: { efficientList },
} = efficientApi;
