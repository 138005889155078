import React, { useEffect, useState } from "react";
import { BottomSheet, useBottomSheet } from "context/bottomsheet.context";
import { ProfileCreateRequest, useProfileCreateMutation, useProfileListQuery } from "app/services/profile";
import AstroProfileForm from "widgets/forms/astroprofile";
import { AstroProfile } from "widgets/forms/astroprofile/astroprofile";
import { formatDate, handleError } from "utils";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../context/telegram.provider";

const NatalChartListPage = () => {

  const navigate = useNavigate();
  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [ selectedProfile, setSelectedProfile ] = useState<AstroProfile>();
  const [ selectFn, setSelectFn ] = useState<(profile: AstroProfile) => void>();

  const {
    data: profiles,
  } = useProfileListQuery();
  const {
    data: histories,
  } = useProfileListQuery({ natal_chart: true });
  const [ createProfile ] = useProfileCreateMutation();

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/astro"));
  }, []);

  const handleSelect = (profile?: AstroProfile) => {
    setSelectedProfile(profile)
    const newSelectFn = async (profile: AstroProfile) => {
      setSelectedProfile(profile);
      closeBottomSheet();

      if (!profile.id) {
        try {
          const parts = profile.name.split(" ")
          const data: Partial<ProfileCreateRequest> = {
            first_name: parts.length > 0 ? parts[0] : "",
            last_name: parts.length > 1 ? parts[1] : "",
            birthday: profile.birthday / 1000,
            is_favorites: profile.favorites,
          };

          const profileResult = await createProfile(data as ProfileCreateRequest).unwrap()
          if (!profileResult) {
            throw new Error("NatalChart: Failed to create profile")
          }

          navigate(`/profile/${profileResult.id}/natal_chart`)

        } catch (err) {
          if (err instanceof Error) {
            handleError(err, err.message)
          } else {
            handleError(err, "An unexpected error occurred")
          }
        }

      } else {
        navigate(`/profile/${profile.id}/natal_chart`)
      }
    };

    setSelectFn(() => newSelectFn);
    openBottomSheet();
  };

  const renderProfile = (profile: AstroProfile | undefined) => {
    if (!profile) {
      return (
        <div className="px-5 my-10">
          <div className="flex flex-col items-center justify-center border-2 border-dashed rounded-xl p-10">
            <div className="text-center text-sm mb-6">
              Выбрать существующий профиль или заполнить данные
            </div>
            <button className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
              onClick={() => handleSelect()}>
              Изменить
            </button>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="mb-5 flex flex-col items-center justify-center mt-8 ">
          <img className="w-32 h-32 mx-auto" src={"/img/constellation/gemini.svg"} alt=""/>
          <h1 className="text-base py-2 text-center text-gold-gradient">
            {profile.name}
          </h1>
          <p className="text-xs text-gold-gradient uppercase">
            {formatDate(new Date(profile.birthday))}, {profile.location}
          </p>
        </div>

        <div className="w-full flex justify-center">
          <button
            className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
            onClick={() => handleSelect(profile)}
          >
            Изменить
          </button>
        </div>
      </>
    );
  };

  return (
    <div>

      <div className="mb-10">
        <div className="flex flex-col items-center justify-center mt-8">
          <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
        </div>
        <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
          Natal Chart
        </h1>
        <div className="text-center">
          <p className="text-sm text-tg-theme-subtitle">
            Check person natal chart and analyze it
          </p>
        </div>
      </div>

      <div className="px-5 my-10">
        {renderProfile(selectedProfile)}
      </div>

      {!!selectedProfile && (
        <div className="flex justify-center items-center">
          <div
            className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer w-4/5"
            onClick={() => {}}
          >
            <div className="mb-1 flex justify-center items-center">
              <img src="/ico/arrow-up.svg" alt=""/>
            </div>
            <div className="px-5 font-bold text-sm text-gold-gradient flex items-center justify-center">
              Запросить натальную карту
            </div>
            <div className="mb-1 flex justify-center items-center">
              <img src="/ico/arrow-up.svg" alt=""/>
            </div>
          </div>
        </div>
      )}

      <div className="mb-1 flex justify-center">
        <img src="/ico/double-arrow-down.svg" alt=""/>
      </div>

      <div className="mx-5 my-10 flex flex-col">
        <div className="w-full text-center mb-5">
          <h2 className="text-sm text-[#AFAFDE]">История запросов натальной карты</h2>
        </div>

        <div className="max-w-full mx-auto">
          {histories?.map((profile, index) => {
            return (
              <div key={index}
                className="flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 bg-card-gradient cursor-pointer min-w-[22rem]"
                onClick={() => navigate(`/profile/${profile.id}/natal_chart`)}
              >
                <img src={"/img/constellation/aries.svg"} alt={""} className="w-6 h-6 rounded-full me-4"/>
                <div className="text-sm text-gold-gradient w-full">
                  {`${profile.first_name || ""} ${profile.last_name || ""}`.trim()} {formatDate(new Date(profile.birthday))}
                </div>
                <div className="flex justify-end">
                  <img src="/ico/arrow-right.svg" alt=""/>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <BottomSheet title="Выберите астро-профиль">
        <div className="px-5">
          <AstroProfileForm
            profiles={profiles || []}
            value={selectedProfile}
            onSelect={(p) => selectFn ? selectFn(p) : null}/>
        </div>
      </BottomSheet>
    </div>
  )
}

export default NatalChartListPage