import React, { ChangeEvent, useState } from "react";
import { useHapticFeedback } from "app/telegram";
import { List, ListItem } from "../../components/list";
import { languages } from "./languages";
import { useTranslation } from "react-i18next";

interface LanguageProps {
  value: string;
  onSelect?: (v: string) => void;
}

const Language = ({ value, onSelect }: LanguageProps) => {
  const [ , , selectionChanged ] = useHapticFeedback();
  const [ filter, setFilter ] = useState("");
  const { t } = useTranslation();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  const handleSelect = (v: string) => {
    selectionChanged();
    onSelect && onSelect(v)
  };

  const filteredData = languages
    .flat()
    .filter(item =>
      item.label.toLowerCase().includes(filter)
    );

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-center mb-5">
        <div className="w-full">
          <input
            type="text"
            className="w-full p-3 text-tg-theme-text bg-transparent bg-card-gradient rounded-xl border-transparent
              focus:border-transparent focus:ring-0 focus:outline-none"
            onChange={handleSearch}
            placeholder={t("widgets.forms.language.search")}
          />
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <List>
          {filteredData.map((item, index) => (
            <ListItem
              key={index}
              disabled={item.soon}
              onClick={() => handleSelect(item.value)}>
              <div className="flex justify-between items-center">
                <div>
                  <p>{item.label}</p>
                  <p className="text-xs">
                    {item.hint}
                  </p>
                </div>

                <div className="w-8 text-right">
                  <i className="fas fa-angle-right"/>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Language;
