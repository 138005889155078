import React, { createContext, useContext, useState } from "react";

interface FormContextType {
  formData: Record<string, any>;
  updateFormData: (data: Record<string, any>) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

interface FormProviderProps {
  children: React.ReactNode;
  initialData?: Record<string, any>;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children, initialData = {} }) => {
  const [ formData, setFormData ] = useState<Record<string, any>>(initialData);

  const updateFormData = (data: Record<string, any>) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
};
