import React, { FC } from "react";
import CompatibilityIndicator from "widgets/components/compatibilityIndicator";
import { CompatibilityZodiac } from "app/types/compatibility";

export interface CompatibilityZodiacCardProps {
  data: CompatibilityZodiac
}

const CompatibilityZodiacCard: FC<CompatibilityZodiacCardProps> = ({ data }) => {
  return (
    <div>

      <div className="mb-5">
        <CompatibilityIndicator
          mood={data.mood.percentage}
          money={data.money.percentage}
          love={data.love.percentage}
          isPercentage={true}/>
      </div>

      <div className="py-5">
        <p className="text-sm text-[#AFAFDE]">
          {data.summary}
        </p>
      </div>

      <div>

        <div className="mb-5">
          <h3 className="mb-2 text-gold-gradient">ОТНОШЕНИЯ</h3>
          {data.mood.description?.split("\n")?.map((line, index) => (
            <p key={index} className="text-sm text-[#AFAFDE] pb-2">
              {line.trim()}
            </p>
          ))}
        </div>

        <div className="mb-5">
          <h3 className="mb-2 text-gold-gradient">ДЕНЬГИ</h3>
          {data.money.description?.split("\n")?.map((line, index) => (
            <p key={index} className="text-sm text-[#AFAFDE] pb-2">
              {line.trim()}
            </p>
          ))}
        </div>

        <div className="mb-5">
          <h3 className="mb-2 text-gold-gradient">НАСТРОЕНИЕ</h3>
          {data.love.description?.split("\n")?.map((line, index) => (
            <p key={index} className="text-sm text-[#AFAFDE] pb-2">
              {line.trim()}
            </p>
          ))}
        </div>
      </div>

      <div className="w-full flex justify-center">
        <hr className="w-1/3"/>
      </div>

      <div className="my-5">
        <p className="text-sm text-[#AFAFDE]">
          {data.efficiency}
        </p>
      </div>

    </div>
  );
};

export default CompatibilityZodiacCard;