import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectAccountSettings, selectCurrentAccount, selectSessionToken, } from "features/auth/authSlice"
import { Account, AccountSettings } from "app/types/account"
import { Session } from "app/types/session"

export const useAccount = () => {
  const session: Session = useSelector(selectSessionToken)
  const account: Account = useSelector(selectCurrentAccount)
  const settings: AccountSettings = useSelector(selectAccountSettings)
  return useMemo(() => ({ account, session, settings }), [ account, session, settings ])
}