import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getZodiac, getZodiacPeriod } from "app/types/zodiac";
import { useNatalChartAnalyseQuery, useNatalChartProfileFetchQuery } from "app/services/natalchart";
import { useDailyPersonalFetchQuery } from "app/services/astrology";
import { formatPosition, handleError } from "utils";
import { BottomSheet, useBottomSheet } from "context/bottomsheet.context";
import { PaymentForm } from "widgets/forms/payment/payment.form";
import { PaymentRequest, usePaymentCreateMutation } from "../../app/services/payment";
import { useWebApp } from "app/telegram";

const NatalChartAnalysePage = () => {

  const { profile_id, type } = useParams();
  const navigate = useNavigate()
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const webApp = useWebApp()

  const {
    data: natalChartInfo,
    isLoading: isNatalChartLoading,
  } = useNatalChartProfileFetchQuery(profile_id!);

  const {
    data: natalChartAnalyse,
    isLoading: isNatalChartAnalyseLoading,
  } = useNatalChartAnalyseQuery({ profile_id: profile_id!, analyze: type! });

  const {
    data: profileInfo,
    isLoading: isProfileLoading,
  } = useDailyPersonalFetchQuery(profile_id!);

  const [ createPayment, {
    isLoading: isCreatePaymentLoading,
    isError: isCreatePymentError
  } ] = usePaymentCreateMutation();

  useEffect(() => {
    if (!window.Telegram?.WebApp) return;

    const handleInvoiceClose = (event: any) => {
      // event example:
      // {"url":"https://t.me/$21plSDTfsEp6CAAAf6umL_l_SOE","status":"paid"}

      if (event.status === "paid") {
        console.log("Payment successful");
      } else if (event.status === "failed") {
        console.log("Payment failed");
      } else if (event.status === "cancelled") {
        console.log("Payment cancelled");
      }
    };

    window.Telegram.WebApp.onEvent("invoiceClosed", handleInvoiceClose);

    return () => {
      window.Telegram.WebApp.offEvent("invoiceClosed", handleInvoiceClose);
    };
  }, []);

  if (isProfileLoading || isNatalChartLoading || isNatalChartAnalyseLoading) return <div/>;
  if (!profile_id || !profileInfo || !natalChartInfo) {
    navigate(-1)
    return;
  }

  const period = getZodiacPeriod(profileInfo.zodiac);

  const isAnalysisReady = !!natalChartAnalyse && natalChartAnalyse?.status === "ready";
  const isAnalysisProcessing = !!natalChartAnalyse && natalChartAnalyse?.status === "processing";
  const showPaymentButton = !natalChartAnalyse || (!isAnalysisReady && !isAnalysisProcessing);

  const handlePaymentCreate = async () => {
    try {
      const link = await createPayment({} as PaymentRequest).unwrap()
      if (!isCreatePymentError) {
        webApp.openInvoice(link)
      }
    } catch (err) {
      handleError(err, "Failed to create payment")
    }
  };

  const handlePaymentSubmit = (type: "stars" | "fens") => {
    console.log("Payment type:", type);
    if (type === "stars") handlePaymentCreate()
    // closeBottomSheet();
  };

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">

      <div className="mb-5 flex flex-col items-center justify-center mt-8">
        <img className="w-12 h-12 mx-auto"
          src={`/ico/constellation/Constellation=${getZodiac(profileInfo.zodiac)}, Color=white.svg`}
          alt=""/>
        <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
          {`${profileInfo.first_name || ""} ${profileInfo.last_name || ""}`.trim()}
        </h1>
        <div className="text-center">
          <p className="text-sm text-tg-theme-subtitle">
            {profileInfo.zodiac.charAt(0).toUpperCase() + profileInfo.zodiac.slice(1).toLowerCase()}
          </p>
          <p className="text-sm text-tg-theme-subtitle">
            {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")}
            - 
            ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
          </p>
        </div>
      </div>

      <div className="mb-5 text-center text-gold-gradient px-10">
        Анализ натальной карты на любовь и взаимоотношения
      </div>

      <div className="relative w-full max-w-sm mx-auto my-5">
        <div className="flex items-center justify-between">
          <div className="w-40 h-40 mx-auto">
            <img className="w-full h-full" src={`/img/analyse/${type}.svg`} alt=""/>
          </div>
        </div>
      </div>

      {isAnalysisProcessing && (
        <div className="flex flex-col items-center justify-center flex-1 px-5">
          <img className="w-20 h-20 mb-4" src="/ico/logo.svg" alt=""/>
          <p className="text-center text-tg-theme-subtitle">
            Собираем анализ вашей натальной карты...
          </p>
        </div>
      )}

      {isAnalysisReady && (
        <div className="px-2 my-10 space-y-10">
          {natalChartAnalyse.details?.map((item, index) => {
            return (
              <div key={index} className="flex gap-2">
                <div className="mt-[1.2rem]">
                  <img
                    className="w-8"
                    style={{ minWidth: "2rem" }}
                    src={`/ico/planets/Rulership=${item.planet?.toLowerCase()}.svg`}
                    alt=""
                  />
                </div>
                <div>
                  <div className="mb-5">
                    <div className="text-sm font-bold text-[#AFAFDE]">{item.planet}</div>
                    <div
                      className="text-sm font-bold text-[#AFAFDE]">{item.zodiac}, {formatPosition(item.position!)}</div>
                    <div className="text-sm font-bold text-[#AFAFDE]">{item.house?.replace(/_/g, " ")}</div>
                  </div>

                  <div className="text-gold-gradient mb-5">
                    {item.summary}
                  </div>

                  <div className="text-sm text-[#AFAFDE]">
                    {item.description}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {showPaymentButton && (
        <div className="px-5">
          <div>
            Чтобы построить крепкие и гармоничные отношения, важно глубоко понять себя и свои особенности.
            Наша нейросеть проведет детальный анализ вашей натальной карты, предлагая объективный и точный взгляд на все
            аспекты вашей личности, связанные с отношениями и любовью.
          </div>

          <div className="flex flex-col items-center justify-center w-full my-10">
            <div className="text-center mb-5">
              В данном анализе будет:
            </div>

            <div className="inline-block text-left">
              <div className="flex items-center gap-4 py-2">
                <img src="/ico/arrow-up.svg" alt=""/>
                <p>Корни вашей личности</p>
              </div>
              <div className="flex items-center gap-4 py-2">
                <img src="/ico/arrow-up.svg" alt=""/>
                <p>Ваши особенности в отношениях</p>
              </div>
              <div className="flex items-center gap-4 py-2">
                <img src="/ico/arrow-up.svg" alt=""/>
                <p>Что мешает гармонии в отношениях</p>
              </div>
              <div className="flex items-center gap-4 py-2">
                <img src="/ico/arrow-up.svg" alt=""/>
                <p>Ваши истинные потребности</p>
              </div>
              <div className="flex items-center gap-4 py-2">
                <img src="/ico/arrow-up.svg" alt=""/>
                <p>Ваше предназначение</p>
              </div>
            </div>
          </div>

          <div className="my-10">
            Открыйте новый уровень осознанности в отношениях и получите точные ответы на самые важные вопросы о любви и
            совместимости
          </div>

          <div
            className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer"
            onClick={() => openBottomSheet("pay")}>

            <div className="mb-1 flex justify-center">
              <img src="/ico/arrow-up.svg" alt=""/>
            </div>
            <div className="px-5 font-bold text-base text-gold-gradient">
              Заказать анализ
            </div>
            <div className="mb-1 flex justify-center">
              <img src="/ico/arrow-up.svg" alt=""/>
            </div>
          </div>

          <div className="w-full flex justify-center my-10">
            <hr className="w-3/5"/>
          </div>

          <div className="grid gap-12">
            <div className="grid gap-3">
              <p className="italic text-sm text-[#AFAFDE]">o Дева ☽ Рак ↑ Скорпион</p>
              <p className="italic text-sm text-[#AFAFDE]">
                ”Это определенно помогло мне с некоторыми ориентиами и взглядами на то, как я подхожу своим
                отношениям...“
              </p>
            </div>
            <div className="grid gap-3">
              <p className="italic text-sm text-[#AFAFDE]">o Близнецы ☽ Лев ↑ Телец</p>
              <p className="italic text-sm text-[#AFAFDE]">
                ”Очень верно и знакомо. Важно помнить о том, какова основная цель моей жизни, кем
                я был в детстве, где находятся трещины, что я игнорировал.”
              </p>
            </div>
            <div className="grid gap-3">
              <p className="italic text-sm text-[#AFAFDE]">o Овен ☽ Водолей ↑ Лев</p>
              <p className="italic text-sm text-[#AFAFDE]">
                ”Это прекрасно и очень точно про меня... сейчас я понимаю почему так, чем когда я
                первый раз прочитай свой анализ. возможно, я просто забыл или не понял его тогда, но теперь всё стало
                ясно.”
              </p>
            </div>
          </div>

        </div>
      )}

      <BottomSheet name="pay" maxHeight="50vh">
        <PaymentForm
          onSubmit={handlePaymentSubmit}
          currencies={[
            { type: "stars", cost: "100", name: "Stars" },
            { type: "fens", cost: "10", name: "Fens" }
          ]}
          buttonText="Заказать анализ"
          description="Закажите расширенный анализ с расшифровкой которая будет всегда вам доступна"
        />
      </BottomSheet>

    </div>
  )
}

export default NatalChartAnalysePage