import React from "react";
import { getBrightestStarByZodiac, getZodiac } from "app/types/zodiac";
import PlanetInfo from "widgets/components/planetinfo";
import { Profile } from "app/types/profile";
import CompatibilityIndicator from "../compatibilityIndicator";
import { formatDate } from "utils";

interface AstroProfileProps {
  data: Profile;
}

const AstroProfile: React.FC<AstroProfileProps> = ({ data }) => {

  const zodiacStr = getZodiac(data.zodiac)

  return (
    <div>
      <div className="flex justify-between mb-5">
        <div className="text-xs text-gold-gradient">{data.first_name} {data.last_name}</div>
        <div className="text-xs text-gold-gradient">{formatDate(new Date(data.birthday))}</div>
      </div>

      <div className="flex max-w-4xl mx-auto overflow-hidden">

        <div className="w-1/4 bg-card-stars">
          <div className="bg-custom-radial">
            <img src={`/img/constellation/${zodiacStr}.svg`} alt="" className="rounded-full w-14 h-14 mx-auto mb-2"/>
            <h2 className="text-xs text-center font-bold capitalize text-gold-gradient">{zodiacStr}</h2>
          </div>
        </div>

        <div className="w-3/4 grid items-center">
          <div className="flex justify-between mb-2">
            <div className="flex items-center">
              <img src="/ico/star-point.svg" alt="" className="w-6 h-6 ms-3 me-2"/>
              <p className="capitalize text-xs text-gold-gradient">
                {getBrightestStarByZodiac(data.zodiac)?.name}
              </p>
            </div>
            <PlanetInfo position={47}/>
          </div>

          <CompatibilityIndicator/>

        </div>
      </div>
    </div>
  );
};

export default AstroProfile;
