import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLazyAccountFetchQuery, useLazyAccountSettingsFetchQuery } from "app/services/account";
import { SessionRequest, useCreateSessionMutation } from "app/services/session";
import { setAccount, setAccountSettings, setCredentials } from "features/auth/authSlice";

import Oops from "widgets/components/oops";
import Preloader from "widgets/components/preloader";
import { Session } from "app/types/session";
import { useWebApp } from "app/telegram";
import { handleError } from "../utils";
import { fakeInitData } from "../mocks/stubs";

const Loader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ isAuthorizeFailed, setIsAuthorizeFailed ] = useState(false)

  const webApp = useWebApp();

  webApp.ready();
  webApp.expand();

  const [ createSession ] = useCreateSessionMutation();
  const [ accountFetch ] = useLazyAccountFetchQuery();
  const [ accountSettingsFetch ] = useLazyAccountSettingsFetchQuery();

  const authAccount = useCallback(async (session: Session) => {
    try {
      dispatch(setCredentials(session));

      const account = await accountFetch({}).unwrap();

      dispatch(setAccount(account));

    } catch (err) {
      handleError(err, "Failed to fetch account")
      throw err
    }
  }, [ dispatch, accountFetch ]);

  const fetchAccountSettings = useCallback(async (session: Session) => {
    try {
      const settings = await accountSettingsFetch().unwrap();

      dispatch(setAccountSettings(settings));

    } catch (err) {
      handleError(err, "Failed to fetch account settings")
      throw err
    }
  }, [ dispatch, accountSettingsFetch ]);

  const authorize = useCallback(async (data: string) => {
    try {
      let body: SessionRequest = {
        init_data: data,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale: navigator.language.split("-")[0] || Intl.DateTimeFormat().resolvedOptions().locale,
      }

      const startParam = webApp.initDataUnsafe.start_param;
      if (startParam) {
        const startapp = /(\w+)-(.*)/;
        const [ , target, id ] = startParam.match(startapp) || [];
        body.ref = target === "ref" ? id : undefined;
      }

      const session = await createSession(body).unwrap();

      await authAccount(session)
      await fetchAccountSettings(session)

    } catch (err) {
      handleError(err, "Failed to create session")
      throw err
    }
  }, [ dispatch, createSession ]);

  useEffect(() => {
    let initData = webApp.initData;

    if (process.env.REACT_APP_ENV === "mocks") {
      initData = fakeInitData
    }

    if (initData) {
      authorize(webApp.initData)
        .then(() => {
          navigate("/");
        })
        .catch(err => {
          console.error("Unhandled authorization error:", err);
          setIsAuthorizeFailed(true)
        });
      return
    }
  }, [ webApp.initData ]);

  if (isAuthorizeFailed) return (
    <div className="flex flex-col justify-center items-center h-[calc(100dvh)] max-w-72 m-auto">
      <Oops/>
    </div>
  )

  return <Preloader/>
};

export default Loader;
