import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { useTelegram } from "context/telegram.provider";
import { BottomSheet, useBottomSheet } from "context/bottomsheet.context";
import { useProfileListQuery, useProfileUpdateMutation } from "app/services/profile";
import { useAccountSettingsUpdateMutation } from "app/services/account";
import { getZodiac, Zodiac } from "app/types/zodiac";
import ZodiacList from "../zodiac/zodiacList";
import ReferralLink from "widgets/components/referallink";
import Card from "widgets/components/card";
import Language from "widgets/forms/language";
import Timezone from "widgets/forms/timezone";
import ZodiacSelect from "widgets/forms/zodiacselect";
import { languages } from "widgets/forms/language/languages";
import DatePicker from "widgets/forms/datepicker";
import TimePicker from "widgets/forms/timepicker";
import { formatDateTime, handleError } from "utils";
import AccountPersonalInfoForm from "../profile/forms/accountPersonalInfoForm";

type FormDataProfile = {
  first_name?: string;
  last_name?: string;
  city?: string;
  country?: string;
  birthday?: number;
}

type FormDataSettings = {
  language: string;
  timezone: string;
  report_time: string;
  report_language: string;
  zodiacs: Zodiac[];
};

type ProfileProps = "first_name" | "last_name" | "city" | "country" | "birthday";
type SettingProps = "language" | "timezone" | "report_time" | "report_language";

const AccountSettingsPage = () => {
  const navigate = useNavigate();
  const { settings } = useAccount();
  const { screen, setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();

  const [ updateAccountSettings ] = useAccountSettingsUpdateMutation();
  const [ updateProfile ] = useProfileUpdateMutation();

  const [ formProfileData, setFormProfileData ] = useState<FormDataProfile>();
  const [ formSettingsData, setFormSettingsData ] = useState<FormDataSettings>({ ...settings });

  const {
    data: profiles,
    isLoading: isProfilesLoading,
  } = useProfileListQuery();

  const [ tempReportTime, setTempReportTime ] = useState<string>("");
  const [ tempZodiacs, setTempZodiacs ] = useState<Zodiac[]>([]);

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  // Configure navigation routing
  useEffect(() => {
    if (!!screen) return;
    setBackButtonVisible(true);
    setBackButtonOnClick(() => goToHome());
  }, [ screen ]);

  useEffect(() => {
    if (!!profiles?.length) {
      setFormProfileData(profiles?.filter(p => p.is_default)[0])
    }
  }, [ profiles ]);

  const goToHome = () => {
    navigate("/");
  };

  if (isProfilesLoading) return <div/>;
  if (!profiles?.length) {
    navigate("/");
    return
  }

  const defaultProfile = profiles?.filter(p => p.is_default)[0];

  const handleUpdateProfile = async () => {
    if (!formProfileData || !defaultProfile) return;

    try {
      const { first_name, last_name, birthday } = formProfileData;
      await updateProfile({
        id: defaultProfile.id,
        data: {
          ...defaultProfile,
          first_name: first_name ?? defaultProfile.first_name,
          last_name: last_name ?? defaultProfile.last_name,
          birthday: birthday != null ? Math.floor(birthday / 1000) : defaultProfile.birthday,
        }
      }).unwrap();
      closeBottomSheet();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  const handleUpdateSettings = async () => {
    try {
      const newSettings = {
        ...formSettingsData,
        zodiacs: tempZodiacs.length > 0 ? tempZodiacs : formSettingsData.zodiacs
      };
      await updateAccountSettings(newSettings).unwrap();
      setFormSettingsData(newSettings);
      setTempZodiacs([]);
      closeBottomSheet();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  const handleChangeProfile = (name: keyof FormDataProfile, value: string | number) => {
    setFormProfileData(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeZodiacs = (zodiacs: Zodiac[]) => {
    setTempZodiacs(zodiacs);
  }

  const handleBirthdayChange = (v: number) => {
    setFormProfileData({ ...formProfileData, birthday: v })
  }

  const handleTimeChange = (v: string) => {
    setTempReportTime(v);
  }

  const handleUpdateReportTime = async () => {
    const newSettings = { ...formSettingsData, report_time: tempReportTime };
    try {
      await updateAccountSettings(newSettings).unwrap();
      setFormSettingsData(newSettings);
      closeBottomSheet();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  const handleChangeSettings = async (name: SettingProps | ProfileProps, value: string) => {
    const newSettings = { ...formSettingsData, [name]: value };
    setFormSettingsData(newSettings);

    try {
      await updateAccountSettings(newSettings).unwrap();
      closeBottomSheet();
    } catch (err) {
      handleError(err, err instanceof Error ? err.message : "An unexpected error occurred");
    }
  };

  return (
    <div className="w-full min-h-screen constellation flex flex-col">

      <div className="flex flex-col items-center justify-center mt-8">
        <h1 className="text-3xl text-tg-theme-section-header">Zodycus</h1>
      </div>

      <div className="w-full mt-5 py-5 text-center">
        <img className="w-24 h-24 mx-auto mb-2"
          src={`/img/constellation/${getZodiac(defaultProfile?.zodiac).toLowerCase()}.svg`} alt=""/>
        <p className="capitalize text-tg-theme-section-header">
          {`${defaultProfile?.first_name || ""} ${defaultProfile?.last_name || ""}`.trim()}
        </p>
        <p className="text-xs text-tg-theme-section-header">
          {`${[
            defaultProfile?.birthday ? formatDateTime(new Date(defaultProfile.birthday)) : null,
            defaultProfile?.city,
            defaultProfile?.country
          ].filter(Boolean).join(", ")}`.trim()}
        </p>
        <button className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
          onClick={() => openBottomSheet("profile")}>
          Изменить
        </button>
      </div>

      <div className="my-10 px-10">
        <p className="text-xs font-bold text-center mb-5 text-[#AFAFDE]">
          Вы можете повысить уровень профиля за приглашенных вами друзей. Расскажите им о приложении и вы оба получите
          дополнительно по 3 дня доступа.
        </p>
        <ReferralLink/>
      </div>

      <div className="mb-10 px-10">
        <h2 className="font-bold text-center text-[#AFAFDE] mb-5">
          Настройка локализации
        </h2>

        <Card title="Язык интерфеса" className="mb-3" titleClassName="normal-case"
          onClick={() => openBottomSheet("language")}>
          <div className="flex justify-between">
            <h5 className="text-[#AFAFDE]">
              {languages.find(l => l.value === formSettingsData.language)?.hint}
            </h5>
            <div className="inline-flex items-center justify-end">
              <img src={"/ico/arrow-down.svg"} alt=""/>
            </div>
          </div>
        </Card>

        <Card title="Язык гороскопов" className="mb-3" titleClassName="normal-case"
          onClick={() => openBottomSheet("report_language")}>
          <div className="flex justify-between">
            <h5 className="text-[#AFAFDE]">
              {languages.find(l => l.value === formSettingsData.report_language)?.hint}
            </h5>
            <div className="inline-flex items-center justify-end">
              <img src={"/ico/arrow-down.svg"} alt=""/>
            </div>
          </div>
        </Card>

        <Card title="Часовой пояс" className="mb-3" titleClassName="normal-case"
          onClick={() => openBottomSheet("timezone")}>
          <div className="flex justify-between">
            <h5 className="text-[#AFAFDE]">
              {formSettingsData.timezone}
            </h5>
            <div className="inline-flex items-center justify-end">
              <img src={"/ico/arrow-down.svg"} alt=""/>
            </div>
          </div>
        </Card>
      </div>

      <div className="px-5">
        <h2 className="font-bold text-center text-[#AFAFDE] mb-5">
          Отслежваемые горосокопы
        </h2>

        <div className="w-full flex justify-center items-center gap-8 mb-10">
          <div className="text-xs text-[#AFAFDE]">Время доставки</div>
          <div className="bg-card-gradient text-tg-theme-section-header rounded-xl px-5 py-1 cursor-pointer"
            onClick={() => openBottomSheet("report_time")}>
            {formSettingsData.report_time}
          </div>
        </div>

        <div className="mb-5">
          <ZodiacList
            selectedZodiacs={formSettingsData?.zodiacs}
            showOnlySelected={true}
          />
        </div>

        <div className="grid justify-center">
          <button className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
            onClick={() => openBottomSheet("zodiacs")}
          >
            Изменить
          </button>
        </div>

      </div>

      <BottomSheet name="profile" title="Персональная информация" noscrollable>
        <>
          <div className="mb-5">
            <AccountPersonalInfoForm data={formProfileData} onChange={handleChangeProfile}/>
          </div>

          <div className="mb-5">
            <DatePicker
              value={defaultProfile?.birthday}
              onChange={handleBirthdayChange}
              label="Дата рождения"
              timeLabel="Знаешь во сколько родился?"
              isTimeEditable={true}
              alwaysOpen={false}
            />
          </div>

          <div className="w-full flex justify-center">
            <button className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
              onClick={handleUpdateProfile}
            >
              Обновить
            </button>
          </div>
        </>
      </BottomSheet>

      <BottomSheet name="report_time" title="Время доставки гороскопов" maxHeight="60vh" noscrollable>
        <>
          <div className="mb-5">
            <TimePicker
              label={"Время доставки"}
              value={formSettingsData.report_time}
              onChange={handleTimeChange}
              alwaysTimeShowOpen={true}
            />
          </div>

          <div className="w-full flex justify-center">
            <button
              className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
              onClick={handleUpdateReportTime}
            >
              Обновить
            </button>
          </div>
        </>
      </BottomSheet>

      <BottomSheet name="language" title="Выберите язык интерфейса" noscrollable>
        <Language value={formSettingsData.language}
          onSelect={(v) => handleChangeSettings("language", v)}/>
      </BottomSheet>

      <BottomSheet name="report_language" title="Выберите язык гороскопов" noscrollable>
        <Language value={formSettingsData.report_language}
          onSelect={(v) => handleChangeSettings("report_language", v)}/>
      </BottomSheet>

      <BottomSheet name="timezone" title="Выберите часовой пояс" noscrollable>
        <Timezone value={formSettingsData.timezone}
          onSelect={(v) => handleChangeSettings("timezone", v)}/>
      </BottomSheet>

      <BottomSheet name="zodiacs" title="Выберите знаки зодиака для отслеживания">
        <>
          <ZodiacSelect
            value={formSettingsData.zodiacs}
            onChange={handleChangeZodiacs}
          />

          <div className="w-full flex justify-center">
            <button
              className="mt-5 px-8 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
              onClick={handleUpdateSettings}
            >
              Обновить
            </button>
          </div>
        </>
      </BottomSheet>

    </div>
  );
};

export default AccountSettingsPage