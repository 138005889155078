import React, { FC, useEffect, useRef, useState } from "react";
import "./style.css";

export interface DropDownOption {
  value: string;
  label: string;
}

export interface DropDownProps {
  options: DropDownOption[];
  value?: string;
  isOpen?: boolean;
  alwaysOpen?: boolean;
  onChange?: (selected: DropDownOption) => void;
  renderOption?: (option: DropDownOption, isActive: boolean) => React.ReactNode;
  renderTitle?: (selected: DropDownOption | undefined) => React.ReactNode;
}

const DropDown: FC<DropDownProps> = ({
  options,
  value,
  isOpen = false,
  alwaysOpen = false,
  onChange,
  renderOption,
  renderTitle,
}) => {
  const [ isOpenValue, setIsOpenValue ] = useState(alwaysOpen || isOpen);
  const [ selected, setSelected ] = useState<DropDownOption | undefined>(
    options.find((opt) => opt.value === value) || options[0]
  );
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isScrollingRef = useRef(false);
  const wheelContainerRef = useRef<HTMLDivElement | null>(null);
  const initializedRef = useRef(false);

  const toggleDropdown = () => !alwaysOpen && setIsOpenValue((prev) => !prev);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget as HTMLDivElement;
    const itemHeight = 40;
    const scrollTop = target.scrollTop;
    const index = Math.round(scrollTop / itemHeight);

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    isScrollingRef.current = true;

    if (index >= 0 && index < options.length) {
      setSelected(options[index]);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      const closestIndex = Math.round(target.scrollTop / itemHeight);
      target.scrollTo({
        top: closestIndex * itemHeight,
        behavior: "smooth",
      });
      isScrollingRef.current = false;

      // Notify change after scrolling has finished
      onChange?.(options[closestIndex]);
    }, 150);
  };

  const scrollToSelected = (behavior: ScrollBehavior = "smooth") => {
    const itemHeight = 40;
    const selectedIndex = options.findIndex(
      (option) => option.value === selected?.value
    );

    if (wheelContainerRef.current && selectedIndex >= 0) {
      wheelContainerRef.current.scrollTo({ top: selectedIndex * itemHeight, behavior });
    }
  };

  useEffect(() => {
    if (isOpenValue && !initializedRef.current) {
      scrollToSelected("auto");
      initializedRef.current = true;
    } else if (isOpenValue) {
      scrollToSelected("smooth");
    }
  }, [ isOpenValue ]);

  // Synchronize selected with value prop
  useEffect(() => {
    if (value !== undefined && value !== selected?.value) {
      const newSelected = options.find((opt) => opt.value === value);
      setSelected(newSelected);
      scrollToSelected("auto"); // Scroll to new value immediately
    }
    if (isOpenValue) {
      scrollToSelected("smooth");
    }
  }, [ value ]);

  return (
    <div className="dropdown">
      <div
        className={`dropdown-header
          ${alwaysOpen ? "" : "cursor-pointer"} 
          ${!isOpenValue ? "" : "border-b border-gray-600 pb-2"}
        `}
        onClick={toggleDropdown}
      >
        <span className="title">
          {renderTitle ? renderTitle(selected) : selected?.label || "Не выбрано"}
        </span>
        <span className="action">
          <img
            src={isOpenValue ? "/ico/arrow-down.svg" : "/ico/arrow-right.svg"}
            alt=""
            className="dropdown-icon w-6 h-6"
          />
        </span>
      </div>

      <div className={`dropdown-content ${isOpenValue ? "block" : "hidden"}`}>
        <div
          className="wheel"
          ref={wheelContainerRef}
          onScroll={handleScroll}
        >
          <div className="wheel-container">
            <ul className="wheel-list">
              <li style={{ height: "40px" }}></li>
              {options.map((option, idx) => {
                const isActive = option.value === selected?.value;
                return (
                  <li
                    key={idx}
                    className={`wheel-item ${isActive ? "active" : ""}`}
                    style={{ height: "40px" }}
                  >
                    {renderOption
                      ? renderOption(option, isActive)
                      : option.label}
                  </li>
                );
              })}
              <li style={{ height: "40px" }}></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
