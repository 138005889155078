import React, { FC } from "react";
import { getZodiac } from "../../app/types/zodiac";
import { useTranslation } from "react-i18next";
import { ProfileCompatibilities, ProfileCompatibility } from "../../app/types/astrology";

interface ICompatibilityListProps {
  compatibilities: ProfileCompatibilities;
}

const ProfileCompatibilityList: FC<ICompatibilityListProps> = ({ compatibilities }) => {
  return (
    <>
      <div className="relative grid grid-cols-3 gap-2">
        {compatibilities && compatibilities?.general && (
          <CompatibilityCard key={"general"} compatibility={compatibilities.general}
          />
        )}

        {compatibilities && compatibilities?.love && (
          <CompatibilityCard key={"love"} compatibility={compatibilities.love}
          />
        )}

        {compatibilities && compatibilities?.work && (
          <CompatibilityCard key={"work"} compatibility={compatibilities.work}
          />
        )}
      </div>
    </>
  );
};

interface CompatibilityCardProps {
  compatibility: ProfileCompatibility;
  onClick?: () => void;
  disabled?: boolean;
}

export const CompatibilityCard: FC<CompatibilityCardProps> = ({ onClick, compatibility, disabled }) => {

  const { t } = useTranslation();

  const convertToCirclesValue = (value: number): number => {
    if (value <= 25) return 1;
    if (value <= 75) return 2;
    return 3;
  };

  const renderCircles = (v: number) => {
    const circlesValue = convertToCirclesValue(v);
    const src =
      circlesValue === 3
        ? "/ico/circle-green.svg"
        : circlesValue < 3
          ? "/ico/circle-yellow.svg"
          : "/ico/circle-red.svg";
    return Array(circlesValue).fill(null).map((_, i) => (
      <img key={i} src={src} alt="" className="w-4 h-4"/>
    ));
  };

  return (
    <>
      <div
        className="flex flex-col items-center justify-between h-full"
        onClick={!disabled ? onClick : undefined}
      >
        <div>
          {t(`features.compatibility.group.${compatibility.group}.title`)}
        </div>

        <div
          className={`
            relative w-full aspect-square text-center my-2 p-3 cursor-pointer 
            rounded-xl flex flex-col items-center justify-between bg-card-gradient-stars
            ${disabled ? "opacity-30" : ""}
        `}>
          <div className={`
            w-3/5 aspect-square flex items-center justify-center bg-custom-radial
          `}>
            <img
              src={`/img/constellation/${getZodiac(compatibility.zodiac)}.svg`}
              alt={getZodiac(compatibility.zodiac)}
              className="h-16 object-contain"
            />
          </div>
          <p className="mt-2 text-sm text-[#FCF0A1]">
            {getZodiac(compatibility.zodiac)}
          </p>
        </div>

        <div className="flex justify-center space-x-1">
          {renderCircles(compatibility.percentage)}
        </div>

      </div>
    </>
  );
};

export default ProfileCompatibilityList;