import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

interface BottomSheetContextType {
  isOpen: boolean;
  openBottomSheet: (name?: string) => void;
  closeBottomSheet: () => void;
  activeSheetName?: string;
}

const BottomSheetContext = createContext<BottomSheetContextType | undefined>(undefined);

export const useBottomSheet = () => {
  const context = useContext(BottomSheetContext);
  if (!context) {
    throw new Error("useBottomSheet must be used within a BottomSheetProvider");
  }
  return context;
};

export const BottomSheetProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ activeSheetName, setActiveSheetName ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isOpen) {
      window.Telegram?.WebApp?.disableVerticalSwipes();
    } else {
      window.Telegram?.WebApp?.enableVerticalSwipes?.();
    }

    return () => {
      window.Telegram?.WebApp?.enableVerticalSwipes?.();
    };
  }, [isOpen]);

  const openBottomSheet = (name?: string) => {
    setActiveSheetName(name);
    setIsOpen(true);
  };

  const closeBottomSheet = () => {
    setActiveSheetName(undefined);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeBottomSheet();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <BottomSheetContext.Provider value={{ isOpen, openBottomSheet, closeBottomSheet, activeSheetName }}>
      {children}
      {isOpen &&
        ReactDOM.createPortal(
          <div
            className="fixed inset-0 z-[10] bg-card-gradient bg-opacity-50 transition-opacity duration-300 opacity-75"
            onClick={closeBottomSheet}
          />,
          document.body
        )}
    </BottomSheetContext.Provider>
  );
};

interface BottomSheetProps {
  children: React.ReactNode;
  title?: string;
  name?: string;
  noscrollable?: boolean;
  onClose?: () => void;
  maxHeight?: string;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({ 
  name, 
  children, 
  title, 
  noscrollable, 
  onClose,
  maxHeight = "90vh"
}) => {
  const { isOpen, closeBottomSheet, activeSheetName } = useBottomSheet();
  const [ shouldRender, setShouldRender ] = useState(false);
  const [ isAnimating, setIsAnimating ] = useState(false);

  // Track the starting Y position when drag begins
  const [ dragStartY, setDragStartY ] = useState<number | null>(null);
  // Track the current vertical translation of the bottom sheet
  const [ currentTranslateY, setCurrentTranslateY ] = useState(0);
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && activeSheetName === name) {
      setCurrentTranslateY(0);
      setShouldRender(true);
      setTimeout(() => setIsAnimating(true), 10);
    } else {
      setIsAnimating(false);
      const timeout = setTimeout(() => setShouldRender(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [ isOpen, activeSheetName, name ]);

  // Handle the start of drag gesture (both touch and mouse events)
  const handleDragStart = (e: React.TouchEvent | React.MouseEvent) => {
    const clientY = "touches" in e ? e.touches[0].clientY : (e as React.MouseEvent).clientY;
    setDragStartY(clientY);
  };

  // Handle the drag movement - updates sheet position as user drags
  const handleDrag = (e: React.TouchEvent | React.MouseEvent) => {
    if (dragStartY === null) return;

    const clientY = "touches" in e ? e.touches[0].clientY : (e as React.MouseEvent).clientY;
    const delta = clientY - dragStartY;

    // Only allow dragging downwards
    if (delta > 0) {
      setCurrentTranslateY(delta);
    }
  };

  // Handle the end of drag gesture
  const handleDragEnd = () => {
    if (dragStartY === null) return;

    // Calculate threshold for closing (30% of sheet height)
    const bottomSheetHeight = bottomSheetRef.current?.clientHeight || 0;
    const closeThreshold = bottomSheetHeight * 0.3;

    // If dragged past threshold, close the sheet
    if (currentTranslateY > closeThreshold) {
      setCurrentTranslateY(bottomSheetHeight);
      closeBottomSheet();
      onClose?.();
    } else {
      // Otherwise snap back to original position
      setCurrentTranslateY(0);
    }
    setDragStartY(null);
  };

  // Handle mouse events for desktop drag functionality
  useEffect(() => {
    if (dragStartY !== null) {
      const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        const delta = e.clientY - dragStartY;
        // Only allow dragging downwards
        if (delta > 0) {
          setCurrentTranslateY(delta);
        }
      };

      const handleMouseUp = () => {
        handleDragEnd();
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [ dragStartY, handleDragEnd, currentTranslateY, closeBottomSheet, onClose ]);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div
      ref={bottomSheetRef}
      className={`fixed inset-x-0 bottom-0 z-[11] bg-bottom-sheet-gradient rounded-t-lg shadow-lg 
        transition-all duration-300 ease-out transform
        ${isAnimating ? "translate-y-0" : "translate-y-full"}`}
      style={{
        willChange: "transform",
        maxHeight: maxHeight,
        transform: `translateY(${currentTranslateY}px)`,
        transition: dragStartY !== null ? "none" : "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`
          drag-handle
          px-4 flex flex-col items-center
          ${!!title ? "pt-4 pb-5" : "py-4"}
        `}
        style={{
          touchAction: "none",
          userSelect: "none",
          WebkitUserSelect: "none",
          cursor: "grab"
        }}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
        onTouchMove={handleDrag}
        onTouchEnd={handleDragEnd}
      >
        <div className="w-10 h-1 bg-gray-300 rounded-full"/>
        {title && <h2 className="text-lg text-[#AFAFDE] mt-4">{title}</h2>}
      </div>

      <div 
        className={`relative p-4 ${!noscrollable ? "overflow-y-auto" : ""}`}
        style={{
          height: `calc(${maxHeight} - ${title ? "116px" : "76px"})`
        }}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};
