import { ReactNode } from "react";

interface ICard {
  title?: string;
  hint?: string;
  className?: string;
  titleClassName?: string;
  onClick?: () => void;
  children?: ReactNode;
  disabled?: boolean;
}

const Card = ({
  title,
  hint,
  children,
  onClick,
  className,
  titleClassName,
  disabled,
}: ICard) => {
  return (
    <>
      {title && (
        <div className={`text-tg-theme-section-header text-xs uppercase ml-3 mb-2 ${titleClassName}`}>
          {title}
        </div>
      )}
      <div
        className={`rounded-xl p-4 bg-card-gradient ${className} ${disabled ? "opacity-60 cursor-not-allowed" : ""}`}
        onClick={!disabled ? onClick : undefined}
      >
        {children}
      </div>
      <div className="text-xs font-light text-tg-theme-hint ml-3 py-1">
        {hint}
      </div>
    </>
  );
};

export default Card;