import React, { useEffect } from "react";
import { useAccount } from "hooks/useAccount";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../context/telegram.provider";
import { useNavigate } from "react-router-dom";
import { FeatureWelcomePath } from "../routes";
import { ScreenProfilePath } from "./screenProfile";


export const ScreenHelloPath = "hello";
const ScreenHello = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAccount();
  
  const { setMainButtonVisible, setMainButtonProps, setBackButtonVisible } = useTelegram();

  const submit = () => {
    console.log("go to profile");
    return navigate(FeatureWelcomePath(ScreenProfilePath));
  };

  useEffect(() => {
    setBackButtonVisible(false)
  }, []);

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(
      t("features.welcome.screens.screenHello.mainButton"),
      submit,
    );
  }, [ setMainButtonProps ]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen constellation">
      <div className="my-6 text-center">
        <img src="/logo.svg" className="w-48 h-48" alt=""/>
      </div>

      <h1 className="text-2xl font-bold">
        {t("features.welcome.screens.screenHello.hello")},{" "}
        <span className="capitalize">
          {account.account.first_name || account.account.username}!
        </span>
      </h1>

      <div className="mt-2 px-8 text-center text-white">
        {t("features.welcome.screens.screenHello.desc")}
      </div>
    </div>
  );
};

export default ScreenHello;
