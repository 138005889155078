import React, { FC, memo, useMemo } from "react";
import { getZodiac, Zodiac } from "app/types/zodiac";
import { AspectType, UniverseEntity } from "app/types/astrology";
import { NatalChartAspect, NatalChartHouse, NatalChartPlanet } from "app/types/natal_chart";

interface ExtendedNatalChartPlanet extends NatalChartPlanet {
  color?: string;
}

interface ExtendedNatalChartAspect extends NatalChartAspect {
  color?: string;
}

export interface NatalChartProps {
  size?: number;
  planets?: ExtendedNatalChartPlanet[];
  ascendant: number;
  houses?: NatalChartHouse[];
  aspects?: ExtendedNatalChartAspect[];
  zodiacSign?: Zodiac
  showHouseNumbers?: boolean;
  showDegrees?: boolean;
}

const CHART_CONSTANTS = {
  VIEW_BOX_SIZE: 600,
  OUTER_RADIUS: 280,
  INNER_RADIUS: 245,
  HOUSES_OUTER_RADIUS: 240,
  HOUSES_INNER_RADIUS: 210,
  MAJOR_DEGREE_LENGTH: 10,
  MINOR_DEGREE_LENGTH: 5,
} as const;

const ZODIAC_SIGNS = [
  Zodiac.ARIES, Zodiac.TAURUS, Zodiac.GEMINI, Zodiac.CANCER,
  Zodiac.LEO, Zodiac.VIRGO, Zodiac.LIBRA, Zodiac.SCORPIO,
  Zodiac.SAGITTARIUS, Zodiac.CAPRICORN, Zodiac.AQUARIUS, Zodiac.PISCES
].reverse();

const ASPECT_STYLES: Record<AspectType, { stroke: string; strokeWidth: number; strokeDasharray?: string }> = {
  [AspectType.CONJUNCTION]: { stroke: "#AFAFDE", strokeWidth: 0.2 },
  [AspectType.SEXTILE]: { stroke: "#AFAFDE", strokeWidth: 0.2, strokeDasharray: "4,4" },
  [AspectType.SQUARE]: { stroke: "#AFAFDE", strokeWidth: 0.2, strokeDasharray: "6,6" },
  [AspectType.TRINE]: { stroke: "#AFAFDE", strokeWidth: 0.2 },
  [AspectType.OPPOSITION]: { stroke: "#AFAFDE", strokeWidth: 0.2, strokeDasharray: "10,10" },
  [AspectType.QUINCUNX]: { stroke: "#AFAFDE", strokeWidth: 0.2, strokeDasharray: "3,5" },
  [AspectType.SEMISEXTILE]: { stroke: "#AFAFDE", strokeWidth: 0.2, strokeDasharray: "2,4" },
};

const PLANET_SYMBOLS: Record<UniverseEntity, string> = {
  [UniverseEntity.SUN]: "☉",
  [UniverseEntity.MOON]: "☽",
  [UniverseEntity.MERCURY]: "☿",
  [UniverseEntity.VENUS]: "♀",
  [UniverseEntity.MARS]: "♂",
  [UniverseEntity.JUPITER]: "♃",
  [UniverseEntity.SATURN]: "♄",
  [UniverseEntity.URANUS]: "♅",
  [UniverseEntity.NEPTUNE]: "♆",
  [UniverseEntity.PLUTO]: "♇",
};

const toRoman = (num: number): string => {
  const roman = [ "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII" ];
  return roman[num - 1];
};

const createArc = (startAngle: number, endAngle: number, innerRad: number, outerRad: number): string => {
  const startRad = ((startAngle + 180) * Math.PI) / 180;
  const endRad = ((endAngle + 180) * Math.PI) / 180;

  const startOuter = {
    x: Math.cos(startRad) * outerRad,
    y: Math.sin(startRad) * outerRad
  };
  const endOuter = {
    x: Math.cos(endRad) * outerRad,
    y: Math.sin(endRad) * outerRad
  };
  const startInner = {
    x: Math.cos(startRad) * innerRad,
    y: Math.sin(startRad) * innerRad
  };
  const endInner = {
    x: Math.cos(endRad) * innerRad,
    y: Math.sin(endRad) * innerRad
  };

  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

  return `
    M ${startInner.x},${startInner.y}
    L ${startOuter.x},${startOuter.y}
    A ${outerRad},${outerRad} 0 ${largeArcFlag} 1 ${endOuter.x},${endOuter.y}
    L ${endInner.x},${endInner.y}
    A ${innerRad},${innerRad} 0 ${largeArcFlag} 0 ${startInner.x},${startInner.y}
  `;
};

const createHouseArc = (startAngle: number, endAngle: number, innerRad: number, outerRad: number): string => {

  // Нормализуем углы и обрабатываем переход через 0/360
  let normalizedStart = startAngle % 360;
  let normalizedEnd = endAngle % 360;

  if (normalizedStart < 0) normalizedStart += 360;
  if (normalizedEnd < 0) normalizedEnd += 360;

  // Обрабатываем случай, когда дуга проходит через 0 градусов
  if (normalizedEnd < normalizedStart) {
    normalizedEnd += 360;
  }

  // Преобразуем углы в радианы
  const startRad = ((normalizedStart + 180) * Math.PI) / 180;
  const endRad = ((normalizedEnd + 180) * Math.PI) / 180;

  const startOuter = {
    x: Math.cos(startRad) * outerRad,
    y: Math.sin(startRad) * outerRad
  };
  const endOuter = {
    x: Math.cos(endRad) * outerRad,
    y: Math.sin(endRad) * outerRad
  };
  const startInner = {
    x: Math.cos(startRad) * innerRad,
    y: Math.sin(startRad) * innerRad
  };
  const endInner = {
    x: Math.cos(endRad) * innerRad,
    y: Math.sin(endRad) * innerRad
  };

  const angleDiff = normalizedEnd - normalizedStart;
  const largeArcFlag = angleDiff > 180 ? 1 : 0;

  return `
    M ${startInner.x},${startInner.y}
    L ${startOuter.x},${startOuter.y}
    A ${outerRad},${outerRad} 0 ${largeArcFlag} 1 ${endOuter.x},${endOuter.y}
    L ${endInner.x},${endInner.y}
    A ${innerRad},${innerRad} 0 ${largeArcFlag} 0 ${startInner.x},${startInner.y}
  `;
};

const createTextArc = (startAngle: number, endAngle: number, radius: number): string => {
  const startRad = ((startAngle + 180) * Math.PI) / 180;
  const endRad = ((endAngle + 180) * Math.PI) / 180;

  return `
    M ${radius * Math.cos(startRad)} ${radius * Math.sin(startRad)}
    A ${radius} ${radius} 0 0 1 ${radius * Math.cos(endRad)} ${radius * Math.sin(endRad)}
  `;
};

const normalizeHouses = (houses: NatalChartHouse[]): {
  normalizedHouses: NatalChartHouse[],
  rotationAngle: number
} => {
  // Находим первый дом
  const firstHouse = houses.find(h => h.number === 1)!;
  // Сохраняем исходный градус первого дома как угол поворота
  const rotationAngle = firstHouse?.position || 0;

  // Нормализуем градусы, вычитая градус первого дома
  const normalizedHouses = houses.map(house => {
    let normalizedDegree = house.position - firstHouse.position;
    // Если после нормализации получился отрицательный угол, добавляем 360
    if (normalizedDegree < 0) {
      normalizedDegree += 360;
    }
    return {
      ...house,
      degree: normalizedDegree
    };
  });

  return {
    normalizedHouses: normalizedHouses.sort((a, b) => a.number - b.number),
    rotationAngle
  };
};

const DegreeMarks: FC<{ radius: number; rotationAngle: number }> = memo(({ radius, rotationAngle }) => {
  return (
    <>
      {Array.from({ length: 360 }, (_, i) => {
        const adjustedAngle = -(i + 180) % 360;
        const angle = adjustedAngle * Math.PI / 180;
        const isMajor = i % 10 === 0;
        const length = isMajor ? CHART_CONSTANTS.MAJOR_DEGREE_LENGTH : CHART_CONSTANTS.MINOR_DEGREE_LENGTH;

        const innerPoint = {
          x: radius * Math.cos(angle),
          y: radius * Math.sin(angle)
        };

        const outerPoint = {
          x: (radius + length) * Math.cos(angle),
          y: (radius + length) * Math.sin(angle)
        };

        const textPoint = {
          x: (radius - 15) * Math.cos(angle),
          y: (radius - 15) * Math.sin(angle)
        };

        return (
          <React.Fragment key={`degree-${i}`}>
            <line
              x1={innerPoint.x}
              y1={innerPoint.y}
              x2={outerPoint.x}
              y2={outerPoint.y}
              stroke="#AFAFDE"
              strokeWidth={isMajor ? 1.5 : 1}
            />
            {isMajor && (
              <g transform={`translate(${textPoint.x}, ${textPoint.y}) rotate(${-rotationAngle})`}>
                <text
                  x="0"
                  y="0"
                  textAnchor="middle"
                  alignmentBaseline="central"
                  style={{
                    fill: "#AFAFDE",
                    fontSize: "10px",
                    fontFamily: "Akkurat-Mono",
                  }}
                >
                  {i}°
                </text>
              </g>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
});

const ZodiacSigns: FC<{ sectors: any[]; zodiacs: Zodiac[] }> = memo(({ sectors, zodiacs }) => {
  return (
    <>
      {sectors.map((sector, i) => (
        <React.Fragment key={`zodiac-${i}`}>
          <path
            d={sector.path}
            style={{ fill: "#080E20", stroke: "#AFAFDE" }}
          />
          <path
            id={sector.id}
            d={sector.textPath}
            style={{ fill: "none" }}
          />
          <text
            style={{
              fill: "#AFAFDE",
              fontFamily: "Akkurat-Mono",
              fontSize: "14px",
              letterSpacing: "0.1em",
              dominantBaseline: "middle",
            }}
          >
            <textPath
              href={`#${sector.id}`}
              startOffset="50%"
              textAnchor="middle"
            >
              <tspan dy="-0.38em">{zodiacs[i]}</tspan>
            </textPath>
          </text>
        </React.Fragment>
      ))}
    </>
  );
});

const Houses: FC<{
  sectors: NatalChartHouse[];
  showNumbers?: boolean;
  rotationAngle: number;
  housesRotation: number;
}> = memo(({ sectors, showNumbers = true, rotationAngle, housesRotation }) => {
  const CIRCLE_RADIUS = 7;

  // Сортируем сектора по номеру дома
  const orderedSectors = [ ...sectors ].sort((a, b) => a.number - b.number);

  const createHouseSegment = (house: NatalChartHouse, nextHouse: NatalChartHouse) => {
    let startDegree = house.position;
    let endDegree = nextHouse.position;

    // Если конечный градус меньше начального, добавляем 360
    if (endDegree < startDegree) {
      endDegree += 360;
    }

    return {
      startDegree: -startDegree,
      endDegree: -endDegree
    };
  };

  return (
    <g transform={`rotate(${-housesRotation})`}>
      {/* Сначала рисуем фон арок */}
      {orderedSectors.map((house, i) => {
        const nextHouse = orderedSectors[(i + 1) % orderedSectors.length];
        const { startDegree, endDegree } = createHouseSegment(house, nextHouse);

        return (
          <path
            key={`house-bg-${house.number}`}
            d={createHouseArc(
              startDegree,
              endDegree,
              CHART_CONSTANTS.HOUSES_INNER_RADIUS,
              CHART_CONSTANTS.HOUSES_OUTER_RADIUS
            )}
            style={{
              fill: "none",
              stroke: "#AFAFDE0D",
              strokeWidth: "1"
            }}
          />
        );
      })}

      {/* Затем рисуем границы арок */}
      {orderedSectors.map((house, i) => {
        const nextHouse = orderedSectors[(i + 1) % orderedSectors.length];
        const { startDegree, endDegree } = createHouseSegment(house, nextHouse);

        return (
          <path
            key={`house-border-${house.number}`}
            d={createHouseArc(
              startDegree,
              endDegree,
              CHART_CONSTANTS.HOUSES_INNER_RADIUS,
              CHART_CONSTANTS.HOUSES_OUTER_RADIUS
            )}
            style={{
              fill: "none",
              stroke: "#AFAFDE0D",
              strokeWidth: "1"
            }}
          />
        );
      })}

      {/* В конце рисуем номера домов */}
      {showNumbers && orderedSectors.map((house, i) => {
        const nextHouse = orderedSectors[(i + 1) % orderedSectors.length];
        const { startDegree, endDegree } = createHouseSegment(house, nextHouse);

        const midAngle = (startDegree + ((endDegree - startDegree) / 2) + 180) * Math.PI / 180;
        const textRadius = CHART_CONSTANTS.HOUSES_INNER_RADIUS;
        const textPosition = {
          x: textRadius * Math.cos(midAngle),
          y: textRadius * Math.sin(midAngle)
        };

        return (
          <g
            key={`house-number-${house.number}`}
            transform={`translate(${textPosition.x}, ${textPosition.y})`}
          >
            <circle
              r={CIRCLE_RADIUS}
              fill="#091F31"
              stroke="#AFAFDE"
              strokeWidth="0.25"
            />
            <text
              x="0"
              y="0"
              textAnchor="middle"
              dominantBaseline="central"
              transform={`rotate(${-rotationAngle + housesRotation})`}
              style={{
                fill: "#AFAFDE",
                fontSize: "7px",
                fontFamily: "Akkurat-Mono",
                fontWeight: "bold"
              }}
            >
              {toRoman(house.number)}
            </text>
          </g>
        );
      })}
    </g>
  );
});

const Aspects: FC<{
  aspects: ExtendedNatalChartAspect[];
  planets: ExtendedNatalChartPlanet[];
  radius: number;
  planetRadius: number;
}> = memo(({ aspects, planets, radius, planetRadius }) => {
  const createAspectLine = (planet1: ExtendedNatalChartPlanet, planet2: ExtendedNatalChartPlanet) => {
    const angle1 = (-planet1.position + 180) * Math.PI / 180;
    const angle2 = (-planet2.position + 180) * Math.PI / 180;

    const planetPoint1 = {
      x: planetRadius * Math.cos(angle1),
      y: planetRadius * Math.sin(angle1)
    };

    const planetPoint2 = {
      x: planetRadius * Math.cos(angle2),
      y: planetRadius * Math.sin(angle2)
    };

    const innerPoint1 = {
      x: radius * Math.cos(angle1),
      y: radius * Math.sin(angle1)
    };

    const innerPoint2 = {
      x: radius * Math.cos(angle2),
      y: radius * Math.sin(angle2)
    };

    return `
      M ${planetPoint1.x},${planetPoint1.y}
      L ${innerPoint1.x},${innerPoint1.y}
      L ${innerPoint2.x},${innerPoint2.y}
      L ${planetPoint2.x},${planetPoint2.y}
    `;
  };

  return (
    <>
      {aspects.map((aspect, index) => {
        const planet1 = planets.find(p => p.planet === aspect.planet1);
        const planet2 = planets.find(p => p.planet === aspect.planet2);

        if (!planet1 || !planet2) return null;

        const style = {
          ...ASPECT_STYLES[aspect.type],
          stroke: aspect.color || "#AFAFDE",
          strokeWidth: 0.5,
          opacity: 0.8
        };

        return (
          <path
            key={`aspect-${index}`}
            d={createAspectLine(planet1, planet2)}
            style={{
              fill: "none",
              ...style
            }}
          />
        );
      })}
    </>
  );
});

const groupPlanetsByProximity = (planets: ExtendedNatalChartPlanet[]): Record<string, ExtendedNatalChartPlanet[]> => {
  const groups: Record<string, ExtendedNatalChartPlanet[]> = {};

  planets.forEach(planet => {
    // Округляем градус до ближайших 5 градусов для группировки близких планет
    const groupKey = `${Math.floor(planet.position / 5) * 5}`;
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(planet);
  });

  return groups;
};

const Planets: FC<{
  groupedPlanets: Record<string, ExtendedNatalChartPlanet[]>;
  planetRadius: number;
  rotationAngle: number;
}> = memo(({ groupedPlanets, planetRadius, rotationAngle }) => {
  return (
    <>
      {Object.values(groupedPlanets).map(planetsInGroup =>
        planetsInGroup.map((planet, index) => {
          const angle = (-planet.position + 180) * Math.PI / 180;
          const position = {
            x: planetRadius * Math.cos(angle),
            y: planetRadius * Math.sin(angle)
          };

          // Отступ между планетами
          const offset = planetsInGroup.length > 1
            ? (index - (planetsInGroup.length - 1) / 2) * 12
            : 0;

          return (
            <g
              key={`planet-${planet.planet}`}
              transform={`translate(${position.x},${position.y + offset})`}
            >
              <text
                textAnchor="middle"
                dominantBaseline="central"
                transform={`rotate(${-rotationAngle})`}
                style={{
                  fill: planet.color || "#AFAFDE",
                  fontSize: planetsInGroup.length > 1 ? "16px" : "20px",
                  fontFamily: "Arial Unicode MS, sans-serif",
                }}
              >
                {PLANET_SYMBOLS[planet.planet]}
              </text>
            </g>
          );
        })
      )}
    </>
  );
});

const AscendantMarker: FC<{ radius: number }> = memo(({ radius }) => {
  const radians = Math.PI;
  const baseX = radius * Math.cos(radians);
  const baseY = radius * Math.sin(radians);
  const tipX = (radius + 20) * Math.cos(radians);
  const tipY = (radius + 20) * Math.sin(radians);

  const arrowSize = 8;
  const leftX = tipX - arrowSize * Math.cos(radians - Math.PI / 5);
  const leftY = tipY - arrowSize * Math.sin(radians - Math.PI / 5);
  const rightX = tipX - arrowSize * Math.cos(radians + Math.PI / 5);
  const rightY = tipY - arrowSize * Math.sin(radians + Math.PI / 5);

  const path = `
    M ${baseX},${baseY}
    L ${tipX},${tipY}
    M ${leftX},${leftY}
    L ${tipX},${tipY}
    L ${rightX},${rightY}
  `;

  return (
    <path
      d={path}
      style={{
        fill: "none",
        stroke: "#AFAFDE",
        strokeWidth: "3",
      }}
    />
  );
});

const NatalChart: FC<NatalChartProps> = ({
  size = CHART_CONSTANTS.VIEW_BOX_SIZE,
  planets = [],
  ascendant = 0,
  houses = Array.from({ length: 12 }, (_, i) => ({
    number: i + 1,
    position: i * 30
  })),
  aspects = [],
  showHouseNumbers = true,
  showDegrees = true,
  zodiacSign,
}) => {
  const textRadius = useMemo(() =>
    ((CHART_CONSTANTS.INNER_RADIUS + CHART_CONSTANTS.OUTER_RADIUS) / 2) - 8.5,
  []
  );

  const planetRadius = useMemo(() =>
    (CHART_CONSTANTS.HOUSES_OUTER_RADIUS + CHART_CONSTANTS.HOUSES_INNER_RADIUS) / 2,
  []
  );

  const degreeRadius = useMemo(() =>
    CHART_CONSTANTS.HOUSES_INNER_RADIUS - 20,
  []
  );

  const aspectRadius = useMemo(() =>
      showDegrees
        ? CHART_CONSTANTS.HOUSES_INNER_RADIUS - 60
        : CHART_CONSTANTS.HOUSES_INNER_RADIUS - 8,
  [ showDegrees ]
  );

  const zodiacSectors = useMemo(() =>
    Array.from({ length: 12 }, (_, i) => {
      const startAngle = i * 30;
      const endAngle = (i + 1) * 30;
      return {
        path: createArc(startAngle, endAngle, CHART_CONSTANTS.INNER_RADIUS, CHART_CONSTANTS.OUTER_RADIUS),
        textPath: createTextArc(startAngle, endAngle, textRadius),
        id: `zodiac-sector-${i}`,
      };
    }),
  [ textRadius ]
  );

  const { normalizedHouses, rotationAngle: housesRotation } = useMemo(() =>
    normalizeHouses(houses),
  [ houses ]
  );

  const groupedPlanets = useMemo(() =>
    groupPlanetsByProximity(planets),
  [ planets ]
  );

  return (
    <svg
      viewBox={`-${size / 2} -${size / 2} ${size} ${size}`}
      className="w-full h-full"
    >
      <g transform={`rotate(${ascendant})`}>
        {showDegrees && <DegreeMarks radius={degreeRadius} rotationAngle={ascendant}/>}
        <ZodiacSigns sectors={zodiacSectors} zodiacs={ZODIAC_SIGNS}/>
        <Aspects
          aspects={aspects}
          planets={planets}
          radius={aspectRadius}
          planetRadius={planetRadius}
        />
        <Houses
          sectors={normalizedHouses}
          showNumbers={showHouseNumbers}
          rotationAngle={ascendant}
          housesRotation={housesRotation}
        />
        <Planets
          groupedPlanets={groupedPlanets}
          planetRadius={planetRadius}
          rotationAngle={ascendant}
        />

        {zodiacSign && (
          <image
            href={`/img/constellation/${getZodiac(zodiacSign).toLowerCase()}.svg`}
            width="180"
            height="180"
            x={-90}
            y={-90}
          />
        )}
      </g>
      <AscendantMarker radius={(CHART_CONSTANTS.HOUSES_OUTER_RADIUS + CHART_CONSTANTS.HOUSES_INNER_RADIUS) / 2}/>
    </svg>
  );
};

export default memo(NatalChart);