import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenDonePath } from "./screenDone";
import { useTelegram } from "context/telegram.provider";
import { FeatureWelcomePath } from "../routes";

export interface AccountSettings {
  language: string;
  timezone: string;
  report_weekly_time: string;
  report_weekly_wday: number;
}

export const ScreenSettingsPath = "settings";
const ScreenSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const { setMainButtonVisible, setMainButtonProps, setBackButtonVisible, setBackButtonOnClick } = useTelegram();

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate(-1))
  }, []);

  const submit = () => {
    console.log("go to done");
    return navigate(FeatureWelcomePath(ScreenDonePath));
  };

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(
      t("features.welcome.screens.screenSettings.mainButton"),
      submit,
    );
  }, [ setMainButtonProps ]);

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">
      <h1 className="text-3xl font-bold pt-8 mb-3 text-center">
        {t("features.welcome.screens.screenSettings.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenSettings.desc")}
      </div>

      <div className="flex-1">
        <div className="text-center pt-5 text-tg-theme-subtitle">
          {t("features.welcome.screens.screenSettings.info")}
        </div>
      </div>
    </div>
  );
};

export default ScreenSettings;
