import React, { ChangeEvent, useEffect, useState } from "react";
import Card from "widgets/components/card";
import { Profile } from "app/types/profile";
import DatePicker from "widgets/forms/datepicker";

interface ProfileFormProps {
  value: Profile;
  onChange?: (data: Profile) => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ value, onChange }) => {

  const [ profileValue, setProfileValue ] = useState<Profile>(value)

  useEffect(() => {
    setProfileValue(value)
  }, [ value ])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const patch = { ...profileValue, [e.target.name]: e.target.value }
    setProfileValue(patch)
    onChange?.(patch)
  }

  const handleBirthdayChange = (v: number) => {
    const patch = { ...profileValue, birthday: v }
    setProfileValue(patch)
    onChange?.(patch)
  }

  return (
    <div>

      <Card title={"First name"} className="mb-2 px-0">
        <div className="flex justify-center items-center min-h-22">
          <div className="flex-auto px-4 text-sm">
            <input type="text"
              name="first_name"
              className="w-full text-tg-theme-text bg-transparent border-transparent 
                focus:border-transparent focus:ring-0 focus:outline-none"
              value={profileValue.first_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </Card>

      <Card title={"Last name"} className="mb-2 px-0">
        <div className="flex justify-center items-center min-h-22">
          <div className="flex-auto px-4 text-sm">
            <input type="text"
              name="last_name"
              className="w-full text-tg-theme-text bg-transparent border-transparent 
                focus:border-transparent focus:ring-0 focus:outline-none"
              value={profileValue.last_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </Card>

      <Card title={"Date of birth"} className="mb-2 !p-0">
        <div className="flex justify-center items-center min-h-22">
          <div className="flex-auto text-sm">
            <DatePicker
              value={profileValue.birthday}
              onChange={handleBirthdayChange}
              label="Дата рождения"
              timeLabel="Знаешь во сколько родился?"
            />
          </div>
        </div>
      </Card>

    </div>
  )
}

export default ProfileForm
