import React, { useEffect } from "react";
import { useScreen } from "context/telegram.provider";
import { useNavigate } from "react-router-dom";
import { usePaymentCreateMutation, useSubscriptionListQuery } from "app/services/payment";
import { useWebApp } from "app/telegram";
import { handleError } from "utils";
import { PaymentType } from "../app/types/payment";

export const SubscriptionPage = () => {

  const navigate = useNavigate()
  const webApp = useWebApp()
  const { setMainButtonVisible, setBackButtonVisible, setBackButtonOnClick, setMainButtonProps } = useScreen();

  const {
    data: subscriptions,
  } = useSubscriptionListQuery();

  const [ createPayment, {
    isError: isCreatePaymentError
  } ] = usePaymentCreateMutation();

  useEffect(() => {
    setMainButtonVisible(true);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate(-1));
    setMainButtonProps("ЗАКРЫТЬ", () => navigate(-1));
  }, []);

  const handlePaymentCreate = async (id: string) => {
    try {
      const link = await createPayment({ type: PaymentType.Subscription, subscription_id: id }).unwrap()
      if (!isCreatePaymentError) {
        webApp.openInvoice(link)
      }
    } catch (err) {
      handleError(err, "Failed to create payment")
    }
  };

  return (
    <div className="w-full min-h-screen px-5 constellation flex flex-col">

      <div className="w-full glow">
        <div className="w-full h-14 mt-5 py-5 text-center content-center">
          <h1 className="text-2xl capitalize">Subscription</h1>
        </div>
      </div>

      <div className="flex-grow flex items-center justify-center">
        <div className="flex flex-col items-center space-y-5 w-full max-w-md">
          {subscriptions?.map(s => (
            <div
              key={s.id}
              className="flex justify-between rounded-xl p-4 w-full bg-card-gradient cursor-pointer"
              onClick={() => handlePaymentCreate(s.id)}
            >
              <div>{s.name}</div>
              <div className="flex items-center gap-2">
                <p>
                  {s.cost}
                </p>
                <img src="/ico/telegram-start.svg" alt="Previous" className="w-5 h-5"/>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};
