import { api } from "./api"
import { Subscription } from "../types/subscription";
import { Payment, PaymentType } from "../types/payment";

function getSubscriptionFromResponse(src: SubscriptionResponse): Subscription {
  return {
    ...src,
  }
}

function getPaymentLinkFromResponse(src: PaymentResponse): Payment {
  return {
    ...src,
  }
}

export type PaymentRequest = {
  type: PaymentType
  subscription_id?: string
  subscription_name?: string
  compatibility?: string
  natal_chart?: boolean
}

export type PaymentResponse = {
  id: string
  link: string
}

export type SubscriptionResponse = {
  id: string;
  name: string;
  cost: number;
}

export type SubscriptionListResponse = {
  items: SubscriptionResponse[]
}

export const paymentApi = api.injectEndpoints({
  endpoints: (build) => ({

    subscriptionList: build.query<Subscription[], void>({
      query: () => ({
        url: "v1/subscriptions",
        method: "GET",
      }),
      transformResponse: (response: SubscriptionListResponse): Subscription[] => {
        return response.items.map(getSubscriptionFromResponse);
      },
    }),

    paymentCreate: build.mutation<Payment, PaymentRequest>({
      query: (payment: PaymentRequest) => ({
        url: "v1/payment",
        method: "POST",
        body: payment,
      }),
      transformResponse: (response: PaymentResponse): Payment => {
        return getPaymentLinkFromResponse(response);
      },
    }),

  }),
})

export const {
  useSubscriptionListQuery,
  usePaymentCreateMutation,
} = paymentApi

