import React, { useState, useEffect } from "react";
import Toggle from "../toggle";
import { Zodiac, getZodiacPeriod } from "../../../app/types/zodiac";

export interface SignSelectProps {
  onChange: (selectedZodiacs: Zodiac[]) => void;
  value?: Zodiac[];
}

const ZodiacSelect: React.FC<SignSelectProps> = ({ onChange, value = [] }) => {
  const [ selectedZodiacs, setSelectedZodiacs ] = useState<Zodiac[]>(value);

  useEffect(() => {
    setSelectedZodiacs(value);
  }, [ value ]);

  const handleToggle = (zodiac: Zodiac, checked: boolean) => {
    const newSelectedZodiacs = checked
      ? [ ...selectedZodiacs, zodiac ]
      : selectedZodiacs.filter(s => s !== zodiac);

    setSelectedZodiacs(newSelectedZodiacs);
    onChange(newSelectedZodiacs);
  };

  return (
    <div className="space-y-0.5">
      {Object.values(Zodiac).map((zodiac) => {
        const isSelected = selectedZodiacs.includes(zodiac);
        const period = getZodiacPeriod(zodiac);
        return (
          <div key={zodiac} className="flex items-center space-x-1 pb-1">
            <img
              src={`/ico/constellation/Constellation=${zodiac.toLowerCase()}, Color=white.svg`}
              alt={zodiac}
              className="w-8 h-8"
            />
            <span className="text-xs text-gray-400 w-[6.25rem] text-left px-3">
              {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")} 
                - 
                ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
            </span>
            <span className="text-sm text-[#FCF0A1] flex-grow min-w-[10rem]">
              {zodiac.charAt(0) + zodiac.slice(1).toLowerCase()}
            </span>
            <Toggle
              checked={isSelected}
              onToggle={(checked) => handleToggle(zodiac, checked)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ZodiacSelect;
