import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../context/telegram.provider";

export const NeuroAstrologyPage = () => {

  const navigate = useNavigate()
  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  return (
    <div className="w-full min-h-screen px-5 constellation flex flex-col">

      <div className="flex flex-col items-center justify-center mt-8">
        <img className="w-12 h-12 mx-auto" src="/ico/zodicus.svg" alt=""/>
      </div>

      {/* Natal Chart Section */}
      <div className="flex flex-col items-center my-10 px-5">

        <div className="mb-5 text-center">
          <h2 className="text-2xl text-gold-gradient">Natal Chart</h2>
          <p className="text-sm mt-2 text-gray-500">Check person natal chart and analyze it</p>
        </div>

        <div className="w-[8rem] h-[8rem] text-center p-3 cursor-pointer my-5
          flex flex-col items-center justify-between bg-card-stars border rounded-2xl"
        >
          <div className="w-24 h-24 flex items-center justify-center bg-custom-radial">
            <img
              src="/ico/people.svg"
              alt=""
              className="w-10 h-10 object-contain"
            />
          </div>
          <p className="mt-2 text-sm text-[#AFAFDE]">Person</p>
        </div>

        <div
          className="flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 bg-card-gradient cursor-pointer min-w-[22rem]"
          onClick={() => navigate("/natal_chart")}
        >
          <div className="text-sm text-gold-gradient text-center w-full">
            Check person natal chart
          </div>
          <div className="flex justify-end">
            <img src="/ico/arrow-right.svg" alt=""/>
          </div>
        </div>

      </div>

      <div className="mb-1 flex justify-center">
        <img src="/ico/double-arrow-down.svg" alt=""/>
      </div>

      {/* Zodiac Compatibility Section */}
      <div className="flex flex-col items-center my-10  px-5">

        <div className="mb-5 text-center">
          <h2 className="text-2xl text-gold-gradient">Zodiac Compatibility</h2>
          <p className="text-sm mt-2 text-gray-500">Check compatibility between zodiac signs</p>¬
        </div>

        <div className="flex items-center my-5">
          <div className="w-[8rem] h-[8rem] text-center p-3 cursor-pointer
          flex flex-col items-center justify-between bg-card-stars border rounded-2xl"
          >
            <div className="w-24 h-24 flex items-center justify-center bg-custom-radial">
              <img
                src="/img/constellation/taurus.svg"
                alt=""
                className="w-16 h-16 object-contain"
              />
            </div>
            <p className="mt-2 text-sm text-[#AFAFDE]">Taurus</p>
          </div>

          <div className="text-2xl mx-4">x</div>

          <div className="w-[8rem] h-[8rem] text-center p-3 cursor-pointer
          flex flex-col items-center justify-between bg-card-stars border rounded-2xl"
          >
            <div className="w-24 h-24 flex items-center justify-center bg-custom-radial">
              <img
                src="/img/constellation/leo.svg"
                alt=""
                className="w-16 h-16 object-contain"
              />
            </div>
            <p className="mt-2 text-sm text-[#AFAFDE]">Leo</p>
          </div>

        </div>

        <div
          className="flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 bg-card-gradient cursor-pointer min-w-[22rem]"
          onClick={() => navigate("/compatibility/zodiac")}
        >
          <div className="text-sm text-gold-gradient text-center w-full">
            Check zodiac signs compatibility
          </div>
          <div className="flex justify-end">
            <img src="/ico/arrow-right.svg" alt=""/>
          </div>
        </div>

      </div>

      <div className="mb-1 flex justify-center">
        <img src="/ico/double-arrow-down.svg" alt=""/>
      </div>

      {/* Personal Compatibility Section */}
      <div className="flex flex-col items-center my-10 px-5">

        <div className="mb-5 text-center">
          <h2 className="text-2xl text-gold-gradient">Personal Compatibility</h2>
          <p className="text-sm mt-2 text-gray-500">
            Check personal compatibility between astro-profiles by natal chart, naming, and numerical data
          </p>
        </div>

        <div className="flex items-center my-5">
          <div className="w-[8rem] h-[8rem] text-center p-3 cursor-pointer
            flex flex-col items-center justify-between bg-card-stars border rounded-2xl"
          >
            <div className="w-24 h-24 flex items-center justify-center bg-custom-radial">
              <img
                src="/ico/people.svg"
                alt=""
                className="w-10 h-10 object-contain"
              />
            </div>
            <p className="mt-2 text-sm text-[#AFAFDE]">Person 1</p>
          </div>

          <div className="text-2xl mx-4 font-bold text-gold-gradient">x</div>

          <div className="w-[8rem] h-[8rem] text-center p-3 cursor-pointer
            flex flex-col items-center justify-between bg-card-stars border rounded-2xl"
          >
            <div className="w-24 h-24 flex items-center justify-center bg-custom-radial">
              <img

                src="/ico/people.svg"
                alt=""
                className="w-10 h-10 object-contain"
              />
            </div>
            <p className="mt-2 text-sm text-[#AFAFDE]">Person 2</p>
          </div>
        </div>

        <div
          className="flex justify-between items-center rounded-xl mt-5 px-5 py-3 mb-5 bg-card-gradient cursor-pointer min-w-[22rem]"
          onClick={() => navigate("/compatibility/profile")}
        >
          <div className="text-sm text-gold-gradient text-center w-full">
            Check extended profile compatibility
          </div>
          <div className="flex justify-end">
            <img src="/ico/arrow-right.svg" alt=""/>
          </div>
        </div>

      </div>

    </div>
  );
};
