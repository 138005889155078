import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Card from "widgets/components/card";
import DatePicker from "widgets/forms/datepicker";
import DropDown from "widgets/forms/dropdown";
import { Profile } from "app/types/profile";
import { Zodiac } from "app/types/zodiac";
import Toggle from "../toggle";

export type AstroProfile = {
  id?: string;
  name: string;
  birthday: number;
  location?: string;
  favorites?: boolean;
  zodiac: Zodiac;
}

export interface AstroProfileFormProps {
  profiles: Profile[]
  value?: AstroProfile
  onSelect: (p: AstroProfile) => void
}

const defaultData = {
  name: "",
  location: "",
  birthday: new Date(2000, 0, 1).getTime(),
} as AstroProfile

const AstroProfileForm: FC<AstroProfileFormProps> = ({ profiles = [], value, onSelect }) => {

  const [ dataValue, setDataValue ] = useState<AstroProfile>(value || defaultData)
  const [ selectedOption, setSelectedOption ] = useState<string | undefined>(value?.id || profiles[0]?.id);

  useEffect(() => {
    if (value?.id)
      setSelectedOption(value?.id)
    else
      setDataValue(value || defaultData)
  }, [ value ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const patch = { ...dataValue, [e.target.name]: e.target.value }
    setDataValue(patch)
  }

  const handleBirthdayChange = (v: number) => {
    const patch = { ...dataValue, birthday: v }
    setDataValue(patch)
  }

  const handleFavoritesChange = () => {
    setDataValue({ ...dataValue, favorites: !dataValue.favorites })
  }

  const options = profiles?.map(p => ({
    value: p.id,
    label: `${p.first_name || ""} ${p.last_name || ""}`.trim()
  }));

  const handleProfileChange = (selected: {value: string, label: string}) => {
    setSelectedOption(selected.value);
  };

  const renderTitle = (selected: {value: string, label: string} | undefined) => (
    <>
      {selected ? (
        <span className="text-sm flex items-center gap-2">
          <img className="w-6 h-6 mx-auto" src={"/img/constellation/gemini.svg"} alt=""/>
          {selected.label}
        </span>
      ) : "Choose..."}
    </>
  );

  const renderCustomOption = (option: {value: string, label: string}) => (
    <span className="text-sm flex items-center gap-2">
      {option.label}
    </span>
  );

  const selectProfile = () => {
    const p = profiles?.find(p => p.id === selectedOption)
    if (!p) return
    const data = {
      id: p.id,
      name: `${p.first_name || ""} ${p.last_name || ""}`.trim(),
      location: `${p.city || ""} ${p.country || ""}`.trim(),
      birthday: p.birthday
    } as AstroProfile
    onSelect(data)
  }

  const isValid = () => !!dataValue.name && !!dataValue.location && !!dataValue.birthday

  const submitForm = () => {
    if (!isValid()) return
    onSelect(dataValue)
  }

  return (
    <div>
      <div className="mb-6">

        <p className="text-center text-sm mb-4">Выбрать существующий профиль</p>

        <div className="mb-5">
          <DropDown
            options={options}
            value={selectedOption}
            onChange={handleProfileChange}
            renderOption={renderCustomOption}
            renderTitle={renderTitle}
          />
        </div>

        <div className="w-full flex justify-center">
          <button className="px-6 py-2 rounded-xl bg-card-gradient text-tg-theme-section-header text-sm"
            onClick={selectProfile}>
            Выбрать
          </button>
        </div>
      </div>

      <div className="w-full flex justify-center my-10">
        <hr className="w-3/5"/>
      </div>

      <div className="pb-10">

        <p className="text-center text-sm mb-4">Заполните новый профиль</p>

        <Card title="Введите имя и фамилию" className="mb-2 px-0">
          <div className="flex justify-center items-center min-h-22">
            <div className="flex-auto px-4 text-sm">
              <input type="text"
                name="name"
                className="w-full text-tg-theme-text bg-transparent border-transparent focus:border-transparent
                  focus:ring-0 focus:outline-none"
                placeholder="Имя и фамилия"
                onChange={handleChange}
                value={dataValue?.name}
              />
            </div>
          </div>
        </Card>

        <Card title="Место рождения" className="mb-6 px-0">
          <div className="flex justify-center items-center min-h-22">
            <div className="flex-auto px-4 text-sm">
              <input type="text"
                name="location"
                className="w-full text-tg-theme-text bg-transparent border-transparent focus:border-transparent
                  focus:ring-0 focus:outline-none"
                placeholder="Начните вводить место рождения"
                onChange={handleChange}
                value={dataValue?.location}
              />
            </div>
          </div>
        </Card>

        <div className="mb-5">
          <DatePicker
            value={dataValue?.birthday}
            onChange={handleBirthdayChange}
            label="Дата рождения"
            timeLabel="Знаешь во сколько родился?"
            alwaysOpen={false}
            isTimeEditable={false}
          />
        </div>

        <div className="mb-10 flex items-center">
          <Toggle checked={dataValue?.favorites} onToggle={handleFavoritesChange}/>
          <div className="ms-5 text-tg-theme-section-header">
            Добавить в избранное
          </div>
        </div>

        <div className="w-full flex justify-center">
          <button className={`
            px-6 py-2 rounded-xl bg-card-gradient text-sm
            ${isValid() ? "text-tg-theme-section-header" : "opacity-30"}
          `}
          disabled={!isValid()}
          onClick={submitForm}>
            Выбрать
          </button>
        </div>
      </div>

    </div>
  );
};


export default AstroProfileForm;