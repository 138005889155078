import { FC, useState } from "react";
import { useEfficientListQuery } from "app/services/efficiency";
import Calendar, { DateOption } from "widgets/forms/calendar";
import moment from "moment/moment";

interface EfficiencyCalendarFeatureProps {
  profile_id?: string
}

interface EfficiencyItem {
  date: number;
  percent: number;
}

export const EfficiencyCalendarFeature: FC<EfficiencyCalendarFeatureProps> = ({ profile_id }) => {

  const categories = [
    { id: "work", label: "Work" },
    { id: "beauty", label: "Beauty" },
    { id: "love", label: "Love" },
    { id: "health", label: "Health" },
  ];

  const [ selectedGroup, setSelectedGroup ] = useState<string>(categories[0].id);

  // format: YYYY-MM-dd
  const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    .toISOString()
    .split("T")[0];
  const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    .toISOString()
    .split("T")[0];
  
  const {
    data: efficiencyList,
    isLoading,
    isError,
  } = useEfficientListQuery({
    profile_id: profile_id,
    range_start: startOfMonth,
    range_end: endOfMonth,
    group: selectedGroup
  },
  {
    refetchOnMountOrArgChange: true,
  }
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div/>;

  const dates: DateOption[] = (efficiencyList || [])
    .filter(e => e.group === selectedGroup)
    .map((item: EfficiencyItem) => {
      const date = moment(item.date).format("ll")
      let status = ""
      if (item.percent <= 25) status = "danger"
      if (item.percent > 25 && item.percent < 75) status = "warning"
      if (item.percent >= 75) status = "success"
      return { date: date, status: status }
    })

  return (
    <>
      <div className="flex gap-4 mb-5">
        {categories.map(category => (
          <div
            key={category.id}
            onClick={() => setSelectedGroup(category.id)}
            className={`w-full text-center font-bold text-black rounded-3xl p-1 cursor-pointer ${
              selectedGroup === category.id ? "bg-[#FCF0A1]" : "bg-[#d4c55f]"
            }`}
          >
            {category.label}
          </div>
        ))}
      </div>

      <Calendar current={moment().unix()}
        disableChangeMonth={true}
        selectedDay={false}
        dates={dates}/>
    </>
  );
};

export default EfficiencyCalendarFeature;
