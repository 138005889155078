import React from "react";

interface MoonPhaseProps {
  position: number;
  starNumbers?: number;
}

const PlanetInfo: React.FC<MoonPhaseProps> = ({ position, starNumbers }) => {
  return (
    <div className="flex gap-4">
      <div className="flex items-center gap-1">
        <img src="/ico/angle.svg" alt=""/>
        <span className="text-xs">{position}°</span>
      </div>

      {!!starNumbers && (
        <div className="flex items-center gap-1">
          <img src="/ico/star-amount.svg" alt=""/>
          <span className="text-xs">{starNumbers}</span>
        </div>
      )}
    </div>
  );
};

export default PlanetInfo;
