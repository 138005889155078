import React from "react";
import { useAccountReferralStatsFetchQuery } from "app/services/account";
import { handleError } from "utils";
import { useWebApp } from "../../../app/telegram";
import { AccountReferralStats } from "../../../app/types/account";

const ReferralSystem: React.FC = () => {

  const webApp = useWebApp();
  const {
    data,
    isLoading
  } = useAccountReferralStatsFetchQuery();

  if (isLoading) return <div/>
  if (!data) return <div/>

  const shareLink = async (data: AccountReferralStats): Promise<void> => {
    try {
      if (data?.referral_id) {
        if (webApp.isVersionAtLeast("7.11")) {
          webApp.shareMessage(data.referral_id, (success: boolean) => {
            if (!success) {
              fallbackCopyToClipboard();
            }
          });
        } else {
          if (webApp?.switchInlineQuery && data?.link) {
            webApp.switchInlineQuery(data?.link, [ "users", "groups" ]);
          } else {
            webApp.showAlert("Sharing is not supported in your Telegram version. Please update to the latest version.");
            fallbackCopyToClipboard();
          }
        }
      } else {
        fallbackCopyToClipboard();
      }
    } catch (err) {
      handleError(err, "Failed to share link");
    }
  };

  const fallbackCopyToClipboard = () => {
    navigator.clipboard.writeText(data?.link!)
      .then(() => console.log({ message: "Ссылка скопирована в буфер обмена!" }))
      .catch((err) => handleError(err, "Failed to copy to clipboard"));
  };

  return (
    <div className="flex items-center rounded-xl py-4 bg-card-gradient mb-5 cursor-pointer px-5"
      onClick={() => shareLink(data)}>
      <div className="w-1/5 flex text-center items-center">
        <div>
          <img src="/ico/people-gold.svg" alt=""/>
        </div>
        <div className="ms-2 font-bold text-tg-theme-section-header w-6 text-center">
          {data?.total ?? 0}
        </div>
      </div>
      <div className="flex-1 text-center text-tg-theme-section-header">
        Пригласить друзей
      </div>
      <div className="w-1/5 text-right font-bold text-tg-theme-section-header">
        +
      </div>
    </div>
  );
};

export default ReferralSystem;