import { api } from "./api"
import { Zodiac, ZodiacElement } from "../types/zodiac";
import {
  DailyCommon,
  DailyPersonal,
  DailyZodiac,
  LunarGrowthStage, ProfileCompatibilities,
  Recommendations,
  SunInfluence,
  ZodiacPlanet,
  ZodiacStar,
  ZodiacSystem,
} from "../types/astrology";

function getDailyCommonFromResponse(src: DailyCommonResponse): DailyCommon {
  return {
    ...src,
  }
}

function getDailyPersonalFromResponse(src: DailyPersonalResponse): DailyPersonal {
  return {
    ...src,
  }
}

function getDailyZodiacFromResponse(src: DailyZodiacResponse): DailyZodiac {
  return {
    ...src,
  }
}

export type DailyCommonResponse = {
  sun_influence: SunInfluence;
  zodiac_planet: ZodiacPlanet;
  lunar_growth_stage: LunarGrowthStage;
  zodiac_element: ZodiacElement;
}

export interface DailyPersonalResponse {
  profile_id: string;
  first_name: string;
  last_name: string;
  status: string;
  zodiac: Zodiac;
  date: number;
  start_period: number;
  end_period: number;
  zodiac_planet: ZodiacPlanet;
  happy_numbers: number[];
  horoscope: string;
  zodiacs: ZodiacSystem[];
  recommendations?: Recommendations;
  compatibilities?: ProfileCompatibilities
}

export type DailyZodiacResponse = {
  zodiac: Zodiac;
  date: number;
  status: string;
  start_period: number;
  end_period: number;
  zodiac_planet: ZodiacPlanet;
  horoscope: string;
  description: string;
  zodiac_star: ZodiacStar;
  tropical_zodiac: ZodiacSystem;
  sidereal_zodiac: ZodiacSystem;
}

export const astrologyApi = api.injectEndpoints({
  endpoints: (build) => ({

    dailyCommonFetch: build.query<DailyCommonResponse, void>({
      query: () => ({
        url: "v1/astro/daily",
        method: "GET",
      }),
      transformResponse: (response: DailyCommonResponse): DailyCommon => {
        return getDailyCommonFromResponse(response);
      },
    }),

    dailyPersonalFetch: build.query<DailyPersonalResponse, string>({
      query: (profile_id) => ({
        url: `v1/astro/daily/profile/${profile_id}`,
        method: "GET",
      }),
      transformResponse: (response: DailyPersonalResponse): DailyPersonal => {
        return getDailyPersonalFromResponse(response);
      },
    }),

    dailyZodiacFetch: build.query<DailyZodiacResponse, string>({
      query: (zodiac) => ({
        url: `v1/astro/daily/zodiac/${zodiac}`,
        method: "GET",
      }),
      transformResponse: (response: DailyZodiacResponse): DailyZodiac => {
        return getDailyZodiacFromResponse(response);
      },
    }),

  }),
})

export const {
  useDailyCommonFetchQuery,
  useDailyPersonalFetchQuery,
  useDailyZodiacFetchQuery
} = astrologyApi

export const {
  endpoints: {
    dailyCommonFetch,
    dailyPersonalFetch,
    dailyZodiacFetch,
  },
} = astrologyApi

