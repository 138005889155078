import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getZodiac, getZodiacPeriod, Zodiac } from "app/types/zodiac";
import { useNatalChartAnalyseListQuery, useNatalChartProfileFetchQuery } from "app/services/natalchart";
import { useDailyPersonalFetchQuery } from "app/services/astrology";
import NatalChart from "widgets/components/natalchart";
import { NatalChartAspect, NatalChartPlanet } from "app/types/natal_chart";
import { AspectType, House, UniverseEntity } from "app/types/astrology";
import Card from "widgets/components/card";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../context/telegram.provider";

const NatalChartInfoPage = () => {

  const { t } = useTranslation()
  const { setBackButtonVisible, setMainButtonVisible, setBackButtonOnClick } = useTelegram();
  const { profile_id } = useParams();
  const navigate = useNavigate()

  const {
    data: natalChartInfo,
    isLoading: isNatalChartLoading,
    error,
  } = useNatalChartProfileFetchQuery(profile_id!);

  const {
    data: natalChartAnalysesList,
  } = useNatalChartAnalyseListQuery();

  const {
    data: profileInfo,
    isLoading: isProfileLoading,
  } = useDailyPersonalFetchQuery(profile_id!);

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate(-1));
  }, []);

  if (isProfileLoading || isNatalChartLoading) return <div/>;
  if (!profile_id || !profileInfo) {
    navigate(-1)
    return;
  }
  if (!natalChartInfo && (error as any)?.status === 404) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="mb-5">
          <img src="/logo.svg" className="w-48 h-48" alt=""/>
        </div>
        <p>Мы собираем сведения</p>
        <p>нужно немного подождать ;)</p>
      </div>
    )
  }
  if (!natalChartInfo) {
    navigate(-1)
    return;
  }

  const period = getZodiacPeriod(profileInfo.zodiac);
  const grouped = groupNatalChartData(natalChartInfo.planets || [], natalChartInfo.aspects || [])

  return (
    <div>
      <div className="flex flex-col min-h-screen px-5 constellation">

        <div className="mb-5 flex flex-col items-center justify-center mt-8">
          <img className="w-12 h-12 mx-auto"
            src={`/ico/constellation/Constellation=${getZodiac(profileInfo.zodiac)}, Color=white.svg`} alt=""/>
          <h1 className="text-3xl font-bold py-4 text-center text-gold-gradient capitalize">
            {`${profileInfo.first_name || ""} ${profileInfo.last_name || ""}`.trim()}
          </h1>
          <div className="text-center">
            <p className="text-sm text-tg-theme-subtitle">
              {profileInfo.zodiac.charAt(0).toUpperCase() + profileInfo.zodiac.slice(1).toLowerCase()}
            </p>
            <p className="text-sm text-tg-theme-subtitle">
              {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")} 
              - ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
            </p>
          </div>
        </div>

        <div>
          <div className="mb-5">
            <NatalChart
              ascendant={110}
              planets={natalChartInfo.planets}
              houses={natalChartInfo.houses}
              aspects={natalChartInfo.aspects}
              zodiacSign={profileInfo?.zodiac}
              showHouseNumbers={true}
              showDegrees={true}
            />
          </div>

          <div className="mx-5 my-10  grid text-center">
            <div className="grid gap-1">
              <div className="mb-2 text-sm text-[#AFAFDE]">
                {t("features.natalChart.info.analiseTitle")}
              </div>

              {natalChartAnalysesList?.map((c, index) => (
                <Card key={index} onClick={() => navigate(`/profile/${profile_id}/natal_chart/analyse/${c.category}`)}>
                  <div className="flex items-center justify-between">
                    <div className="text-sm text-gold-gradient">
                      {t(`features.natalChart.info.analysesList.analyse-${c.category}`)}
                    </div>
                    <div>
                      <img src={"/ico/arrow-right.svg"} alt=""/>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>

          <div className="px-2 my-10 space-y-10">
            {grouped?.map((item, index) => {
              return (
                <div key={index} className="flex gap-2">
                  <div className="mt-[1.2rem]">
                    <img className="w-8" src={`/ico/planets/Rulership=${item.planet1?.toLowerCase()}.svg`} alt=""/>
                  </div>
                  <div>
                    <div className="mb-5">
                      <div className="text-sm text-gold-gradient">
                        {item.planet1}
                      </div>
                      <div className="text-sm text-gold-gradient">
                        {item.zodiac}, {formatPosition(item.position!)}
                      </div>
                      <div className="text-sm text-gold-gradient">
                        {item.house?.replace(/_/g, " ")}
                      </div>
                    </div>
                    <div className="space-y-5">
                      <div className="text-sm border-l-2 border-green-500">
                        <p className="ps-2">
                          {item.positive_effect}
                        </p>
                      </div>
                      <div className="text-sm border-l-2 border-red-500">
                        <p className="ps-2">
                          {item.negative_effect}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

        </div>
      </div>
    </div>
  )
}

type Grouped = {
  type?: AspectType;
  planet1?: UniverseEntity;
  planet2?: UniverseEntity;
  zodiac?: Zodiac;
  house?: House;
  position?: number;
  positive_effect?: string;
  negative_effect?: string;
}

const groupNatalChartData = (
  planets: NatalChartPlanet[],
  aspects: NatalChartAspect[]
): Grouped[] => {
  const grouped: {[key: string]: any} = {};

  aspects.forEach((aspect) => {
    const key = aspect.planet1.toString();
    grouped[key] = { ...aspect };
  });

  planets.forEach((planet) => {
    const key = planet.planet.toString();
    if (grouped[key]) {
      grouped[planet.planet.toString()] = {
        ...grouped[key],
        zodiac: planet.zodiac,
        house: planet.house,
        position: planet.position
      };
    }
  });

  return Object.values(grouped);
};

const formatPosition = (position: number): string => {
  const degrees = Math.floor(position);
  const minutesDecimal = (position - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);
  return `${degrees}°${minutes}'${seconds}"`;
};

export default NatalChartInfoPage