import { createContext, ReactNode, useCallback, useContext, useEffect, useState, } from "react";

interface ScreenContextType {
  screen: string | undefined;
  setScreen: (screen: string | undefined) => void;

  isVisibleMainButton: boolean;
  isMock: () => boolean;

  setMainButtonProps: (text: string, onClick: () => void) => void;
  setMainButtonVisible: (visible: boolean) => void;
  setMainButtonDisabled: (disabled: boolean) => void;

  getMainButtonText: () => string;
  getMainButtonClickHandler: () => (() => void) | undefined;
  getMainButtonVisible: () => boolean;

  isVisibleBackButton: boolean;
  setBackButtonVisible: (visible: boolean) => void;
  setBackButtonOnClick: (onClick: () => void) => void;
}

const TelegramContext = createContext<ScreenContextType | undefined>(undefined);
const ButtonPlaceholder = {
  text: "",
  isVisible: false,
  enable: () => {},
  disable: () => {},
  onClick: () => {},
  offClick: () => {},
};

export interface ScreenProviderProps {
  children: ReactNode;
}

export const useScreen = () => {
  const context = useContext(TelegramContext);
  if (!context) {
    throw new Error("useScreen must be used within a ScreenProvider");
  }
  return context;
};

export const TelegramProvider = ({ children }: ScreenProviderProps) => {
  const Telegram =
    window.Telegram && !(process.env.REACT_APP_ENV === "mocks")
      ? window.Telegram.WebApp
      : {
        MainButton: ButtonPlaceholder,
        BackButton: ButtonPlaceholder,
      };

  const [ isReady, setIsReady ] = useState<boolean>(false);
  const [ screen, setScreen ] = useState<string | undefined>(undefined);
  const [ mainButtonClickHandler, setMainButtonClickHandler ] =
    useState<() => void>();
  const [ backButtonClickHandler, setBackButtonClickHandler ] =
    useState<() => void>();
  const [ mainButtonText, setMainButtonText ] = useState<string>("");
  const [ mainButtonVisible, setMainButtonVisibleValue ] =
    useState<boolean>(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    console.log("mainButtonText", mainButtonText);
    if (mainButtonText) {
      Telegram.MainButton.text = mainButtonText;
    }
  }, [ mainButtonText ]);

  useEffect(() => {
    if (mainButtonClickHandler) {
      Telegram.MainButton.onClick(mainButtonClickHandler);
      return () => {
        Telegram.MainButton.offClick(mainButtonClickHandler);
      };
    }
  }, [ mainButtonClickHandler ]);

  useEffect(() => {
    Telegram.MainButton.isVisible = mainButtonVisible;
    mainButtonVisible
      ? Telegram.MainButton.enable()
      : Telegram.MainButton.disable();
  }, [ mainButtonVisible ]);

  useEffect(() => {
    console.log("backButtonClickHandler", backButtonClickHandler);
    if (backButtonClickHandler) {
      Telegram.BackButton.onClick(backButtonClickHandler);
      return () => {
        Telegram.BackButton.offClick(backButtonClickHandler);
      };
    }
  }, [ backButtonClickHandler ]);

  const setMainButtonProps = useCallback(
    (text: string, onClick: () => void) => {
      setMainButtonClickHandler(() => onClick);
      setMainButtonText(text);
      Telegram.MainButton.text = text.toUpperCase();
      Telegram.MainButton.isVisible = !!(text && onClick);
    },
    [],
  );

  const setMainButtonVisible = useCallback((visible: boolean) => {
    setMainButtonVisibleValue(visible);
  }, []);

  const setMainButtonDisabled = useCallback((disabled: boolean) => {
    if (disabled) {
      Telegram.MainButton.disable();
      return;
    }
    Telegram.MainButton.enable();
  }, []);

  const getMainButtonClickHandler = (): (() => void) | undefined => {
    return mainButtonClickHandler;
  };
  const getMainButtonVisible = () => {
    return mainButtonVisible;
  };

  const getMainButtonText = () => {
    return mainButtonText;
  };

  const isMock = () => {
    return process.env.REACT_APP_ENV === "mocks";
  };

  const setBackButtonVisible = useCallback((visible: boolean) => {
    Telegram.BackButton.isVisible = visible;
  }, []);

  const setBackButtonOnClick = useCallback((onClick: () => void) => {
    setBackButtonClickHandler(() => onClick);
  }, []);

  if (!isReady) return <div/>;

  return (
    <TelegramContext.Provider
      value={{
        screen,
        setScreen,
        isMock,
        isVisibleMainButton: Telegram.MainButton.isVisible,
        setMainButtonVisible,
        setMainButtonDisabled,
        setMainButtonProps,

        getMainButtonClickHandler,
        getMainButtonText,
        getMainButtonVisible,

        isVisibleBackButton: Telegram.BackButton.isVisible,
        setBackButtonVisible,
        setBackButtonOnClick,
      }}
    >
      {children}
    </TelegramContext.Provider>
  );
};

export interface ScreenProps {
  screens?: { [key: string]: ReactNode };
  children: ReactNode;
  className?: string;
  disableBack?: boolean;
}

export const Screen = ({ children, screens = {}, disableBack, className }: ScreenProps) => {
  const { screen, setScreen, setBackButtonVisible, setBackButtonOnClick } = useScreen();

  useEffect(() => {
    if (disableBack || !screen) return;
    setBackButtonVisible(true);
    setBackButtonOnClick(() => setScreen(undefined));
  }, [ screen ]);

  useEffect(() => {
    // Отключаем скролл при рендере screen и
    // включаем скролл, если screen не отображается
    document.body.style.overflow = !!screen ? "hidden" : "";

    return () => {
      // Включаем скролл при размонтировании компонента
      document.body.style.overflow = "";
    };
  }, [ screen ]);

  return (
    <>
      <div className="h-screen overflow-auto">{children}</div>

      {(screens && screen && screens[screen]) && (
        <div className="fixed inset-0 z-50 flex flex-col bg-tg-theme-secondary">
          <div className="flex-1 max-h-screen overflow-auto">
            <div className={className}>
              {screens[screen]}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const useTelegram = () => {
  const context = useContext(TelegramContext);
  if (!context) {
    throw new Error("useScreen must be used within a TelegramProvider");
  }
  return context;
};

