export enum Zodiac {
  ARIES = "ARIES",
  TAURUS = "TAURUS",
  GEMINI = "GEMINI",
  CANCER = "CANCER",
  LEO = "LEO",
  VIRGO = "VIRGO",
  LIBRA = "LIBRA",
  SCORPIO = "SCORPIO",
  SAGITTARIUS = "SAGITTARIUS",
  CAPRICORN = "CAPRICORN",
  AQUARIUS = "AQUARIUS",
  PISCES = "PISCES",
}

export type ZodiacType = keyof typeof Zodiac;

export const getZodiac = (zodiac: Zodiac): string => {
  switch (zodiac) {
    case Zodiac.ARIES:
      return "aries";
    case Zodiac.TAURUS:
      return "taurus";
    case Zodiac.GEMINI:
      return "gemini";
    case Zodiac.CANCER:
      return "cancer";
    case Zodiac.LEO:
      return "leo";
    case Zodiac.VIRGO:
      return "virgo";
    case Zodiac.LIBRA:
      return "libra";
    case Zodiac.SCORPIO:
      return "scorpio";
    case Zodiac.SAGITTARIUS:
      return "sagittarius";
    case Zodiac.CAPRICORN:
      return "capricorn";
    case Zodiac.AQUARIUS:
      return "aquarius";
    case Zodiac.PISCES:
      return "pisces";
    default:
      return "";
  }
};

export function stringToZodiac(str: string): Zodiac | undefined {
  const upperCaseStr = str.toUpperCase();
  return Object.values(Zodiac).find(
    (zodiac) => zodiac === upperCaseStr
  ) as Zodiac | undefined;
}

export function getZodiacByDate(date: Date): Zodiac {
  const month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
  const day = date.getDate();

  if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
    return Zodiac.AQUARIUS;
  } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
    return Zodiac.PISCES;
  } else if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
    return Zodiac.ARIES;
  } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
    return Zodiac.TAURUS;
  } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
    return Zodiac.GEMINI;
  } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
    return Zodiac.CANCER;
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return Zodiac.LEO;
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
    return Zodiac.VIRGO;
  } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
    return Zodiac.LIBRA;
  } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
    return Zodiac.SCORPIO;
  } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
    return Zodiac.SAGITTARIUS;
  } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
    return Zodiac.CAPRICORN;
  }
  return Zodiac.CAPRICORN
}

export interface ZodiacPeriod {
  start: {month: number; day: number};
  end: {month: number; day: number};
}

export function getZodiacPeriod(zodiac: Zodiac): ZodiacPeriod {
  switch (zodiac) {
    case Zodiac.ARIES:
      return { start: { month: 3, day: 21 }, end: { month: 4, day: 19 } };
    case Zodiac.TAURUS:
      return { start: { month: 4, day: 20 }, end: { month: 5, day: 20 } };
    case Zodiac.GEMINI:
      return { start: { month: 5, day: 21 }, end: { month: 6, day: 20 } };
    case Zodiac.CANCER:
      return { start: { month: 6, day: 21 }, end: { month: 7, day: 22 } };
    case Zodiac.LEO:
      return { start: { month: 7, day: 23 }, end: { month: 8, day: 22 } };
    case Zodiac.VIRGO:
      return { start: { month: 8, day: 23 }, end: { month: 9, day: 22 } };
    case Zodiac.LIBRA:
      return { start: { month: 9, day: 23 }, end: { month: 10, day: 22 } };
    case Zodiac.SCORPIO:
      return { start: { month: 10, day: 23 }, end: { month: 11, day: 21 } };
    case Zodiac.SAGITTARIUS:
      return { start: { month: 11, day: 22 }, end: { month: 12, day: 21 } };
    case Zodiac.CAPRICORN:
      return { start: { month: 12, day: 22 }, end: { month: 1, day: 19 } };
    case Zodiac.AQUARIUS:
      return { start: { month: 1, day: 20 }, end: { month: 2, day: 18 } };
    case Zodiac.PISCES:
      return { start: { month: 2, day: 19 }, end: { month: 3, day: 20 } };
  }
}

export type Element = "air" | "water" | "fire" | "earth"

export const getElement = (zodiac: Zodiac): Element | undefined => {
  switch (zodiac) {
    case Zodiac.ARIES:
    case Zodiac.LEO:
    case Zodiac.SAGITTARIUS:
      return "fire";

    case Zodiac.TAURUS:
    case Zodiac.VIRGO:
    case Zodiac.CAPRICORN:
      return "earth";

    case Zodiac.GEMINI:
    case Zodiac.LIBRA:
    case Zodiac.AQUARIUS:
      return "air";

    case Zodiac.CANCER:
    case Zodiac.SCORPIO:
    case Zodiac.PISCES:
      return "water";

    default:
      return undefined;
  }
};

export interface Star {
  name: string;
  brightness: number;
}

const zodiacStars: Record<Zodiac, Star[]> = {
  [Zodiac.ARIES]: [
    { name: "Hamal", brightness: 2.0 },
    { name: "Sheratan", brightness: 4.6 },
    { name: "Mesarthim", brightness: 4.5 },
  ],
  [Zodiac.TAURUS]: [
    { name: "Aldebaran", brightness: 0.85 },
    { name: "Elnath", brightness: 1.65 },
    { name: "Ain", brightness: 4.4 },
  ],
  [Zodiac.GEMINI]: [
    { name: "Castor", brightness: 1.58 },
    { name: "Pollux", brightness: 1.14 },
    { name: "Alhena", brightness: 3.5 },
  ],
  [Zodiac.CANCER]: [
    { name: "Acubens", brightness: 4.3 },
    { name: "Tarf", brightness: 3.7 },
    { name: "Asellus Australis", brightness: 3.9 },
  ],
  [Zodiac.LEO]: [
    { name: "Regulus", brightness: 1.35 },
    { name: "Denebola", brightness: 2.14 },
    { name: "Zosma", brightness: 3.6 },
  ],
  [Zodiac.VIRGO]: [
    { name: "Spica", brightness: 0.98 },
    { name: "Porrima", brightness: 3.66 },
    { name: "Vindemiatrix", brightness: 3.5 },
  ],
  [Zodiac.LIBRA]: [
    { name: "Zubenelgenubi", brightness: 2.74 },
    { name: "Zubeneschamali", brightness: 2.75 },
    { name: "Brachium", brightness: 4.5 },
  ],
  [Zodiac.SCORPIO]: [
    { name: "Antares", brightness: 1.06 },
    { name: "Shaula", brightness: 1.62 },
    { name: "Lesath", brightness: 2.7 },
  ],
  [Zodiac.SAGITTARIUS]: [
    { name: "Kaus Australis", brightness: 1.79 },
    { name: "Ruchbah", brightness: 3.6 },
    { name: "Atria", brightness: 2.24 },
  ],
  [Zodiac.CAPRICORN]: [
    { name: "Deneb Algedi", brightness: 2.87 },
    { name: "Tsi", brightness: 4.0 },
    { name: "Algedi", brightness: 2.53 },
  ],
  [Zodiac.AQUARIUS]: [
    { name: "Sadalmelik", brightness: 2.95 },
    { name: "Sadalsuud", brightness: 2.87 },
    { name: "Skat", brightness: 3.3 },
  ],
  [Zodiac.PISCES]: [
    { name: "Fum al Samakah", brightness: 3.9 },
    { name: "Markab", brightness: 2.5 },
    { name: "Alrischa", brightness: 4.0 },
  ],
};

export const getBrightestStarByZodiac = (zodiac: Zodiac): Star | null => {
  const stars = zodiacStars[zodiac];
  if (!stars || stars.length === 0) {
    return null;
  }
  return stars.reduce((prev, current) =>
    (prev.brightness < current.brightness ? prev : current)
  );
};

export enum Planet {
  MARS = "MARS",
  VENUS = "VENUS",
  MERCURY = "MERCURY",
  MOON = "MOON",
  SUN = "SUN",
  PLUTO = "PLUTO",
  JUPITER = "JUPITER",
  SATURN = "SATURN",
  URANUS = "URANUS",
}

export const zodiacPlanetMap: Record<Zodiac, Planet> = {
  [Zodiac.ARIES]: Planet.MARS,
  [Zodiac.TAURUS]: Planet.VENUS,
  [Zodiac.GEMINI]: Planet.MERCURY,
  [Zodiac.CANCER]: Planet.MOON,
  [Zodiac.LEO]: Planet.SUN,
  [Zodiac.VIRGO]: Planet.MERCURY,
  [Zodiac.LIBRA]: Planet.VENUS,
  [Zodiac.SCORPIO]: Planet.PLUTO,
  [Zodiac.SAGITTARIUS]: Planet.JUPITER,
  [Zodiac.CAPRICORN]: Planet.SATURN,
  [Zodiac.AQUARIUS]: Planet.URANUS,
  [Zodiac.PISCES]: Planet.JUPITER,
};

export const getPlanetForZodiac = (zodiac: Zodiac): Planet | undefined => {
  return zodiacPlanetMap[zodiac];
};

export enum ZodiacElement {
  FIRE = "FIRE",
  EARTH = "EARTH",
  AIR = "AIR",
  WATER = "WATER",
}

export const getElementString = (element: ZodiacElement): Element | undefined => {
  switch (element) {
    case ZodiacElement.FIRE:
      return "fire"
    case ZodiacElement.EARTH:
      return "earth"
    case ZodiacElement.AIR:
      return "air"
    case ZodiacElement.WATER:
      return "water"
    default:
      return undefined
  }
};

