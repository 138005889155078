import React from "react";
import { MoonPhase } from "app/types/astrology";

interface MoonPhaseInfoProps {
  phase: MoonPhase;
  size?: number;
}

const MoonPhaseInfo: React.FC<MoonPhaseInfoProps> = ({ phase, size = 48 }) => {
  const clipPathMap: Record<MoonPhase, string> = {
    [MoonPhase.NEW_MOON]: "inset(0 100% 0 0)", // Новолуние — Луна не видна на небе.
    [MoonPhase.WAXING_CRESCENT]: "circle(75% at 0 50%)", // Молодая Луна (Растущий месяц) — узкий серп, светлая справа.
    [MoonPhase.FIRST_QUARTER]: "inset(0 50% 0 0)", // Первая четверть — освещена половина Луны слева.
    [MoonPhase.WAXING_GIBBOUS]: "circle(50% at 75% 50%)", // Растущая Луна — большая часть Луны освещена справа.
    [MoonPhase.FULL_MOON]: "", // Полнолуние — освещена вся Луна целиком.
    [MoonPhase.WANING_GIBBOUS]: "circle(50% at 25% 50%)", // Убывающая Луна — большая часть Луны, светлая слева.
    [MoonPhase.THIRD_QUARTER]: "inset(0 50% 0 0)", // Последняя четверть — освещена другая половина Луны.
    [MoonPhase.WANING_CRESCENT]: "circle(75% at 100% 50%)", // Старая Луна (Убывающий месяц) — узкий серп, светлая слева.
  };

  const phaseTypes = {
    growing: [
      MoonPhase.WAXING_CRESCENT,
      MoonPhase.FIRST_QUARTER,
      MoonPhase.WAXING_GIBBOUS,
    ],
    waning: [
      MoonPhase.WANING_GIBBOUS,
      MoonPhase.THIRD_QUARTER,
      MoonPhase.WANING_CRESCENT,
    ],
  };

  const isGrowing = phaseTypes.growing.includes(phase);
  const isWaning = phaseTypes.waning.includes(phase);

  const clipPath = clipPathMap[phase];

  const whitePhases = new Set([
    MoonPhase.WAXING_CRESCENT,
    MoonPhase.FIRST_QUARTER,
    MoonPhase.WANING_CRESCENT,
  ]);
  const bgColor = whitePhases.has(phase) ? "bg-white" : "bg-gray-800";
  const innerBgColor = whitePhases.has(phase) ? "bg-gray-800" : "bg-white";

  return (
    <div className="flex items-center">
      {(isGrowing || isWaning) && (
        <div className="mr-2">
          {isGrowing && <img src="/ico/arrow-up.svg" alt="Growing Moon"/>}
          {isWaning && <img src="/ico/arrow-down.svg" alt="Waning Moon"/>}
        </div>
      )}
      <div
        className={`relative overflow-hidden ${bgColor}`}
        style={{ width: `${size}px`, height: `${size}px`, borderRadius: "50%" }}
      >
        <div
          className={`absolute top-0 left-0 rounded-full ${innerBgColor}`}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            clipPath,
          }}
        />
      </div>
    </div>
  );
};

export default MoonPhaseInfo;
