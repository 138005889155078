import * as Sentry from "@sentry/react";
import moment from "moment/moment";

export function handleError(err: unknown, title: string) {
  if (typeof err === "string") {
    // Handle string errors
    console.error(`${title}:`, err);
    Sentry.captureMessage(`${title}: ${err}`);
  } else if (err instanceof Error) {
    // Handle Error objects
    console.error(`${title}:`, err.message);
    console.error("Stack trace:", err.stack);
    Sentry.captureMessage(`${title}: ${err.message}`);
    Sentry.captureException(err);
  } else {
    // Handle any other types of errors
    console.error(`${title}:`, JSON.stringify(err, null, 2));
    Sentry.captureMessage(`${title}: Unknown error type`);
    Sentry.captureException(err);
  }
}


export const formatDate = (date: Date): string => {
  return moment(date).locale("ru").format("DD.MM.YYYY");
};

export const formatDateTime = (date: Date): string => {
  return moment(date).utc().format("DD.MM.YYYY HH:mm");
};

export const formatPosition = (position: number): string => {
  const degrees = Math.floor(position);
  const minutesDecimal = (position - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);
  return `${degrees}°${minutes}'${seconds}"`;
};