import React from "react";

export type Currency = {
  type: 'stars' | 'fens';
  cost: string;
  name: string;
}

type PaymentFormProps = {
  onSubmit: (type: 'stars' | 'fens') => void;
  description?: string;
  buttonText?: string;
  currencies: Currency[];
};

export const PaymentForm: React.FC<PaymentFormProps> = ({
  onSubmit,
  description = "Закажите расширенный анализ с расшифровкой которая будет всегда вам доступна",
  buttonText = "Заказать анализ",
  currencies
}) => {
  const [selectedPayment, setSelectedPayment] = React.useState<'stars' | 'fens'>(currencies[0]?.type || 'stars');

  return (
    <div className="px-5">
      <div className="flex flex-col gap-5 items-center mb-10 w-full">
        <div className="flex flex-row gap-5 justify-center w-full">
          {currencies.map((currency) => (
            <div key={currency.type} className="flex flex-col items-center">
              <div 
                className={`bg-card-gradient border rounded-xl w-32 h-24 flex items-center justify-center cursor-pointer ${
                  selectedPayment === currency.type ? 'border-[#FFD700]' : 'border-transparent'
                }`}
                onClick={() => setSelectedPayment(currency.type)}
              >
                <img className="w-14" src={`/ico/pay-${currency.type}.svg`} alt=""/>
              </div>
              <span className={`text-sm mt-2 ${
                selectedPayment === currency.type ? 'text-[#FFD700]' : 'text-[#AFAFDE]'
              }`}>{currency.cost} {currency.name}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-5 text-center text-xs text-[#AFAFDE] w-full flex justify-center">
        <div className="w-2/3">
          {description}
        </div>
      </div>

      <div className="flex justify-center items-center">
        <div
          className="flex justify-center items-center rounded-xl p-4 bg-card-gradient mb-5 cursor-pointer w-4/5"
          onClick={() => onSubmit(selectedPayment)}
        >
          <div className="mb-1 flex justify-center items-center">
            <img src="/ico/arrow-up.svg" alt=""/>
          </div>
          <div className="px-5 font-bold text-sm text-gold-gradient flex items-center justify-center">
            {buttonText}
          </div>
          <div className="mb-1 flex justify-center items-center">
            <img src="/ico/arrow-up.svg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
}; 