import React from "react";

interface HappyNumbersProps {
  numbers: number[];
  maxColumns?: number;
}

const HappyNumbers: React.FC<HappyNumbersProps> = ({ numbers, maxColumns = 3 }) => {
  const getGridClass = (count: number) => {
    const cols = Math.min(count, maxColumns);
    return `grid grid-cols-${cols} gap-4 text-center justify-center`;
  };

  const renderNumbers = () => {
    const rows = [];
    for (let i = 0; i < numbers.length; i += maxColumns) {
      const rowNumbers = numbers.slice(i, i + maxColumns);
      rows.push(
        <div key={i} className={getGridClass(rowNumbers.length)}>
          {rowNumbers.map((n, index) => (
            <div key={index} className="flex flex-col items-center text-4xl justify-center">
              {n}
            </div>
          ))}
        </div>
      );
    }
    return rows;
  };

  return (
    <div className="px-10">
      {renderNumbers()}
    </div>
  );
};

export default HappyNumbers
