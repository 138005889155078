import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./style.css";
import Toggle from "../toggle";

const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = [
  "января", "февраля", "марта", "апреля", "мая", "июня",
  "июля", "августа", "сентября", "октября", "ноября", "декабря"
];
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));
const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));

function getStartOfToday(): Date {
  const now = new Date();
  return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
}

const createEmptyArray = (size: number) => Array.from({ length: size }, () => null);

interface DatePickerProps {
  label: string;
  timeLabel?: string;
  value?: number;
  isOpen?: boolean;
  alwaysOpen?: boolean;
  onChange?: (timestamp: number) => void;
  isTimeEditable?: boolean;
  onTimeEditableChange?: (isEditable: boolean) => void;
}

const DatePicker: FC<DatePickerProps> = ({
  label,
  timeLabel,
  value,
  onChange,
  isOpen = false,
  alwaysOpen = false,
  isTimeEditable = false,
  onTimeEditableChange,
}) => {
  const currentDay = getStartOfToday()
  const [ isOpenValue, setIsOpenValue ] = useState(alwaysOpen || isOpen);
  const [ isShowTime, setShowTime ] = useState(isTimeEditable);
  const [ selectedDate, setSelectedDate ] = useState(() => {
    const initialDate = value ? new Date(value) : currentDay;
    return {
      day: initialDate.getUTCDate(),
      month: initialDate.getUTCMonth(),
      year: initialDate.getUTCFullYear(),
    };
  });
  const [ selectedTime, setSelectedTime ] = useState(() => {
    const initialTime = value ? new Date(value) : currentDay;
    return {
      hour: initialTime.getUTCHours().toString().padStart(2, "0"),
      minute: initialTime.getUTCMinutes().toString().padStart(2, "0"),
    };
  });

  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isScrollingRef = useRef(false);
  const wheelContainerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const initializedRef = useRef(false);

  const toggleDropdown = () => {
    if (isOpenValue && isScrollingRef.current) {
      // Если закрываем во время скролла, используем текущие значения из state
      notifyChange(selectedDate, selectedTime);
      
      // Очищаем таймаут скролла, если он есть
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    }

    if (!alwaysOpen) {
      setIsOpenValue(prev => !prev);
    }
  };

  const handleScroll = (
    e: React.UIEvent<HTMLDivElement>,
    type: "day" | "month" | "year" | "hour" | "minute",
    items: (string | number)[]
  ) => {
    const target = e.currentTarget as HTMLDivElement;
    const itemHeight = 40;
    const scrollTop = target.scrollTop;
    const index = Math.round(scrollTop / itemHeight);

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    isScrollingRef.current = true;

    if (index >= 0 && index < items.length) {
      const newState = type === "day" || type === "month" || type === "year"
        ? { ...selectedDate, [type]: type === "month" ? index : items[index] }
        : { ...selectedTime, [type]: items[index] };

      if (type === "day" || type === "month" || type === "year") {
        setSelectedDate(newState as any);
        notifyChange(newState as any, selectedTime);
      } else {
        setSelectedTime(newState as any);
        notifyChange(selectedDate, newState as any);
      }
    }

    scrollTimeoutRef.current = setTimeout(() => {
      const closestIndex = Math.round(target.scrollTop / itemHeight);
      target.scrollTo({
        top: closestIndex * itemHeight,
        behavior: "smooth",
      });
      isScrollingRef.current = false;
    }, 150);
  };

  const notifyChange = (date: {day: number; month: number; year: number}, time: {hour: string; minute: string}) => {
    const timestamp = Date.UTC(
      date.year,
      date.month,
      date.day,
      parseInt(time.hour),
      parseInt(time.minute)
    );

    if (timestamp !== value) {
      onChange?.(timestamp);
    }
  };

  const scrollToSelected = (behavior: ScrollBehavior = "smooth") => {
    const itemHeight = 40;
    const dayIndex = selectedDate.day - 1;
    const monthIndex = selectedDate.month;
    const yearIndex = years.findIndex(year => year === selectedDate.year);
    const hourIndex = parseInt(selectedTime.hour);
    const minuteIndex = parseInt(selectedTime.minute);

    if (wheelContainerRefs.current.length > 0) {
      wheelContainerRefs.current[0]?.scrollTo({ top: dayIndex * itemHeight, behavior });
      wheelContainerRefs.current[1]?.scrollTo({ top: monthIndex * itemHeight, behavior });
      wheelContainerRefs.current[2]?.scrollTo({ top: yearIndex * itemHeight, behavior });
      wheelContainerRefs.current[3]?.scrollTo({ top: hourIndex * itemHeight, behavior });
      wheelContainerRefs.current[4]?.scrollTo({ top: minuteIndex * itemHeight, behavior });
    }
  };

  useEffect(() => {
    if (value) {
      const newDate = new Date(value);
      setSelectedDate({
        day: newDate.getUTCDate(),
        month: newDate.getUTCMonth(),
        year: newDate.getUTCFullYear(),
      });
      setSelectedTime({
        hour: newDate.getUTCHours().toString().padStart(2, "0"),
        minute: newDate.getUTCMinutes().toString().padStart(2, "0"),
      });
      if (isOpenValue) {
        scrollToSelected("smooth");
      }
    }
  }, [ value ]);

  useLayoutEffect(() => {
    if (isOpenValue && !initializedRef.current) {
      scrollToSelected("instant");
      initializedRef.current = true;
    }
  }, [isOpenValue]);

  useEffect(() => {
    if (isOpenValue && !isScrollingRef.current) {
      scrollToSelected("smooth");
    }
  }, [selectedDate, selectedTime]);

  const renderWheelList = (items: (string | number)[], selectedItem: string | number, type: string) => {
    const itemHeight = 40;

    return (
      <div
        key={type}
        className="wheel"
        ref={el => {
          wheelContainerRefs.current[type === "day" ? 0 : type === "month" ? 1 : type === "year" ? 2 : type === "hour" ? 3 : 4] = el;
        }}
        onScroll={(e) => handleScroll(e, type as any, items)}
      >
        <div className="wheel-container">
          <ul className="wheel-list">
            {createEmptyArray(1).map((_, idx) => <li key={idx} style={{ height: "40px" }}></li>)}
            {items.map((item, idx) => {
              const isActive = (type === "month")
                ? selectedItem === idx
                : selectedItem === item;
              return (
                <li
                  key={idx}
                  className={`wheel-item ${isActive ? "active" : ""}`}
                  style={{ height: "40px" }}
                >
                  {item}
                </li>
              );
            })}
            <li style={{ height: "40px" }}></li>
          </ul>
        </div>
      </div>
    );
  };

  const handleTimeEditableToggle = () => {
    const newValue = !isShowTime;
    setShowTime(newValue);
    onTimeEditableChange?.(newValue);
  };

  return (
    <div className="datepicker-picker">
      <div
        className={`datepicker-picker-header 
          ${alwaysOpen ? "" : "cursor-pointer"} 
          ${!isOpenValue ? "" : "border-b border-gray-600 pb-2"}
        `}
        onClick={toggleDropdown}>
        <span className="title">{label}</span>
        <span className="action">
          {`${selectedDate.day} ${months[selectedDate.month]} ${selectedDate.year}`}
          {isShowTime && ` ${selectedTime.hour}:${selectedTime.minute}`}
        </span>
      </div>

      <div className={`dropdown-content ${isOpenValue ? "block" : "hidden"}`}>
        <div className="grid grid-cols-3 gap-2">
          {renderWheelList(days, selectedDate.day, "day")}
          {renderWheelList(months, selectedDate.month, "month")}
          {renderWheelList(years, selectedDate.year, "year")}
        </div>

        <div className="mb-5">
          <div className="mt-4 mb-2 flex items-center">
            <label className="me-2">
              {timeLabel || "Задать время?"}
            </label>
            <Toggle
              checked={isShowTime}
              onToggle={handleTimeEditableToggle}
            />
          </div>

          <div className={`w-full flex justify-center ${isShowTime ? "block" : "hidden"}`}>
            <div className="grid grid-cols-2 gap-2 w-48">
              {renderWheelList(hours, selectedTime.hour, "hour")}
              {renderWheelList(minutes, selectedTime.minute, "minute")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default DatePicker;
