import React, { useEffect, useRef } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAccount } from "../hooks/useAccount"
import { useTelegram } from "context/telegram.provider";
import BottomBar from "./bottomBar";
import routes, { RouteGroup } from "routes";

const Main: React.FC = () => {
  const auth = useAccount()
  const location = useLocation()
  const { isMock, getMainButtonText, getMainButtonClickHandler, getMainButtonVisible } = useTelegram()
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [ location.pathname ]);

  if (!auth.session) return <Navigate to="/loading" state={{ from: location }}/>

  return (
    <div className="flex flex-col h-screen">
      <Routes>
        {(routes as RouteGroup[]).map(
          ({ layout, pages }) =>
            layout === "protected" &&
            pages.map(({ path, element, bottomBar }) => (
              <Route key={path} path={path} element={
                <div className="flex flex-col h-full">
                  <div
                    ref={scrollContainerRef}
                    className={`flex-grow overflow-y-auto ${bottomBar ? "pb-[12rem]" : ""}`}
                  >
                    {element}
                  </div>
                  {bottomBar && (
                    <div className="fixed bottom-0 left-0 right-0">
                      <BottomBar/>
                    </div>
                  )}
                </div>
              }/>
            ))
        )}
      </Routes>

      {isMock() && (
        <button
          onClick={getMainButtonClickHandler}
          style={{
            display: getMainButtonVisible() ? "block" : "none",
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "10px 20px",
            backgroundColor: "#3390ec",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {getMainButtonText()}
        </button>
      )}
    </div>
  )
}

export default Main;
