import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AccountPersonalInfoForm from "../../profile/forms/accountPersonalInfoForm";
import { useAccount } from "hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { FeatureWelcomePath } from "../routes";
import { ScreenBirthdayPath } from "./screenBirthday";
import { useTelegram } from "context/telegram.provider";
import { useForm } from "context/form.context";

export interface AccountProfile {
  first_name: string
  last_name: string
}

export const ScreenProfilePath = "profile";
const ScreenProfile = () => {

  const { t } = useTranslation()
  const account = useAccount().account
  const navigate = useNavigate()
  const { formData, updateFormData } = useForm();

  useEffect(() => {
    updateFormData({ ...formData, first_name: account.first_name, last_name: account.last_name })
  }, []);

  const handleChange = (name: any, data: any) => {
    updateFormData({ ...formData, [name]: data })
  }

  const { setMainButtonVisible, setMainButtonProps, setBackButtonVisible, setBackButtonOnClick } = useTelegram()

  useEffect(() => {
    setBackButtonVisible(true)
    setBackButtonOnClick(() => navigate(-1))
  }, []);

  const submit = () => {
    console.log("go to birthday");
    return navigate(FeatureWelcomePath(ScreenBirthdayPath));
  }

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenProfile.mainButton"), submit);
  }, [ setMainButtonProps ]);

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">
      <h1 className="text-3xl font-bold pt-8 mb-3 text-center">
        {t("features.welcome.screens.screenProfile.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenProfile.desc")}
      </div>

      <div className="flex-1">
        <div className="mb-5">
          <AccountPersonalInfoForm data={formData} onChange={handleChange}/>
        </div>
      </div>

    </div>
  )
}

export default ScreenProfile
