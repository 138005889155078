import { Account, AccountReferral, AccountReferralStats, AccountSettings } from "app/types/account"
import { api } from "./api"
import { EmptyRequest } from "./empty"
import { setAccount, setAccountSettings } from "features/auth/authSlice";
import { Zodiac } from "../types/zodiac";
import * as Sentry from "@sentry/react";

export interface AccountResponse {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  default_profile_id: string;
  created: number;
  updated: number;
}

export interface AccountSettingsResponse {
  language: string;
  timezone: string;
  report_time: string;
  report_language: string;
  zodiacs: Zodiac[];
}

export type AccountSettingsUpdateRequest = {
  language: string,
  timezone: string,
  report_time: string,
  report_language: string,
  zodiacs: Zodiac[],
}

export type AccountReferralStatsResponse = {
  link: string;
  referral_id: string;
  total: number;
  used: number;
}

export type AccountReferralResponse = {
  username: string,
  created: number,
}

function getAccountFromResponse(src: AccountResponse): Account {
  return {
    id: src.id,
    username: src.username,
    first_name: src.first_name,
    last_name: src.last_name,
    created: src.created,
    updated: src.updated,
  }
}

function getAccountSettingsFromResponse(src: AccountSettingsResponse): AccountSettings {
  return {
    ...src
  }
}

function getAccountReferralStatsFromResponse(src: AccountReferralStatsResponse): AccountReferralStats {
  return {
    ...src
  }
}

function getAccountReferralFromResponse(src: AccountReferralResponse): AccountReferral {
  return {
    ...src
  }
}

export const accountApi = api.injectEndpoints({
  endpoints: (build) => ({

    accountFetch: build.query<Account, EmptyRequest>({
      query: () => ({
        url: "v1/account",
        method: "GET",
      }),
      transformResponse: (response: AccountResponse): Account => {
        return getAccountFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(data));
        } catch (err) {
          console.error("Failed to fetch account", err);
          Sentry.captureMessage("Service: Failed to fetch account");
          Sentry.captureException(err);
        }
      },
    }),

    accountSettingsUpdate: build.mutation<AccountSettings, AccountSettingsUpdateRequest>({
      query: (account: AccountSettingsUpdateRequest) => ({
        url: "v1/account/settings",
        method: "PUT",
        body: account,
      }),
      transformResponse: (response: AccountSettingsResponse): AccountSettings => {
        return getAccountSettingsFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountSettings(data));
        } catch (err) {
          console.error("Failed to fetch account settings", err);
          Sentry.captureMessage("Service: Failed to fetch account settings");
          Sentry.captureException(err);
        }
      },
    }),

    accountSettingsFetch: build.query<AccountSettingsResponse, void>({
      query: () => ({
        url: "v1/account/settings",
        method: "GET",
      }),
      transformResponse: (response: AccountSettingsResponse): AccountSettings => {
        return getAccountSettingsFromResponse(response);
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountSettings(data));
        } catch (err) {
          console.error("Failed to fetch account settings", err);
          Sentry.captureMessage("Service: Failed to fetch account settings");
          Sentry.captureException(err);
        }
      },
    }),

    accountReferralStatsFetch: build.query<AccountReferralStatsResponse, void>({
      query: () => ({
        url: "v1/referral",
        method: "GET",
      }),
      transformResponse: (response: AccountReferralStatsResponse): AccountReferralStats => {
        return getAccountReferralStatsFromResponse(response);
      },
    }),

    accountReferralsFetch: build.query<AccountReferralResponse, void>({
      query: () => ({
        url: "v1/referrals",
        method: "GET",
      }),
      transformResponse: (response: AccountReferralResponse): AccountReferral => {
        return getAccountReferralFromResponse(response);
      },
    }),

  }),
})

export const {
  useAccountFetchQuery,
  useLazyAccountFetchQuery,
  useAccountSettingsUpdateMutation,
  useLazyAccountSettingsFetchQuery,
  useAccountReferralStatsFetchQuery,
  useAccountReferralsFetchQuery,
} = accountApi

export const {
  endpoints: {
    accountFetch,
    accountSettingsUpdate,
    accountReferralStatsFetch,
    accountReferralsFetch
  },
} = accountApi

