import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "context/telegram.provider";
import { useForm } from "context/form.context";
import { ScreenDonePath } from "./screenDone";
import { FeatureWelcomePath } from "../routes";
import ZodiacSelect from "widgets/forms/zodiacselect";
import { Zodiac } from "app/types/zodiac";

export const ScreenZodiacsPath = "zodiacs";

const ScreenZodiacs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { formData, updateFormData } = useForm();

  const { setMainButtonVisible, setMainButtonProps } = useTelegram();

  const submit = () => {
    console.log("go to done");
    return navigate(FeatureWelcomePath(ScreenDonePath));
  }

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenBirthday.mainButton"), submit);
  }, [ setMainButtonProps ]);


  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(
      t("features.welcome.screens.screenZodiacs.mainButton"),
      submit,
    );
  }, [ setMainButtonProps ]);

  const handleSignChange = (data: Zodiac[]) => {
    updateFormData({ ...formData, zodiacs: data })
  }


  // const handleTimeChange = (time: string) => {
  //   updateFormData({ ...formData, report_daily_time: time })
  // }

  return (
    <div className="flex flex-col min-h-screen px-5 constellation">
      <h1 className="text-3xl font-bold pt-8 mb-3 text-center">
        {t("features.welcome.screens.screenZodiacs.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenZodiacs.info")}
      </div>

      <div className="my-5">
        <ZodiacSelect
          value={formData.zodiacs}
          onChange={handleSignChange}
        />
      </div>

      {/* <div className="my-5">
        <TimePicker
          label="Время рассылки"
          value={formData.daily_time}
          onChange={handleTimeChange}
          minuteInterval={15}
        />
      </div> */}
    </div>
  );
};

export default ScreenZodiacs;
