import React from "react";
import Card from "../card";

export type Recommendation = {
  text: string;
  isPositive: boolean;
};

interface RecommendationCardProps {
  data: Recommendation[];
  disabled?: boolean;
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({ data, disabled }) => {
  if (disabled) {
    data = [
      { text: "Заняться спортом", isPositive: true },
      { text: "Денежные операции", isPositive: true },
      { text: "Принять горячий душ", isPositive: true },
      { text: "Читать финансовые отчеты", isPositive: false },
      { text: "Спойлерить сериалы про детективов", isPositive: false },
      { text: "Шутить про клоунов", isPositive: false },
    ]
  }

  const renderRecommendations = (isPositive: boolean) => (
    <div className="flex items-center space-x-2">
      <img
        src={isPositive ? "/ico/emoji-smile.svg" : "/ico/emoji-sad.svg"}
        alt={isPositive ? "Positive" : "Negative"}
        className="w-10 h-10"
      />
      <div className={`border-l pl-5 flex-grow relative ${isPositive ? "border-green-500" : "border-red-500"}`}>
        <Card className={`space-y-3 p-0 ${disabled ? "opacity-10": "bg-none"}`} disabled={disabled}>
          {data
            .filter((rec) => rec.isPositive === isPositive)
            .map((rec, index) => (
              <p key={index} className="text-sm">
                {rec.text}
              </p>
            ))}
        </Card>
        {disabled && (
          <div
            className="absolute inset-0 bg-[#081527ED] bg-opacity-75 rounded-xl p-4 ml-3 flex items-center justify-center">
            <p className="text-[#4F6080] text-sm text-center">
              Рекомендации не доступны на текущем уровне доступа
            </p>
          </div>
        )}
      </div>

    </div>
  );

  return (
    <div className="flex flex-col space-y-10">
      {renderRecommendations(true)}
      {renderRecommendations(false)}
    </div>
  );
};

export default RecommendationCard;