import React from "react";
import { ZodiacPlanet } from "app/types/astrology";

export interface PlanetProps {
  data: ZodiacPlanet;
}

const ZodiacPlanetCard: React.FC<PlanetProps> = ({ data }) => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="flex space-x-10">

        <div className="flex items-center justify-center">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-[3rem] h-[3rem]">
              <img src={`/ico/planets/Rulership=${data?.name?.toLowerCase()}.svg`} alt=""/>
            </div>
            <div>
              <img src={"/ico/arrow-up.svg"} alt=""/>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-[3rem] h-[3rem]">
              <img src={"/ico/planets/Rulership=moon.svg"} alt=""/>
            </div>
            <div>
              <img src={"/ico/arrow-up.svg"} alt=""/>
            </div>
          </div>
        </div>

      </div>

      <div className="w-full px-5">
        <p className="text-sm text-center">
          {data?.influence}
        </p>
      </div>

    </div>
  );
};

export default ZodiacPlanetCard;
