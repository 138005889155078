import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "app/store"
import { Account, AccountSettings } from "app/types/account"
import { Session } from "app/types/session"

const initialState = {
  session: null,
  account: null,
  settings: null,
} as {
  session: Session | null,
  account: Account | null,
  settings: AccountSettings | null,
}

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: session,
      }: PayloadAction<Session>,
    ) => {
      state.session = session
    },
    setAccount: (
      state,
      {
        payload: account,
      }: PayloadAction<Account>,
    ) => {
      state.account = account
    },
    setAccountSettings: (
      state,
      {
        payload: accountSettings,
      }: PayloadAction<AccountSettings>,
    ) => {
      if (state.account) {
        state.settings = accountSettings
      }
    },
  },
})

export const {
  setCredentials,
  setAccount,
  setAccountSettings,
} = slice.actions
export default slice.reducer

export const selectSessionToken = (state: RootState) => state.auth?.session?.token
export const selectCurrentAccount = (state: RootState) => state.auth?.account
export const selectAccountSettings = (state: RootState) => state.auth?.settings
