import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useScreen } from "context/telegram.provider";
import { BottomSheet, useBottomSheet } from "context/bottomsheet.context";
import { getZodiac, getZodiacPeriod } from "app/types/zodiac";
import { useDailyPersonalFetchQuery } from "app/services/astrology";
import { useNatalChartProfileFetchQuery } from "app/services/natalchart";
import { useProfileFetchQuery } from "app/services/profile";
import Card from "widgets/components/card";
import ZodiacPlanet from "widgets/components/zodiacplanet";
import RecommendationInfo from "widgets/components/recommendation";
import HappyNumber from "widgets/components/happynumber/happynumber";
import EfficiencyCalendarFeature from "../efficiency/efficiency_calendar";
import NatalChart from "widgets/components/natalchart/natalChart";
import ProfileCompatibilityList from "./profile_compatibility";

const ProfileInfoPage = () => {

  const navigate = useNavigate()
  const { openBottomSheet } = useBottomSheet();
  const { profile_id } = useParams();
  const { setMainButtonVisible, setBackButtonVisible, setBackButtonOnClick } = useScreen();

  const {
    data: profile,
    isLoading,
  } = useProfileFetchQuery(profile_id!, {
    skip: !profile_id,
  });

  const {
    data: profileInfo,
    isLoading: isProfileLoading,
  } = useDailyPersonalFetchQuery(profile_id!);

  const {
    data: natalChartInfo,
    isLoading: isNatalChartLoading,
  } = useNatalChartProfileFetchQuery(profile_id!);

  // Configure navigation routing
  useEffect(() => {
    setMainButtonVisible(false);
    setBackButtonVisible(true);
    setBackButtonOnClick(() => navigate("/"));
  }, []);

  if (isLoading || isProfileLoading || isNatalChartLoading) return <div/>;

  if (!profile || !profileInfo || !natalChartInfo) {
    navigate("/")
    return;
  }

  if (profileInfo.status === "pending") {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="mb-5">
          <img src="/logo.svg" className="w-48 h-48" alt=""/>
        </div>
        <p>Идет подготовка профиля</p>
        <p>нужно немного подождать ;)</p>
      </div>
    )
  }

  const recommendations = [
    ...(profileInfo?.recommendations?.positives?.map(r => ({ text: r, isPositive: true })) || []),
    ...(profileInfo?.recommendations?.negatives?.map(r => ({ text: r, isPositive: false })) || []),
  ]

  const period = getZodiacPeriod(profileInfo.zodiac);

  return (
    <div>
      <div className="flex flex-col min-h-screen px-5 constellation">

        <div className="mb-5 flex flex-col items-center justify-center mt-8">
          <img className="w-12 h-12 mx-auto"
            src={`/ico/constellation/Constellation=${getZodiac(profileInfo.zodiac)}, Color=white.svg`} alt=""/>
          <h1 className="text-3xl font-bold py-4 text-center text-tg-theme-section-header capitalize">
            {`${profileInfo.first_name || ""} ${profileInfo.last_name || ""}`.trim()}
          </h1>
          <div className="text-center">
            <p className="text-sm text-tg-theme-subtitle">
              {profileInfo.zodiac.charAt(0).toUpperCase() + profileInfo.zodiac.slice(1).toLowerCase()}
            </p>
            <p className="text-sm text-tg-theme-subtitle">
              {`${period.start.day.toString().padStart(2, "0")}.${period.start.month.toString().padStart(2, "0")} 
              - ${period.end.day.toString().padStart(2, "0")}.${period.end.month.toString().padStart(2, "0")}`}
            </p>
          </div>
        </div>

        <div>
          <div className="mb-5">
            <NatalChart
              ascendant={110}
              planets={natalChartInfo.planets}
              houses={natalChartInfo.houses}
              aspects={natalChartInfo.aspects}
              zodiacSign={profileInfo?.zodiac}
              showHouseNumbers={true}
              showDegrees={true}
            />
          </div>

          <div className="mx-5 grid text-center">
            <div className="mb-2 text-sm text-[#AFAFDE]">Натальная карта и астропрофиль</div>
            <Card className="flex items-center justify-between"
              onClick={() => navigate(`/profile/${profileInfo.profile_id}/natal_chart`)}>
              <div className="flex items-center">
                <div className="me-5">
                  <img
                    src={`/img/constellation/${profileInfo.zodiac.toLowerCase()}.svg`}
                    alt={profileInfo.zodiac.toLowerCase()}
                    className="w-8 object-contain"
                  />
                </div>
                <div className="text-sm text-tg-theme-section-header">
                  {`${profileInfo.first_name || ""} ${profileInfo.last_name || ""}`.trim()}
                </div>
              </div>
              <div>
                <img src={"/ico/arrow-right.svg"} alt=""/>
              </div>
            </Card>
          </div>
        </div>

        <div className="mx-5 my-10">
          <div className="w-full text-center text-tg-theme-section-header mb-3">
            Ваши планеты
          </div>
          <ZodiacPlanet data={profileInfo.zodiac_planet}/>
        </div>

        <div className="mx-5 my-10">
          <div className="w-full text-center text-tg-theme-section-header mb-3">
            <p>Ваш персональный гороскоп</p>
            <p></p>
          </div>
          <div>{profileInfo.horoscope}</div>
        </div>

        <div className="mx-5 my-5">
          <Card>
            <div className="w-full text-center text-tg-theme-section-header mb-3">
              Счастливые числа
            </div>
            <HappyNumber numbers={profileInfo.happy_numbers}/>
          </Card>
        </div>

        {!!profileInfo?.compatibilities && (
          <div className="mx-5 my-10">
            <div className="w-full text-center mb-5">
              <h2 className="text-sm text-tg-theme-section-header">Совместимость сегодня</h2>
            </div>

            <ProfileCompatibilityList compatibilities={profileInfo?.compatibilities}/>
          </div>
        )}

        <div className="mx-5 my-10">
          <RecommendationInfo data={recommendations}/>
        </div>

        <div className="mx-5 my-10">
          <div className="w-full text-center mb-5">
            <h2 className="text-sm text-tg-theme-section-header">Календарь эффективности</h2>
          </div>

          <EfficiencyCalendarFeature profile_id={profile?.id}/>
        </div>

        <div className="mx-5 my-10">
          <div className="w-full text-center mb-5">
            <h2 className="text-sm text-[#AFAFDE]">История запросов персональных совместимостей</h2>
          </div>

          {[ 1, 2 ].map((i, index) => {
            return (
              <div key={i} className={`
                flex items-center justify-between bg-card-gradient px-5 py-2 rounded-lg cursor-pointer
                ${index < 1 ? "mb-4" : ""}
                `}
              onClick={() => navigate("/compatibility/1")}
              >
                <div className="flex items-center">
                  <img src={"/img/constellation/aries.svg"} alt={""} className="w-12 h-12 rounded-full mr-4"/>
                  <div className="text-sm text-[#AFAFDE]">
                    <div>Viktoria Khamzina</div>
                    <div>11.05.1994</div>
                  </div>
                </div>

                <div className="text-[#AFAFDE]">x</div>

                <div className="flex items-center">
                  <div className="text-sm text-[#AFAFDE]">
                    <div>Viktoria Khamzina</div>
                    <div>11.05.1994</div>
                  </div>
                  <img src={"/img/constellation/aries.svg"} alt={""} className="w-12 h-12 rounded-full ml-4"/>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mx-5 my-10">
          <div className="w-full text-center mb-5">
            <h2 className="text-sm text-[#AFAFDE]">Настройка профайла</h2>
          </div>

          <div className="flex flex-col items-center">
            <img
              src={`/img/constellation/${getZodiac(profileInfo.zodiac)}.svg`}
              alt={getZodiac(profileInfo.zodiac)}
              className="h-10 object-contain my-5"
            />
            <p className="text-sm mt-4 text-center text-tg-theme-section-header capitalize">
              {`${profileInfo.first_name || ""} ${profileInfo.last_name || ""}`.trim()}
            </p>
            <button
              className="mt-4 px-10 py-3 rounded-xl bg-card-gradient text-tg-theme-section-header w-[12rem]"
              onClick={() => {
              }}
            >
              Изменить
            </button>
          </div>

        </div>

        <div className="mx-5 my-10">
          <Card>
            <div className="flex flex-col justify-center items-center w-full h-full">
              <h2 className="text-lg font-bold">Удаление профиля</h2>
              <div className="text-center text-sm py-2">Будьте аккуратны - данное действие необратимо.</div>
              <div>
                <button
                  className="mt-4 px-10 py-1 border border-red-500 text-red-500 rounded-xl bg-card-gradient"
                  onClick={() => openBottomSheet()}
                >
                  Удалить
                </button>
              </div>
            </div>
          </Card>

          <BottomSheet>
            <div className="flex flex-col justify-center items-center w-full h-full">
              <p>Вы уверены что хотите удалить профайл?</p>
              <button className="mt-4 px-10 py-1 border border-red-500 text-red-500 rounded-xl bg-card-gradient">
                Подтверждаю, удаление
              </button>
            </div>
          </BottomSheet>
        </div>

      </div>
    </div>
  )
}

export default ProfileInfoPage
