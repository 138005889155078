import { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAccount } from "../../hooks/useAccount"
import ScreenHello, { ScreenHelloPath } from "./screens/screenHello";
import ScreenProfile, { ScreenProfilePath } from "./screens/screenProfile";
import { RouteGroup } from "routes";
import ScreenSettings, { ScreenSettingsPath } from "./screens/screenSettings";
import ScreenBirthday, { ScreenBirthdayPath } from "./screens/screenBirthday";
import ScreenZodiacs, { ScreenZodiacsPath } from "./screens/screenZodiacs";
import ScreenDone, { ScreenDonePath } from "./screens/screenDone";
import { FormProvider } from "../../context/form.context";

const FeaturePrefix = "welcome"

export const FeatureWelcomePath = (screen: string) => {
  return "/" + [ FeaturePrefix, screen ].join("/")
}

const defaultFormData = {
  first_name: "",
  last_name: "",
  birthday: 0,
  report_daily_time: "08:00",
  zodiacs: [],
  isEditTime: false,
}

const FeatureWelcome: FC = () => {

  const auth = useAccount()
  const location = useLocation()

  if (!auth.session) return <Navigate to="/loading" state={{ from: location }}/>

  const routes: RouteGroup[] = [
    {
      layout: FeaturePrefix,
      pages: [
        {
          path: ScreenHelloPath,
          element: <ScreenHello/>,
        },
        {
          path: ScreenProfilePath,
          element: <ScreenProfile/>,
        },
        {
          path: ScreenBirthdayPath,
          element: <ScreenBirthday/>
        },
        {
          path: ScreenZodiacsPath,
          element: <ScreenZodiacs/>,
        },
        {
          path: ScreenSettingsPath,
          element: <ScreenSettings/>,
        },
        {
          path: ScreenDonePath,
          element: <ScreenDone/>
        },
      ]
    }
  ];

  return (
    <FormProvider initialData={defaultFormData}>
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === FeaturePrefix &&
            pages.map(({ path, element }) => (
              <Route path={path} element={element}/>
            ))
        )}
      </Routes>
    </FormProvider>
  )
}

export default FeatureWelcome;
